import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import { SaveCancelFooter } from '../../../components/Footer';
import TrainingStages from '../../../components/training/TrainingStages';
import { TRAINING_CREATION_STAGES } from '../../../constants/constants';
import BasicTrainingCard from '../../../components/training/Cards/Basic';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import TrainingDetails from '../../../components/training/Cards/Details';
import ScheduleDigitalCard from '../../../components/training/Cards/Schedule/Digital';
import ScheduleInPersonCard from '../../../components/training/Cards/Schedule/In-Person';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import {
  createTraining,
  fetchTrainingById,
  updateTraining
} from '../../../api/v4';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject
} from '../../../selectors/company';
import { getLoggedInUser } from '../../../selectors/users';
import history from '../../../history';

import styles from './styles.module.scss';

export default function TrainingCreate() {
  const activeCompany = useSelector(getActiveCompany);
  const group = useSelector(getActiveLocationId);
  const project = useSelector(getActiveProject);
  const user = useSelector(getLoggedInUser);
  const location = useLocation();

  const { trainingId } = useParams();
  const [selectedStage, setStage] = useState(0);
  const [training, setTraining] = useState({});
  const [unsavedChanges, setUnsaved] = useState(false);
  const [stages] = useState(TRAINING_CREATION_STAGES);
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const equipmentId = location.state?.equipmentId;
  const validTemplateIds = location.state?.validTemplateIds;
  const groupDropdown = location.state?.groupDropdown;
  const projectDropdown = location.state?.projectDropdown;
  const equipmentGroups = location.state?.equipmentGroups;
  const equipmentProjects = location.state?.equipmentProjects;
  const fromScreen = location.state?.fromScreen;

  useEffect(() => {
    const storedTraining = localStorage.getItem('trainingTemplate');
    let training = {
      trainingType: equipmentId ? 1 : null,
      schedule: equipmentId ? 1 : null,
      equipmentId: equipmentId,
      groupIds: equipmentId ? equipmentGroups : group ? [group?._id] : [],
      projectIds: equipmentId
        ? equipmentProjects
        : project
        ? [project?._id]
        : []
    };
    if (storedTraining) {
      const jsonTraining = JSON.parse(storedTraining);
      setTraining({ ...training, ...jsonTraining });
      setStage(jsonTraining.stage ?? TRAINING_CREATION_STAGES?.length - 1);
      localStorage.removeItem('trainingTemplate');
    } else if (trainingId)
      fetchTrainingById(trainingId).then(r =>
        setTraining({ ...training, ...r })
      );
    else {
      setTraining(training);
    }
  }, [
    trainingId,
    equipmentId,
    equipmentGroups,
    equipmentProjects,
    group,
    project
  ]);

  const onAnswer = value => {
    let updated = { ...training, ...value };

    if (
      updated.trainingType === 2 &&
      !updated.quizMasterId &&
      !updated.collectSignatures
    )
      delete updated.releaseType;

    setTraining(updated);
    setUnsaved(true);
    setDisabled(false);
  };
  const handleBack = () => {
    history.push(fromScreen ?? '/app/training/list');
  };

  const handleSubmit = () => {
    if (!trainingId)
      createTraining({
        ...training,
        companyId: activeCompany._id
      }).then(handleBack);
    else
      updateTraining({
        ...training,
        companyId: activeCompany._id
      }).then(handleBack);
  };

  const advancePage = () => {
    setStage(selectedStage + 1);
    setMissingRequired(false);
    setDisabled(true);
  };

  const header = (
    <Header
      title="Training"
      section="Create Training"
      needsSaved={unsavedChanges}
      clickBack={() =>
        selectedStage === 0
          ? unsavedChanges
            ? setUnsavedModal(true)
            : handleBack()
          : setStage(selectedStage - 1)
      }
    />
  );
  const footer = (
    <SaveCancelFooter
      saveButtonText={selectedStage === stages.length - 1 ? 'Create' : 'Next'}
      saveButtonClick={() =>
        !canSubmit
          ? setMissingModalOpen(true)
          : selectedStage === stages.length - 1
          ? handleSubmit()
          : advancePage()
      }
      cancelButtonClick={() =>
        selectedStage === 0
          ? unsavedChanges
            ? setUnsavedModal(true)
            : handleBack()
          : setStage(selectedStage - 1)
      }
      cancelButtonText={selectedStage === 0 ? 'Cancel' : 'Back'}
      reverseOrder
      onMouseEnter={() => setMissingRequired(true)}
      // disabling is required because the onMouseEnter fires when you go to the next card
      saveButtonDisabled={disabled && !canSubmit}
    />
  );

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        className={styles.trainingContainer}
      >
        <TrainingStages stages={stages} selectedStage={selectedStage} />
        {selectedStage === 0 ? (
          <BasicTrainingCard
            training={training}
            onAnswer={onAnswer}
            setCanSubmit={setCanSubmit}
            onlyTemplates={equipmentId ? validTemplateIds ?? [] : undefined}
            missingRequired={missingRequired}
          />
        ) : selectedStage === 1 ? (
          <TrainingDetails
            training={training}
            onAnswer={onAnswer}
            setCanSubmit={setCanSubmit}
            location={group}
            editing
            creating
            missingRequired={missingRequired}
          />
        ) : selectedStage === 2 ? (
          training?.trainingType === 1 ? (
            <ScheduleDigitalCard
              training={training}
              onAnswer={onAnswer}
              company={activeCompany}
              groupDropdown={groupDropdown}
              projectDropdown={projectDropdown}
              setCanSubmit={setCanSubmit}
              location={group}
              editing
              creating
              accessLevel={user.accessLevel}
              missingRequired={missingRequired}
            />
          ) : (
            <ScheduleInPersonCard
              training={training}
              onAnswer={onAnswer}
              setCanSubmit={setCanSubmit}
              company={activeCompany}
              location={group}
              editing
              creating
              accessLevel={user.accessLevel}
              missingRequired={missingRequired}
            />
          )
        ) : (
          <></>
        )}
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={unsavedModal}
        onRequestClose={() => setUnsavedModal(false)}
        submitActions={() => handleBack()}
        savingWhat="a page"
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Training"
      />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import ReactLoading from 'react-loading';
import moment from 'moment';

import history from '../../history';
import * as apiV4 from '../../api/v4';
import {
  addUploadedAttachmentsRequest,
  addUploadedAttachmentsResponse,
  updateAttachmentRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import { setActiveIncidentResponse } from '../../actions/incidents';
import { createReportResponse } from '../../actions/reports';
import { setActiveTaskRequest, loadTask } from '../../actions/tasks';
import {
  ACTION_COMPONENTS,
  CLAIM_STAGES,
  CLOSURE_COMPONENTS,
  HAZARD_STAGES,
  INVESTIGATION_COMPONENTS,
  REACTIVE_KEYS,
  REACTIVE_REPORT_TYPES
} from '../../constants/constants';
import { getActiveCompany } from '../../selectors/company';
import { getActiveIncidentSelector } from '../../selectors/incidents';
import {
  getDiagramSelector,
  getAddedAttachmentsSelector,
  getPhotoEvidenceSelector,
  getNonPhotoEvidenceDiagramSelector
} from '../../selectors/attachments';
import { getActiveOshaLogSelector } from '../../selectors/oshaLogs';
import { getLoggedInUser } from '../../selectors/users';
import {
  canAddReportType,
  getAvailableUsersForPermissions,
  getPercentages,
  isConfidentialReport,
  isSectionComplete
} from '../../utils/incidentHelper';
import {
  InputRow,
  Button,
  EmployeeDropdown,
  Textbox
} from '../../components/inputs';
import SvgWithTooltip from '../../components/SvgWithTooltip';
import IncidentStages from '../../components/incidentComponents/IncidentStages';
import Modal from '../../components/Modal';
import PermissionsDropdown from '../../components/inputs/Dropdown/permissionsDropdown';
import UserBadge from '../../components/UserBadge';
import ReportActions from '../../components/ReportActions';
import ReportComponentCard from '../../components/incidentComponents/ReportComponentCard';
import Assignee from '../../components/Assignee';
import Header from '../../components/Header';
import ProgressBar from '../../components/ProgressBar';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import ReassignUserModal from '../../components/Modal/reassignUserModal';
import StageSelector from '../../components/StageSelector';
import DashboardButtons from '../../components/Modal/dashboardButtons';

import './index.scss';
import DownloadReportsModal from '../../components/Modal/downloadReports';

export default function IncidentContainer() {
  const dispatch = useDispatch();
  const params = useParams();
  const setActiveTask = task => dispatch(setActiveTaskRequest(task));
  const loadTaskWithComments = payload => dispatch(loadTask(payload));
  const setActiveIncident = payload =>
    dispatch(setActiveIncidentResponse(payload));
  const addAttachment = payload =>
    dispatch(addUploadedAttachmentsRequest(payload));
  const setActiveReportComponent = payload =>
    dispatch(createReportResponse(payload));

  const [openModalType, setOpenModalType] = useState('');
  const [stage, setStage] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [reassignUserId, setReassignUserId] = useState(undefined);
  const [reportStage, setReportStage] = useState(0);
  const [permissions, setPermissions] = useState([]);
  const [downloadReport, setDownloadReport] = useState(false);
  const [users, setUsers] = useState([]);
  const [sendToList, setSendToList] = useState([]);
  const [froiClaim, setFroiClaim] = useState(undefined);
  const [autoClaim, setAutoClaim] = useState(undefined);
  const [newReportTypes, setNewReportTypes] = useState([]);

  const loggedInUser = useSelector(getLoggedInUser);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const nonPhotoEvidenceDiagrams = useSelector(
    getNonPhotoEvidenceDiagramSelector
  );
  const activeIncident = useSelector(getActiveIncidentSelector);
  const diagrams = useSelector(getDiagramSelector);
  const photoEvidence = useSelector(getPhotoEvidenceSelector);
  const activeCompany = useSelector(getActiveCompany);
  const oshaForms = useSelector(getActiveOshaLogSelector);

  const group = activeCompany.groups?.find(
    g => g._id === activeIncident?.groupId
  );
  const area = group?.projects?.find(p => p._id === activeIncident?.projectId);

  const disabled =
    group?.isHidden || area?.isHidden || activeIncident?.isArchived;

  const modalProps = {
    reports: {
      title: 'Add Incident Report(s)',
      text: 'Select one or more if applicable',
      buttons: REACTIVE_REPORT_TYPES?.filter(
        rt => rt.value !== 19 && rt.value !== 7
      ),
      onClick: v => {
        newReportTypes?.includes(v)
          ? setNewReportTypes(newReportTypes.filter(x => x !== v))
          : setNewReportTypes(
              !newReportTypes ? [].concat(v) : newReportTypes.concat(v)
            );
      },
      modalSubmit: () => {
        history.push({
          pathname: '/app/initalReportContainer',
          state: {
            fromScreen: 'reportTypesUpdate',
            newReportTypes: newReportTypes?.map(r => r.toString())
          }
        });
      }
    }
  };

  useEffect(() => {
    const userPermissions = activeIncident.permissions.find(
      p => p.userId === loggedInUser._id
    );

    if (
      params.stage === 'action' ||
      activeIncident.stage === 'action' ||
      (userPermissions?.investigation === 'None' &&
        userPermissions?.action !== 'None')
    ) {
      setStage(1);
    } else if (
      params.stage === 'closure' ||
      activeIncident.stage === 'closure' ||
      (userPermissions?.investigation === 'None' &&
        userPermissions?.action === 'None' &&
        userPermissions?.closure !== 'None')
    ) {
      setStage(2);
    }

    if (activeIncident.claims?.length) {
      setAutoClaim(activeIncident.claims?.find(c => c.claimType === 'au'));
      setFroiClaim(activeIncident.claims?.find(c => c.claimType === 'wc'));
    }

    apiV4
      .fetchAllUsersForLocation(activeIncident.locationId, {
        locationId: activeIncident.locationId,
        accessLevel: [400, 500, 900]
      })
      .then(setUsers);
  }, [activeIncident, loggedInUser, params]);

  const sendEmail = () => {
    apiV4
      .emailAssignees({
        messageContent: emailMessage,
        incidentId: activeIncident._id,
        sentMessageToIds: [activeIncident.incidentOwner]
      })
      .then(() => {
        setOpenModalType(null);
        setEmailMessage('');
      });
  };

  const handleReportComponentClick = async (reportComponent, stage) => {
    await setActiveReportComponent({
      report: reportComponent,
      stage,
      disabled
    });

    if (reportComponent[0] === 'Initial Report') {
      history.push('/app/initalReportContainer');
    } else if (reportComponent[0][0] === 'Photo Evidence') {
      history.push('/app/photoEvidenceContainer/true');
    } else if (reportComponent[0][0] === 'Diagrams') {
      history.push('/app/photoEvidenceContainer');
    } else if (
      reportComponent[0][0] === 'Root Cause' &&
      !activeIncident.isRootCauseLegacy
    ) {
      history.push(`/app/rootCauseContainer/${stage}`);
    } else if (
      reportComponent[0][0] === 'Witness Statements' &&
      !activeIncident.isWitnessLegacy
    ) {
      history.push(`/app/witnessComponentContainer/${stage}`);
    } else if (
      reportComponent[0][0] === 'Return to Work' &&
      !activeIncident.isCcLegacy
    ) {
      history.push(`/app/returnToWorkContainer/${stage}`);
    } else {
      history.push(`/app/reportSectionContainer/${stage}`);
    }
  };

  const handleReassignUser = () => {
    setActiveIncident({ ...activeIncident, incidentOwner: reassignUserId });
    apiV4.updateIncidentOwner({
      dontLeavePage: true,
      incidentId: activeIncident._id,
      newIncidentOwnerId: reassignUserId
    });

    setOpenModalType(null);
    setReassignUserId(undefined);
  };

  const submitIncidentName = async newName => {
    await setActiveIncident({
      ...activeIncident,
      incidentNumber: newName,
      incidentNumberLegacy: activeIncident.incidentNumber
    });

    apiV4.updateIncidentNumber({
      incidentId: activeIncident._id,
      incidentNumber: newName
    });
  };

  const removeActionItem = async actionItem => {
    actionItem = {
      ...actionItem,
      isRemoved: true,
      isRemovedByOwner: loggedInUser._id === activeIncident.incidentOwner
    };
    if (actionItem.type === 'attachment') {
      let flatList = addedAttachments.reduce((acc, x) => acc.concat(x), []);
      flatList = flatList.map(currentAttachment => {
        if (currentAttachment._id === actionItem._id) {
          return { ...actionItem };
        }
        return { ...currentAttachment };
      });
      await dispatch(updateAttachmentRequest({ ...actionItem }));
      await apiV4.updateActivityAttachments({ ...actionItem });
      await dispatch(addUploadedAttachmentsResponse(flatList));
    } else if (actionItem.type === 'comment') {
      apiV4.updateComment(actionItem).then(r =>
        setActiveIncident({
          ...r,
          selectedTab: activeIncident?.selectedTab,
          returnPath: activeIncident?.returnPath
        })
      );
    } else if (actionItem.type === 'log') {
      apiV4.updateNote(actionItem).then(r =>
        setActiveIncident({
          ...r,
          selectedTab: activeIncident?.selectedTab,
          returnPath: activeIncident?.returnPath
        })
      );
    }
  };

  const updateReportStage = (reportStage, closeAllComponents) => {
    apiV4
      .updateIncidentStage(activeIncident._id, {
        reportStage,
        closeAllComponents
      })
      .then(response => {
        setActiveIncident({
          ...activeIncident,
          ...response
        });
      });

    setReportStage(reportStage);
    setOpenModalType(null);
  };

  const handleReportSelectionFromTask = async taskObject => {
    const reportComponents = activeIncident.reportComponents;

    const reportComponent = reportComponents.filter(
      report => report[0] === taskObject.reportComponentName
    );

    await setActiveReportComponent({ report: reportComponent });

    if (reportComponent[0][0] === 'Photo Evidence') {
      history.push('/app/photoEvidenceContainer/true');
    } else if (reportComponent[0][0] === 'Diagrams') {
      history.push('/app/photoEvidenceContainer');
    } else if (
      reportComponent[0][0] === 'Return to Work' &&
      !activeIncident?.isCcLegacy
    ) {
      history.push(`/app/returnToWorkContainer`);
    } else {
      history.push('/app/reportSectionContainer');
    }
  };

  const addUsersToIncident = async () => {
    const perms = permissions.map(permission => ({
      userId: permission.value,
      action: 'View',
      closure: permission.accessLevel < 500 ? 'None' : 'View',
      investigation: 'View'
    }));

    await setActiveIncident({
      ...activeIncident,
      permissions: [...activeIncident.permissions, ...perms]
    });

    setPermissions([]);
  };

  const removeUsersFromIncident = async user => {
    const perms = activeIncident.permissions.filter(
      permission => permission.userId !== user.userId
    );

    await setActiveIncident({
      ...activeIncident,
      permissions: [...perms]
    });
  };

  const handleUserPermissionUpdates = user => {
    const userIndex = activeIncident.permissions.findIndex(
      perm => perm.userId === user.userId
    );

    let incident = { ...activeIncident };

    incident.permissions[userIndex] = {
      ...activeIncident.permissions[userIndex],
      ...user
    };

    setActiveIncident(incident);
  };

  const handlePermissions = () => {
    apiV4.updateIncidentPermissions(
      activeIncident._id,
      activeIncident.permissions
    );
    setOpenModalType(null);
  };

  const printReport = ({ options, reportTypes }) => {
    setDownloadReport(true);
    setOpenModalType(null);

    let reportOptions = {};
    options.forEach(report => (reportOptions[report] = true));

    apiV4
      .printReport({
        incidentId: activeIncident._id,
        options: reportOptions,
        reportTypes
      })
      .then(url => {
        url.map(report => window.open(report, '_blank'));
        setDownloadReport(false);
      });
  };

  const getCurrentNumber = (
    activeComponent,
    photoEvidence,
    diagrams,
    oshaForms
  ) => {
    if (activeComponent[0][0] === 'Photo Evidence') {
      return photoEvidence.filter(photo => !photo.isRemoved).length;
    } else if (activeComponent[0][0] === 'Diagrams') {
      return diagrams.length;
    } else if (
      activeComponent[0][0] === 'Witness Statements' &&
      activeIncident.isWitnessLegacy
    ) {
      const subSections = activeComponent[0][1].subSections;
      subSections.filter(section => isSectionComplete(section));
      return subSections.length;
    } else if (activeComponent[0][0] === 'Witness Statements') {
      return activeComponent[0][1]?.statements?.length ?? 0;
    } else if (activeComponent[0][0] === 'OSHA Compliance') {
      return oshaForms.filter(o => o._id).length;
    } else if (activeComponent[0][0] === 'Corrective Actions') {
      return activeComponent[0][6]?.minSections;
    } else if (
      activeComponent[0][0] === 'Return to Work' &&
      !activeIncident.isCcLegacy
    ) {
      return activeComponent[0][1]?.forms?.length;
    }

    return '';
  };

  const typeOfClaimAccess = () => {
    if (loggedInUser.isAccountOwner) return 'Edit';

    const companyAccess = activeCompany?.claimPermissions?.find(
      p => p.userId === loggedInUser._id
    );
    const claimAccess = activeIncident?.claimPermissions?.find(
      p => p.userId === loggedInUser._id
    );

    if (!companyAccess && !claimAccess) return 'None';

    if (
      !activeIncident.claimId &&
      (companyAccess?.readOnly ?? claimAccess?.readOnly)
    )
      return 'None';

    return companyAccess?.readOnly ?? claimAccess?.readOnly ? 'View' : 'Edit';
  };

  const handleClaimClick = () => {
    const { claims, reportTemplateIds } = activeIncident;

    if (reportTemplateIds.includes('1') && reportTemplateIds.includes('2')) {
      setOpenModalType('fileMultipleClaims');
    } else {
      if (claims?.length === 1) {
        history.push(
          `/app/claims/${
            reportTemplateIds.includes('2')
              ? 'wc'
              : reportTemplateIds.includes('1')
              ? 'auto'
              : 'damage'
          }/${claims[0].claimId}`
        );
      } else {
        setOpenModalType('fileClaim');
      }
    }
  };

  const handleClaimButtonText = () => {
    const { claims, reportTemplateIds } = activeIncident;

    if (reportTemplateIds.includes('1') && reportTemplateIds.includes('2')) {
      if (
        (froiClaim?.claimSubmitted && autoClaim?.claimSubmitted) ||
        (froiClaim?.claimSubmitted && !autoClaim) ||
        (autoClaim?.claimSubmitted && !froiClaim)
      ) {
        return 'View';
      } else if (froiClaim?.claimInProgress || autoClaim?.claimInProgress) {
        return 'Continue';
      }
    } else {
      if (claims?.length === 1) {
        return claims[0].claimSubmitted ? 'View' : 'Continue';
      }
    }
    return 'File';
  };

  const handleArchive = () => {
    apiV4
      .archiveIncident({
        incidentId: activeIncident._id,
        isArchived: !activeIncident.isArchived
      })
      .then(() => {
        if (!activeIncident.isArchived)
          history.push(
            `/app/${
              activeIncident.reportTemplateIds?.length > 1
                ? 'incidents'
                : 'reportStages/' + activeIncident.reportTemplateIds.toString()
            }`
          );
        else
          setActiveIncident({
            ...activeIncident,
            isArchived: !activeIncident.isArchived
          });
      });
  };

  const initialReportComponent = ['Initial Report', []];
  const isConfidential = isConfidentialReport(activeIncident);

  const permUsers = getAvailableUsersForPermissions(
    users,
    activeIncident,
    isConfidential ? activeCompany?.adminsWithConfidentialAccess ?? [] : []
  );

  const percentages =
    activeIncident &&
    getPercentages(
      activeIncident.reportComponents,
      Number(stage),
      activeIncident.reportTemplateIds
    );

  const getReportStages = activeIncident?.reportTemplateIds
    ? REACTIVE_KEYS.indexOf(parseInt(activeIncident.reportTemplateIds[0], 10)) >
      -1
      ? CLAIM_STAGES.map((stage, index) => {
          return {
            value: index,
            label: stage
          };
        })
      : HAZARD_STAGES.map((stage, index) => {
          return {
            value: index,
            label: stage
          };
        })
    : [];

  const rStage = reportStage || activeIncident.reportStage;
  const center = (
    <StageSelector
      options={getReportStages}
      currentValue={rStage?.value ?? rStage}
      onChange={stage =>
        stage?.label === 'Closed'
          ? setOpenModalType('stage')
          : updateReportStage(stage, false)
      }
      bareValues={false}
      label={
        REACTIVE_KEYS.indexOf(
          parseInt(activeIncident.reportTemplateIds[0], 10)
        ) > -1
          ? 'Report Status'
          : 'Hazard Stage'
      }
      disabled={disabled}
    />
  );

  const right = (
    <Assignee
      text="Incident Owner"
      user={activeIncident?.incidentOwner}
      options={[
        {
          label: 'Email Incident Owner',
          onClick: () => setOpenModalType('emailIncidentOwner'),
          visible: !disabled
        },
        {
          label: 'Reassign Incident',
          visible:
            !disabled &&
            (loggedInUser?.accessLevel === 900 ||
              activeIncident?.incidentOwner === loggedInUser._id),
          onClick: () => setOpenModalType('reassignIncident')
        },
        {
          label: 'Assign Permissions',
          visible:
            !disabled &&
            (loggedInUser?.accessLevel === 900 ||
              activeIncident?.incidentOwner === loggedInUser._id),
          onClick: () => setOpenModalType('editPermissions')
        },
        {
          label: 'Delete Incident',
          visible:
            loggedInUser?.accessLevel === 900 ||
            activeIncident?.incidentOwner === loggedInUser._id,
          onClick: () => setOpenModalType('deleteIncident')
        },
        {
          label: 'Email Initial Report PDF',
          visible: !disabled,
          onClick: () => setOpenModalType('emailInitialReport')
        },
        {
          label: 'Add Report Type(s)',
          onClick: () =>
            setOpenModalType(
              isConfidential ? 'confidentialReports' : 'reports'
            ),
          visible:
            !disabled &&
            canAddReportType(
              loggedInUser,
              activeIncident?.permissions,
              activeIncident.reportTemplateIds
            )
        },
        {
          label: `${activeIncident?.isArchived ? 'Un-' : ''}Archive Incident`,
          onClick: handleArchive,
          visible: loggedInUser.accessLevel > 400
        }
      ]}
    />
  );

  const header = (
    <Header
      clickBack={() => {
        setActiveIncident({});
        dispatch(clearUploadedAttachments());
        history.push({
          pathname: activeIncident?.returnPath ?? '/app/dashboard',
          state: { selectedTab: activeIncident?.selectedTab }
        });
      }}
      title="Incident ID"
      section={activeIncident?.incidentNumber}
      editName={disabled ? null : submitIncidentName}
      center={center}
      right={right}
    />
  );

  return !activeIncident?._id ? (
    <></>
  ) : (
    <>
      <HeaderAndFooter Header={header} className="incidentContainer-mainPage">
        <div className="incidentContainer-mainPage--left">
          <div
            className={
              stage === 2 &&
              (activeIncident.reportTemplateIds.includes('1') ||
                activeIncident.reportTemplateIds.includes('2')) &&
              activeCompany.showClaims &&
              ['View', 'Edit'].includes(typeOfClaimAccess())
                ? 'incidentContainer-mainPage--categorization claims'
                : 'incidentContainer-mainPage--categorization'
            }
          >
            <Button
              text={`${handleClaimButtonText()} Claim(s)`}
              color="blue"
              onClick={handleClaimClick}
              className="file-claim-button"
            />

            <div className="incidentContainer-reportCategories">
              <div className="incidentContainer-reportCategories--reported">
                <span>
                  Reported at{' '}
                  {moment(Date.parse(activeIncident.createdAt)).format(
                    'hh:mm A'
                  )}
                </span>
                <span style={{ fontWeight: 'bold' }}>
                  {activeIncident.createdAt &&
                    moment(Date.parse(activeIncident.createdAt)).format('LL')}
                </span>
              </div>
              {activeIncident.reportTemplateIds.map((templateId, index) => (
                <SvgWithTooltip type={templateId} key={index} />
              ))}
            </div>
            {downloadReport ? (
              <ReactLoading
                type="spinningBubbles"
                color="#008284"
                className="loading"
              />
            ) : (
              <Button
                text="Download Reports"
                color="blue"
                onClick={() => setOpenModalType('downloadReport')}
              />
            )}
          </div>
          <div className="incidentContainer-incidentCard">
            <IncidentStages
              changeStage={setStage}
              selectedStage={stage}
              activeIncident={activeIncident}
              loggedInUser={loggedInUser}
            />
            <div className="incidentContainer-incidentCard--content">
              <ProgressBar
                scale={percentages.total}
                complete={percentages.completed / percentages.total === 1}
                bars={[
                  {
                    width: percentages.completed,
                    color: 'green'
                  },
                  {
                    width: percentages.started,
                    color: 'blue'
                  },
                  {
                    width: percentages.pastDue,
                    color: 'red'
                  }
                ]}
              />
              {stage === '' ? (
                <div className="incidentContainer-cardContainer">
                  <ReportComponentCard
                    reportComponent={initialReportComponent}
                    setActiveReportComponent={() =>
                      handleReportComponentClick(
                        initialReportComponent,
                        'investigation'
                      )
                    }
                    activeIncident={activeIncident}
                    loggedInUser={loggedInUser}
                    stage="investigation"
                    setActiveTask={setActiveTask}
                    activeCompany={activeCompany}
                    disabled={disabled}
                  />
                  {INVESTIGATION_COMPONENTS.map((component, index) => {
                    const activeComponent = activeIncident.reportComponents.filter(
                      report => report[0] === component
                    );
                    if (!activeComponent?.length)
                      return <div key={index}></div>;
                    return (
                      <ReportComponentCard
                        key={index}
                        reportComponent={activeComponent[0]}
                        setActiveReportComponent={() =>
                          handleReportComponentClick(
                            activeComponent,
                            'investigation'
                          )
                        }
                        activeIncident={activeIncident}
                        loggedInUser={loggedInUser}
                        currentNumber={getCurrentNumber(
                          activeComponent,
                          photoEvidence,
                          diagrams,
                          oshaForms
                        )}
                        showNumbers
                        stage="investigation"
                        setActiveTask={setActiveTask}
                        activeCompany={activeCompany}
                        disabled={disabled}
                      />
                    );
                  })}
                </div>
              ) : stage === 1 ? (
                <div className="incidentContainer-cardContainer">
                  {ACTION_COMPONENTS.map((component, index) => {
                    const activeComponent = activeIncident.reportComponents.filter(
                      report => report[0] === component
                    );
                    if (!activeComponent?.length) return <></>;
                    return (
                      <ReportComponentCard
                        key={index}
                        reportComponent={activeComponent[0]}
                        setActiveReportComponent={() =>
                          handleReportComponentClick(activeComponent, 'action')
                        }
                        currentNumber={getCurrentNumber(
                          activeComponent,
                          photoEvidence,
                          diagrams,
                          oshaForms
                        )}
                        activeIncident={activeIncident}
                        loggedInUser={loggedInUser}
                        showNumbers
                        stage="action"
                        setActiveTask={setActiveTask}
                        disabled={disabled}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="incidentContainer-cardContainer">
                  {CLOSURE_COMPONENTS.map((component, index) => {
                    const activeComponent = activeIncident.reportComponents.filter(
                      report => report[0] === component
                    );

                    if (
                      component !== 'Return to Work' &&
                      loggedInUser?.accessLevel === 400
                    )
                      return <></>;
                    if (!activeComponent?.length) return <></>;

                    return (
                      <ReportComponentCard
                        key={index}
                        reportComponent={activeComponent[0]}
                        setActiveReportComponent={() =>
                          handleReportComponentClick(activeComponent, 'closure')
                        }
                        currentNumber={getCurrentNumber(
                          activeComponent,
                          photoEvidence,
                          diagrams,
                          oshaForms
                        )}
                        activeIncident={activeIncident}
                        loggedInUser={loggedInUser}
                        showNumbers
                        stage="closure"
                        setActiveTask={setActiveTask}
                        activeCompany={activeCompany}
                        oshaForms={
                          activeComponent[0][0] === 'OSHA Compliance'
                            ? oshaForms
                            : null
                        }
                        disabled={disabled}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="incidentContainer-mainPage--right">
          <ReportActions
            comments={activeIncident.incidentActivity.comments}
            notes={activeIncident.incidentActivity.notes}
            activityItems={activeIncident.incidentActivity.activityItems}
            tasks={activeIncident.incidentActivity.openTasks}
            attachments={nonPhotoEvidenceDiagrams}
            loggedInUser={loggedInUser}
            activeIncident={activeIncident}
            addNote={note => {
              apiV4
                .addNote({
                  value: note,
                  ownerId: activeIncident._id,
                  ownerType: 'incident'
                })
                .then(r =>
                  setActiveIncident({
                    ...r,
                    selectedTab: activeIncident?.selectedTab,
                    returnPath: activeIncident?.returnPath
                  })
                );
            }}
            addComment={comment => {
              apiV4
                .addComment({
                  value: comment,
                  ownerId: activeIncident._id,
                  ownerType: 'incident'
                })
                .then(r =>
                  setActiveIncident({
                    ...r,
                    selectedTab: activeIncident?.selectedTab,
                    returnPath: activeIncident?.returnPath
                  })
                );
            }}
            removeActionItem={actionItem => removeActionItem(actionItem)}
            addAttachment={addAttachment}
            handleReportSelection={task => handleReportSelectionFromTask(task)}
            setActiveTask={setActiveTask}
            loadTask={loadTaskWithComments}
            stage={stage}
            disabled={disabled}
          />
        </div>
      </HeaderAndFooter>
      <DownloadReportsModal
        isOpen={openModalType === 'downloadReport'}
        onRequestClose={() => setOpenModalType()}
        user={loggedInUser}
        activeIncident={activeIncident}
        submitActions={printReport}
      />

      <Modal
        title="Email Incident Owner"
        titleClassName="blueHeader"
        isOpen={openModalType === 'emailIncidentOwner'}
        submitButtonColor="blue"
        submitButtonText="Send Email"
        onRequestClose={() => {
          setOpenModalType(null);
          setEmailMessage('');
        }}
        submitActions={sendEmail}
        disableSubmit={!emailMessage}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          This email will go through the iReportSource system for record keeping
          purposes.
        </div>
        <Textbox
          fieldLabel="Email Content"
          type="textarea"
          placeholder="Type email content here"
          currentValue={emailMessage}
          onChange={setEmailMessage}
        />
      </Modal>
      <Modal
        title={`Delete ${activeIncident.incidentNumber}`}
        titleClassName="redHeader"
        isOpen={openModalType === 'deleteIncident'}
        submitButtonColor="red"
        submitButtonText="Delete Incident"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={async () =>
          await apiV4
            .deleteIncident(activeIncident._id)
            .then(history.push('/app/dashboard'))
        }
      >
        <div className="reportSectionContainer-emailAssigneeText">
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            Deleting this incident will delete all relavent data with the
            incident forever.{' '}
          </span>
          Are you sure?
        </div>
      </Modal>
      <ReassignUserModal
        title="Reassign Incident Owner"
        onRequestClose={() => {
          setOpenModalType(null);
          setReassignUserId(null);
        }}
        isOpen={openModalType === 'reassignIncident'}
        employeeFieldLabel="Default Incident Owner"
        submitActions={() => handleReassignUser()}
        onChange={setReassignUserId}
        message={
          <div className="reportSectionContainer-emailAssigneeText">
            From here you can assign a different user as the owner of this
            incident.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              The previous incident owner will be removed, lose special
              privileges, and be replaced by the new incident owner.
            </span>{' '}
            Incident ownership can only by assigned to Collaborators or Admins
            with assess to the Establishment/Group of this incident.
          </div>
        }
        previousUser={activeIncident.incidentOwner}
        disableSubmit={!reassignUserId}
        reassignUser={reassignUserId}
        permissions={[500, 900]}
        limit={
          isConfidential
            ? activeCompany?.adminsWithConfidentialAccess
            : undefined
        }
      />
      <Modal
        title="Edit Permissions"
        titleClassName="blueHeader"
        isOpen={openModalType === 'editPermissions'}
        submitButtonColor="blue"
        submitButtonText="Save Changes"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={handlePermissions}
      >
        <div className="incidentContainer-editPermissions">
          <div className="incidentContainer-editPermissions--stages">
            <div>Users</div>
            <div>Investigation</div>
            <div>Action</div>
            <div>Closure</div>
          </div>
          <ul className="incidentContainer-editPermissions--userList">
            {activeIncident.permissions
              .map(p => ({ ...p, user: users.find(u => u._id === p.userId) }))
              .filter(p => p.user)
              .map((user, index) => (
                <li key={index}>
                  <div className="userTable">
                    {user.user.accessLevel !== 900 && (
                      <img
                        src={require('../../assets/images/removePermissions.png')}
                        className="removePermissions"
                        alt="Remove permissions from Incident"
                        onClick={() => removeUsersFromIncident(user)}
                      />
                    )}
                    <UserBadge
                      userId={user.userId}
                      showImage
                      showName
                      className="incidentContainer-editPermissions--user"
                    />
                  </div>
                  <div className="userTable">
                    <PermissionsDropdown
                      currentValue={user.investigation}
                      onChange={value =>
                        handleUserPermissionUpdates({
                          investigation: value,
                          userId: user.userId
                        })
                      }
                      accessLevel={user.user.accessLevel}
                      stage={0}
                    />
                  </div>
                  <div className="userTable">
                    <PermissionsDropdown
                      currentValue={user.action}
                      onChange={value =>
                        handleUserPermissionUpdates({
                          action: value,
                          userId: user.userId
                        })
                      }
                      accessLevel={user.user.accessLevel}
                      stage={1}
                    />
                  </div>
                  <div className="userTable">
                    <PermissionsDropdown
                      currentValue={user.closure}
                      onChange={value =>
                        handleUserPermissionUpdates({
                          closure: value,
                          userId: user.userId
                        })
                      }
                      accessLevel={user.user.accessLevel}
                      stage={2}
                      disabled={
                        user.user.accessLevel < 500 &&
                        !activeIncident?.reportTemplateIds?.includes('2')
                      }
                    />
                  </div>
                </li>
              ))}
          </ul>
          <InputRow>
            <EmployeeDropdown
              multi
              selectButtons
              employees={permUsers}
              currentValue={permissions}
              onChange={setPermissions}
              disabled={permUsers.length === 0}
              placeholder={
                permUsers.length === 0
                  ? 'No Users Available'
                  : 'Select Users to add'
              }
              className="incidentContainer-editPermissions--grow"
              bareValues={false}
            />
            <Button
              text="Add"
              color="blue"
              onClick={addUsersToIncident}
              disabled={permUsers.length === 0}
            />
          </InputRow>
          <div className="reportSectionContainer-emailAssigneeText">
            Only Collaborators and Admins may be given full permissions.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Admins can not be removed from any stage or the incident as a
              whole.
            </span>{' '}
            Supervisors can only be added to Investigation, and Reporters can
            not be added.{' '}
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              Removing a user’s permission from all stages will completely
              remove them after saving changes.
            </span>
          </div>
        </div>
      </Modal>
      <Modal
        title="Email Initial Report PDF"
        titleClassName="blueHeader"
        isOpen={openModalType === 'emailInitialReport'}
        className="modalBig"
        submitButtonColor="blue"
        submitButtonText="Send Email"
        disableSubmit={!sendToList.length}
        onRequestClose={() => {
          setOpenModalType(null);
          setSendToList([]);
        }}
        submitActions={async () => {
          setOpenModalType(null);
          await apiV4.emailInitialReport({
            incidentId: activeIncident._id,
            sendToList
          });
          setSendToList([]);
        }}
      >
        <EmployeeDropdown
          fieldLabel="Send Initial Report PDF to"
          searchable
          multi
          isRequired
          permissions={[100, 400, 500, 900]}
          currentValue={sendToList}
          onChange={setSendToList}
        />
      </Modal>
      <Modal
        title="File a Claim"
        titleClassName="blueHeader"
        isOpen={openModalType === 'fileClaim'}
        submitButtonColor="blue"
        submitButtonText="File"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() =>
          history.push(
            `/app/claims/${
              activeIncident.reportTemplateIds.includes('2')
                ? 'wc'
                : activeIncident.reportTemplateIds.includes('1')
                ? 'auto'
                : 'damage'
            }`
          )
        }
      >
        Are you sure you want to file a claim?{' '}
        <span className="blueText">
          Filing a claim through iReportSource will not file claim with your
          insurance provider until you submit a claim through iReportSource.
        </span>
      </Modal>
      <Modal
        title="Close Report"
        titleClassName="greenHeader"
        isOpen={openModalType === 'stage'}
        submitButtonColor="green"
        submitButtonText="Yes"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() =>
          updateReportStage({ value: 5, label: 'Closed' }, true)
        }
        cancelButtonText="No"
        cancelActions={() =>
          updateReportStage({ value: 5, label: 'Closed' }, false)
        }
      >
        You are about to close this report. Do you want all the report sections
        closed as well? By hitting Yes, all sections will be closed and will
        have to be manually reopened.
      </Modal>
      <Modal
        title="File Claim(s)"
        titleClassName="blueHeader"
        isOpen={openModalType === 'fileMultipleClaims'}
        submitButtonColor="blue"
        submitButtonText={`${
          froiClaim?.claimSubmitted
            ? 'View'
            : froiClaim?.claimInProgress
            ? 'Continue'
            : 'File'
        } WC Claim`}
        submitActions={() =>
          history.push(`/app/claims/wc/${froiClaim?.claimId ?? ''}`)
        }
        cancelButtonColor="blue"
        cancelButtonText={`${
          autoClaim?.claimSubmitted
            ? 'View'
            : autoClaim?.claimInProgress
            ? 'Continue'
            : 'File'
        } Auto Claim`}
        cancelActions={() =>
          history.push(`/app/claims/auto/${autoClaim?.claimId ?? ''}`)
        }
        onRequestClose={() => setOpenModalType(null)}
      >
        Are you sure you want to file a claim?
        <span className="blueText">
          Filing a claim through iReportSource will not file claim with your
          insurance provider until you submit a claim through iReportSource.
        </span>
        What type of claim do you want to file?
      </Modal>
      {openModalType === 'reports' ? (
        <DashboardButtons
          modalOpen={openModalType}
          modalProps={modalProps}
          handleCloseModal={() => {
            setOpenModalType(null);
            setNewReportTypes([]);
          }}
          modalSelection={newReportTypes}
          setModalSelection={setNewReportTypes}
          company={activeCompany}
          alreadySelected={activeIncident?.reportTemplateIds?.map(r =>
            parseInt(r, 10)
          )}
          submitButtonText="Add"
        />
      ) : null}
      <Modal
        title="Confidential Report"
        titleClassName="blueHeader"
        isOpen={openModalType === 'confidentialReports'}
        submitButtonColor="blue"
        submitButtonText="Proceed"
        submitActions={() => setOpenModalType('reports')}
        cancelButtonColor="blue"
        cancelButtonText="Cancel"
        cancelActions={() => setOpenModalType(null)}
        onRequestClose={() => setOpenModalType(null)}
      >
        You are about to add a report type to a report that is{' '}
        <span style={{ fontWeight: 'bold' }}>confidential</span>. Any data
        captured in either report type will show as Confidentital Report to
        users without access. Do you wish to proceed?
      </Modal>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../../history';
import {
  createSafetyMeeting,
  deleteAttachment,
  deleteSafetyMeeting,
  fetchSafetyMeetingById,
  fetchSafetyMeetingTemplates,
  printSafetyMeeting,
  updateSafetyMeeting,
  uploadAttachment
} from '../../../api/v4';
import { getAllUsers, getLoggedInUser } from '../../../selectors/users';
import { formatFiles } from '../../../utils/attachmentSelector';
import useActiveHeirarchy from '../../../utils/useActiveHeirarchy';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import LeftNav from '../../../components/LeftNav';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import Card from '../../../components/Card';
import {
  Dropdown,
  EmployeeDropdown,
  Textbox,
  TwoColumn
} from '../../../components/inputs';
import GeneratorCard from '../../../components/behaviorObservations/GeneratorCard';
import HierarchySelector from '../../../components/HierarchySelector';
import AttachmentUploadCard from '../../../components/AttachmentUploadCard';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

export default function SafetyMeetingContainer() {
  const { id } = useParams();
  const user = useSelector(getLoggedInUser);
  const allUsers = useSelector(getAllUsers);
  const { company, location, project } = useActiveHeirarchy();

  const [meeting, setMeeting] = useState({});
  const [templates, setTemplates] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);
  const [addedAttachments, setAttachments] = useState([]);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const group = company.groups?.find(g => g._id === meeting?.groupId);
  const area = group?.projects?.find(p => p._id === meeting?.projectId);

  const disabled = group?.isHidden || area?.isHidden || meeting?.isArchived;

  useEffect(() => {
    if (id) {
      fetchSafetyMeetingById(id).then(r => {
        setMeeting(r);
        setAttachments(r.attachments);
      });
    } else {
      setCreating(true);
    }
    fetchSafetyMeetingTemplates().then(response => {
      response = response.filter(template => !template.isArchived);
      setTemplates(response);
    });
  }, [id]);
  useEffect(() => {
    const groupUsers = allUsers
      ?.filter(u => u?.groupIds?.includes(location._id ?? meeting.groupId))
      ?.map(u => u._id);
    const newAdmin = groupUsers?.find(a => a === meeting.admin);
    const newAttendees = meeting.attendees?.filter(att =>
      groupUsers?.includes(att)
    );
    if (
      newAdmin !== meeting.admin ||
      newAttendees?.join() !== meeting.attendees?.join()
    )
      setMeeting(m => ({
        ...m,
        attendees: newAttendees,
        admin: newAdmin
      }));
  }, [
    meeting.groupId,
    allUsers,
    meeting.admin,
    location._id,
    meeting.attendees
  ]);

  const addAttachments = attachments => {
    if (!attachments) return;
    let data = formatFiles(attachments);
    uploadAttachment(data, {
      ownerType: 'safetyMeeting',
      ownerId: id,
      isSignature: false,
      isPhotoEvidence: false
    }).then(response => {
      setAttachments([...addedAttachments, ...response]);
      setUnsavedChanges(true);
    });
  };

  const handlePrint = () => {
    printSafetyMeeting(id).then(response => {
      window.open(response, '_blank');
    });
  };

  const canSubmit =
    (meeting.groupId || location) && meeting.templateId && meeting.admin;

  const handleSubmit = () => {
    if (meeting.projectId === '') meeting.projectId = null;
    if (id) {
      updateSafetyMeeting({ ...meeting, attachments: addedAttachments }).then(
        r => {
          setMeeting(r);
          setEditing(false);
          setUnsavedChanges(false);
        }
      );
    } else {
      delete meeting._id;
      if (filesToUpload?.length > 0) {
        let data = formatFiles(filesToUpload);
        uploadAttachment(data, {
          ownerType: 'safetyMeeting',
          ownerId: 'pending',
          isSignature: false,
          isPhotoEvidence: false
        }).then(attachments => {
          createSafetyMeeting({
            ...meeting,
            groupId: meeting.groupId ?? location._id,
            projectId: meeting.projectId ?? project?._id,
            attachments: [
              ...attachments.map(a => a._id),
              ...addedAttachments.map(a => a._id)
            ]
          }).then(async r => {
            setMeeting(r);
            setAttachmentsToUpload([]);
            setFilesToUpload([]);
            setEditing(false);
            setCreating(false);
            setUnsavedChanges(false);
            history.push(`/app/safetyMeeting/container/${r._id}`);
          });
        });
      } else {
        createSafetyMeeting({
          ...meeting,
          groupId: meeting.groupId ?? location._id,
          projectId: meeting.projectId ?? project?._id
        }).then(async r => {
          setMeeting(r);
          setAttachmentsToUpload([]);
          setFilesToUpload([]);
          setEditing(false);
          setCreating(false);
          setUnsavedChanges(false);
          history.push(`/app/safetyMeeting/container/${r._id}`);
        });
      }
    }
  };

  const handleGoBack = () => {
    history.push('/app/safetyMeeting/list');
  };

  const handleArchive = () => {
    updateSafetyMeeting({
      ...meeting,
      attachments: addedAttachments,
      isArchived: !meeting.isArchived
    }).then(response => {
      if (response.isArchived) history.push('/app/safetyMeeting/list');
      else {
        setMeeting(response);
        setUnsavedChanges(false);
      }
    });
  };

  const header = (
    <Header
      title="Safety Meeting"
      section={`${
        !editing && !creating ? 'View' : creating ? 'Create' : 'Edit'
      } Safety Meeting`}
      needsSaved={unsavedChanges}
      clickBack={() =>
        unsavedChanges ? setOpenSaveChangesModal(true) : handleGoBack()
      }
      pageActionOptions={
        !creating
          ? [
              {
                label: 'Print Safety Meeting',
                color: 'blueOutline',
                onClick: handlePrint,
                visible: user.accessLevel >= 400 && !editing
              },
              {
                label: 'Edit Safety Meeting',
                color: 'blueOutline',
                onClick: () => setEditing(true),
                visible: user.accessLevel >= 400 && !editing && !disabled
              },
              {
                label: 'Delete Safety Meeting',
                color: 'redOutline',
                onClick: () => setOpenDeleteModal(true),
                visible: user.accessLevel >= 400
              },
              {
                label: `${
                  meeting?.isArchived ? 'Un-' : ''
                }Archive Safety Meeting`,
                color: 'blueOutline',
                onClick: () => handleArchive(),
                visible: user.accessLevel > 400
              }
            ]
          : null
      }
    />
  );

  const left = (
    <LeftNav
      items={
        meeting?.title
          ? [
              { value: 0, label: 'Safety Meeting Info' },
              { value: 1, label: meeting?.title },
              { value: 2, label: 'Attachments' }
            ].filter(x => x !== null)
          : [{ value: 0, label: 'Safety Meeting Info' }]
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit ? setMissingModalOpen(true) : handleSubmit()
      }
      saveButtonDisabled={!unsavedChanges && editing}
      saveButtonText={creating ? 'Create' : 'Save'}
      editing={editing}
      cancelButtonClick={() =>
        editing && unsavedChanges
          ? setOpenSaveChangesModal(true)
          : editing & !unsavedChanges
          ? setEditing(false)
          : setOpenSaveChangesModal(true)
      }
      onMouseEnter={() => setMissingRequired(!canSubmit)}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      showFooter={editing || creating}
      Left={left}
    >
      <Card title="Information Card" showHeader name={0}>
        <HierarchySelector
          groupRequired={true}
          groupId={meeting.groupId}
          projectId={meeting.projectId}
          onGroupChange={value => {
            setUnsavedChanges(true);
            setMeeting({
              ...meeting,
              groupId: value,
              projectId: null
            });
          }}
          onProjectChange={value => {
            setUnsavedChanges(true);
            setMeeting({ ...meeting, projectId: value });
          }}
          projectDisabled={
            !(meeting.groupId || location) || (!editing && !creating)
          }
          groupDisabled={!creating}
          touched={missingRequired && !meeting.groupId}
        />
        <Dropdown
          fieldLabel="Template"
          currentValue={meeting.templateId}
          options={
            id
              ? [
                  {
                    value: meeting.templateId,
                    label: meeting.title
                  }
                ]
              : templates?.map(template => {
                  return {
                    value: template._id,
                    label: template.title,
                    instructions: template.instructions,
                    fields: template.fields,
                    title: template.title
                  };
                })
          }
          onChange={value => {
            const template = templates.find(a => a._id === value);
            setUnsavedChanges(true);
            setAttachments(template.attachments);
            setMeeting({
              ...meeting,
              ...template,
              instructions: template.instructions ?? '',
              templateId: value
            });
          }}
          disabled={!creating}
          isRequired
          touched={missingRequired && !meeting.templateId}
        />
        <Textbox
          fieldLabel="Instructions"
          onChange={value => setMeeting({ ...meeting, instructions: value })}
          currentValue={meeting?.instructions}
          disabled={true}
          type="textarea"
        />
        <TwoColumn>
          <EmployeeDropdown
            fieldLabel="Administrator"
            searchable
            currentValue={meeting.admin}
            permissions={[400, 500, 900]}
            onChange={value => {
              setUnsavedChanges(true);
              setMeeting({
                ...meeting,
                admin: value
              });
            }}
            group={location?._id ?? meeting.groupId}
            disabled={
              (!editing && !creating) || (!meeting.groupId && !location)
            }
            bareValues
            isRequired
            touched={missingRequired && !meeting.admin}
          />
          <EmployeeDropdown
            fieldLabel="Attendees"
            searchable
            selectButtons
            currentValue={meeting.attendees}
            onChange={value => {
              setUnsavedChanges(true);
              setMeeting({
                ...meeting,
                attendees: value
              });
            }}
            group={meeting.groupId}
            multi
            disabled={!editing && !creating}
          />
        </TwoColumn>
      </Card>
      {meeting.templateId ? (
        <>
          <GeneratorCard
            name={1}
            observation={meeting}
            onChange={values => {
              setMeeting({ ...meeting, ...values });
              setUnsavedChanges(true);
            }}
            disabled={!creating}
            creating={creating}
            editing={editing}
            isObservation={false}
          />
          <AttachmentUploadCard
            name={2}
            addAttachments={addAttachments}
            deleteAttachment={x => {
              deleteAttachment(x._id).then(() =>
                setAttachments(addedAttachments.filter(a => a._id !== x._id))
              );
            }}
            isEditing={editing}
            isCreating={creating}
            handleAnswer={(currentAttachments, currentFiles) => {
              setAttachmentsToUpload(currentAttachments);
              setFilesToUpload(currentFiles);
              setUnsavedChanges(true);
            }}
            addedAttachments={addedAttachments || []}
            attachmentsToUpload={attachmentsToUpload}
            formattedAttachmentsToUpload={filesToUpload}
            ownerType="safetyMeeting"
            createdBy={user}
          />
        </>
      ) : (
        <></>
      )}

      <DeleteItemModal
        deletingWhat="Safety Meeting"
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={() => {
          deleteSafetyMeeting(id).then(() =>
            history.push('/app/safetyMeeting/list')
          );
        }}
      />
      <SaveChangesModal
        savingWhat="Safety Meeting"
        isOpen={openSaveChangesModal}
        onRequestClose={() => setOpenSaveChangesModal(false)}
        submitActions={handleGoBack}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Safety Meeting"
      />
    </HeaderAndFooter>
  );
}

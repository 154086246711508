import ApproveTraining from '../containers/Training/Approve';
import BehaviorObservations from '../containers/BehaviorObservations';
import BehaviorObservationContainer from '../containers/BehaviorObservations/BOContainer';
import BehaviorObservationTemplates from '../containers/BehaviorObservations/templateList';
import BehaviorObservationTemplate from '../containers/BehaviorObservations/Template';
import AnnouncementList from '../containers/Announcement/List';
import AnnouncementContainer from '../containers/Announcement/Container';
import Claims from '../containers/Claims';
import AutoClaim from '../containers/Claims/Auto';
import DamageClaim from '../containers/Claims/Damage';
import WcClaim from '../containers/Claims/WC';
import CustomDocumentContainer from '../containers/CustomDocumentContainer';
import CustomDocumentList from '../containers/CustomDocumentList';
import CustomDocumentTemplateContainer from '../containers/CustomDocumentTemplateContainer';
import CustomDocumentTemplateList from '../containers/CustomDocumentTemplateList';
import CustomReportQuestionContainer from '../containers/CustomReportQuestionContainer';
import CustomReportQuestionsList from '../containers/CustomReportQuestionsList';
import CustomTrainingTemplates from '../containers/Training/Template';
import Dashboard from '../containers/Dashboard';
import FireExposureForm from '../containers/FireExposureForm';
import HazardCard from '../containers/SafetyWalkContainer/HazardCard';
import IncidentContainer from '../containers/IncidentContainer';
import IncidentStages from '../containers/IncidentStages';
import InitalReportContainer from '../containers/InitialReportContainer';
import BravoCard from '../containers/SafetyWalkContainer/BravoCard';
import MyAnalytics from '../containers/MyAnalytics';
import MyAnalyticsChart from '../containers/MyAnalytics/Chart';
import MyAnalyticsDashboard from '../containers/MyAnalytics/Dashboard';
import MyAnalyticsDetail from '../containers/MyAnalytics/Detail';
import MyDocumentContainer from '../containers/MyDocumentContainer';
import MyDocumentsStages from '../containers/MyDocumentsStages';
import OshaLog from '../containers/OshaLog';
import PhotoEvidenceContainer from '../containers/PhotoEvidenceContainer';
import Permissions from '../containers/Permissions';
import Profile from '../containers/Profile';
import QuizList from '../containers/Quiz/QuizList';
import QuizTemplate from '../containers/Quiz/QuizTemplate';
import RecurrenceContainer from '../containers/RecurrenceContainer';
import ReportSectionContainer from '../containers/ReportSectionContainer';
import ReportStages from '../containers/ReportStages';
import SafetyAuditCreate from '../containers/Audits/Create';
import SafetyAuditPerform from '../containers/Audits/Perform';
import SafetyAuditSummary from '../containers/Audits/Summary';
import SafetyAuditTemplates from '../containers/Audits/templateList';
import SafetyAuditTemplate from '../containers/Audits/Template';
import SafetyInstructionContainer from '../containers/SafetyInstruction';
import SafetyInstructionList from '../containers/SafetyInstructionList';
import SafetyWalkContainer from '../containers/SafetyWalkContainer';
import SafetyWalkStages from '../containers/SafetyWalkStages';
import SdsContainer from '../containers/SDS';
import SdsList from '../containers/SdsList';
import OSHAInfoDoc from '../containers/OshaInfoDoc';
import OSHAInfoList from '../containers/OshaInfoList';
import Settings from '../containers/Settings';
import TaskContainer from '../containers/TaskContainer';
import TaskStages from '../containers/TaskStages';
import TemplateList from '../containers/Training/TemplateList';
import TrainingAttachmentViewer from '../containers/Training/TrainingAttachmentViewer';
import TrainingList from '../containers/Training/TrainingList';
import TransitionContainer from '../containers/TransitionContainer';
import ViewEditEmployee from '../containers/ViewEditEmployee';
import CovidEmployeeList from '../containers/Covid-19/employeeList';
import EmployeeCovidStatus from '../containers/Covid-19/StatusPage';
import EmployeeExposure from '../containers/Covid-19/ExposurePage';
import WitnessStatementContainer from '../containers/WitnessStatementContainer';
import WitnessStatementList from '../containers/WitnessStatementList';
import EquipmentList from '../containers/EquipmentList';
import EquipmentContainer from '../containers/EquipmentContainer';
import TrainingReport from '../containers/ExceptionReporting/training';
import Leaderboards from '../containers/MyLeaderBoards';
import CreateMyLeaderboard from '../containers/MyLeaderBoards/Create';
import Leaderboard from '../containers/MyLeaderBoards/Leaderboard';
import LeaderboardDashboard from '../containers/MyLeaderBoards/Dashboard';
import ViewLeaderboard from '../containers/MyLeaderBoards/ViewLeaderboardTables';
import SafetyAuditReport from '../containers/ExceptionReporting/audit';
import QualificationList from '../components/QualificationList';
import QualificationContainer from '../containers/Qualification';
import SafetySuggestionsList from '../containers/SafetySuggestions/List';
import safetySuggestionPage from '../containers/SafetySuggestions/Page';

import { TYPE_NAMES } from '../constants/constants';
import Audits from '../containers/Audits';
import SafetyMeetingTemplates from '../containers/SafetyMeetings/templateList';
import SafetyMeetingList from '../containers/SafetyMeetings';
import SafetyMeetingContainer from '../containers/SafetyMeetings/Container';
import SafetyMeetingTemplate from '../containers/SafetyMeetings/Template';
import ExternalStatement from '../containers/ExternalStatement';
import QuizViewer from '../containers/Quiz/QuizPerform';
import TrainingCreate from '../containers/Training/TrainingCreate';
import PerformTraining from '../containers/Training/Perform';
import TrainingView from '../containers/Training/View';
import CourseTemplate from '../containers/Training/CourseTemplate';
import CourseTemplateList from '../containers/Training/CourseTemplateList';
import CourseList from '../containers/Training/CourseList';
import TrainingCourse from '../containers/Training/CourseView';
import TrainingCourseCreate from '../containers/Training/CourseCreate';
import AddCourseTraining from '../containers/Training/AddCourseTraining';
import AdministerTraining from '../containers/Training/Administer';
import CorrectiveActionContainer from '../containers/TaskContainer/correctiveAction';
import RootCauseContainer from '../containers/RootCauseContainer';
import WitnessComponentContainer from '../containers/WitnessComponentContainer';
import EditAnalyticsColumns from '../containers/MyAnalytics/CustomColumns';
import ReturnToWorkContainer from '../containers/ReturnToWorkContainer';

const routes = [
  {
    path: '/app/training/approve/:trainingId?',
    showLeftNav: false,
    leftNav: 'Training',
    component: ApproveTraining
  },
  {
    path: '/app/behaviorObservations',
    showLeftNav: true,
    leftNav: 'Behavior Observations',
    component: BehaviorObservations
  },
  {
    path: '/app/behaviorObservation/container/:boId?',
    showLeftNav: false,
    leftNav: 'Behavior Observation',
    component: BehaviorObservationContainer
  },
  {
    path: '/app/behaviorObservation/templates',
    showLeftNav: true,
    leftNav: 'Behavior Observation Templates',
    component: BehaviorObservationTemplates
  },
  {
    path: '/app/behaviorObservation/template/:templateId?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Behavior Observation Templates',
    component: BehaviorObservationTemplate
  },
  {
    path: '/app/claims/wc/:claimId?',
    showLeftNav: false,
    leftNav: 'Claims',
    component: WcClaim
  },
  {
    path: '/app/claims/damage/:claimId?',
    showLeftNav: false,
    leftNav: 'Claims',
    component: DamageClaim
  },
  {
    path: '/app/claims/auto/:claimId?',
    showLeftNav: false,
    leftNav: 'Claims',
    component: AutoClaim
  },
  {
    path: '/app/claims',
    showLeftNav: true,
    leftNav: 'Claims',
    component: Claims
  },
  {
    path: '/app/myAnalytics/card/:cardId?',
    showLeftNav: false,
    leftNav: 'My Analytics',
    component: MyAnalyticsChart
  },
  {
    path: '/app/myAnalytics/dashboard/:dashboardId?',
    showLeftNav: false,
    leftNav: 'My Analytics',
    component: MyAnalyticsDashboard
  },
  {
    path: '/app/myAnalytics/detail/:chartId?',
    showLeftNav: false,
    leftNav: 'My Analytics',
    component: MyAnalyticsDetail
  },
  {
    path: '/app/myAnalytics/columns',
    showLeftNav: false,
    leftNav: 'My Analytics',
    component: EditAnalyticsColumns
  },
  {
    path: '/app/myAnalytics',
    showLeftNav: true,
    leftNav: 'My Analytics',
    component: MyAnalytics
  },
  {
    path:
      '/app/CustomDocumentContainer/:templateId?/:documentId?/:duplicating?',
    showLeftNav: false,
    leftNav: 'Custom Documents',
    component: CustomDocumentContainer
  },
  {
    path: '/app/CustomDocumentList',
    showLeftNav: true,
    leftNav: 'Custom Documents',
    component: CustomDocumentList
  },
  {
    path: '/app/CustomDocumentTemplateContainer/:templateId?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Document Templates',
    component: CustomDocumentTemplateContainer
  },
  {
    path: '/app/CustomDocumentTemplateList',
    showLeftNav: true,
    leftNav: 'Custom Document Templates',
    component: CustomDocumentTemplateList
  },
  {
    path: '/app/customReportQuestionsList',
    showLeftNav: true,
    leftNav: 'Custom Incident Questions',
    component: CustomReportQuestionsList
  },
  {
    path: '/app/customReportQuestionContainer',
    showLeftNav: false,
    leftNav: 'Custom Report Questions',
    component: CustomReportQuestionContainer
  },
  {
    path: '/app/training/template/:id?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Custom Trainings',
    component: CustomTrainingTemplates
  },
  {
    path: '/app/trainingCourses/',
    showLeftNav: true,
    leftNav: 'Training Courses',
    component: CourseList
  },
  {
    path: '/app/trainingCourses/create/:id?',
    showLeftNav: false,
    leftNav: 'Training Courses',
    component: TrainingCourseCreate
  },
  {
    path: '/app/trainingCourses/view/:id?',
    showLeftNav: false,
    leftNav: 'Training Courses',
    component: TrainingCourse
  },
  {
    path: '/app/courseTemplates',
    showLeftNav: true,
    leftNav: 'Training Course Templates',
    component: CourseTemplateList
  },
  {
    path: '/app/courseTemplates/addTraining',
    showLeftNav: false,
    leftNav: 'Training Courses Templates',
    component: AddCourseTraining
  },
  {
    path: '/app/trainingCourses/addTraining',
    showLeftNav: false,
    leftNav: 'Training Courses Templates',
    component: AddCourseTraining
  },
  {
    path: '/app/courseTemplates/view/:id?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Training Course Templates',
    component: CourseTemplate
  },
  {
    path: '/app/dashboard',
    showLeftNav: true,
    leftNav: 'Home',
    component: Dashboard
  },
  {
    path: '/app/documents/sds',
    showLeftNav: true,
    leftNav: 'SDS',
    component: SdsList
  },
  {
    path: '/app/documents/oshaInfoList',
    showLeftNav: true,
    leftNav: 'OSHA Information',
    component: OSHAInfoList
  },
  {
    path: '/app/documents/safetyInstructions',
    showLeftNav: true,
    leftNav: 'Safety Procedures',
    component: SafetyInstructionList
  },
  {
    path: '/app/documents/:documentType/:qualificationType?',
    showLeftNav: true,
    leftNav: 'Qualifications',
    component: QualificationList
  },
  {
    path: '/app/safetyWalkContainer/hazardCard/:hazardIndex?',
    showLeftNav: false,
    leftNav: 'Safety Walks',
    component: HazardCard
  },
  {
    path: '/app/safetyWalkContainer/bravoCard/:bravoIndex?',
    showLeftNav: false,
    leftNav: 'Safety Walks',
    component: BravoCard
  },
  {
    path: '/app/incidentContainer/:stage?/:incidentId?',
    showLeftNav: false,
    leftNav: 'Reports',
    component: IncidentContainer
  },
  {
    path: '/app/incidents',
    showLeftNav: true,
    leftNav: 'All Incidents',
    component: IncidentStages
  },
  {
    path: '/app/initalReportContainer',
    showLeftNav: false,
    leftNav: 'Reports',
    component: InitalReportContainer
  },
  {
    path: '/app/myDocumentContainer/:id?/:isUserId?',
    showLeftNav: false,
    leftNav: 'My Documents',
    component: MyDocumentContainer
  },
  {
    path: '/app/myDocumentsStages',
    showLeftNav: true,
    leftNav: 'My Documents',
    component: MyDocumentsStages
  },
  {
    path: '/app/oshaLog/:year?',
    showLeftNav: true,
    leftNav: 'OSHA Forms',
    component: OshaLog
  },
  {
    path: '/app/photoEvidenceContainer/:isPhotoEvidence?',
    showLeftNav: false,
    leftNav: 'Reports',
    component: PhotoEvidenceContainer
  },
  {
    path: '/app/training/perform/:trainingId?',
    showLeftNav: false,
    leftNav: 'Training',
    component: PerformTraining
  },
  {
    path: '/app/training/administer/:trainingId?/:groupId?',
    showLeftNav: false,
    leftNav: 'Training',
    component: AdministerTraining
  },
  {
    path: '/app/permissions/:type?/:typeId?',
    showLeftNav: false,
    component: Permissions
  },
  {
    path: '/app/profile',
    showLeftNav: true,
    leftNav: 'Dashboard',
    component: Profile
  },
  {
    path: '/app/quiz/quizList',
    showLeftNav: true,
    leftNav: 'Training Quiz Templates',
    component: QuizList
  },
  {
    path: '/app/quiz/quizTemplate/:quizTemplateId?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Custom Quizzes',
    component: QuizTemplate
  },
  {
    path: '/app/recurrenceContainer/:recurrenceId?',
    showLeftNav: false,
    leftNav: 'My Documents',
    component: RecurrenceContainer
  },
  {
    path: '/app/reportSectionContainer/:stage?/:taskId?',
    showLeftNav: false,
    leftNav: 'Reports',
    component: ReportSectionContainer
  },
  {
    path: '/app/reportStages/:templateId',
    showLeftNav: true,
    leftNav: ({ match }) => TYPE_NAMES[match.params.templateId],
    component: ReportStages
  },
  {
    path: '/app/audits/safety',
    showLeftNav: true,
    leftNav: 'Safety Audits',
    component: Audits
  },
  {
    path: '/app/audits/quality',
    showLeftNav: true,
    leftNav: 'Quality Audits',
    component: Audits
  },
  {
    path: '/app/audits/loto',
    showLeftNav: true,
    leftNav: 'Lockout/Tagout',
    component: Audits
  },
  {
    path: '/app/audits/safety/create/:safetyAuditId?/:type?',
    showLeftNav: false,
    leftNav: 'Safety Audits',
    component: SafetyAuditCreate
  },
  {
    path: '/app/audits/safety/perform/:safetyAuditId?',
    showLeftNav: false,
    leftNav: 'Safety Audits',
    component: SafetyAuditPerform
  },
  {
    path: '/app/audits/safety/summary/:safetyAuditId?',
    showLeftNav: false,
    leftNav: 'Safety Audits',
    component: SafetyAuditSummary
  },
  {
    path: '/app/audits/quality/create/:safetyAuditId?/:type?',
    showLeftNav: false,
    leftNav: 'Quality Audits',
    component: SafetyAuditCreate
  },
  {
    path: '/app/audits/quality/perform/:safetyAuditId?',
    showLeftNav: false,
    leftNav: 'Quality Audits',
    component: SafetyAuditPerform
  },
  {
    path: '/app/audits/quality/summary/:safetyAuditId?',
    showLeftNav: false,
    leftNav: 'Quality Audits',
    component: SafetyAuditSummary
  },
  {
    path: '/app/audits/loto/create/:safetyAuditId?/:type?',
    showLeftNav: false,
    leftNav: 'Lockout/Tagout',
    component: SafetyAuditCreate
  },
  {
    path: '/app/audits/loto/perform/:safetyAuditId?',
    showLeftNav: false,
    leftNav: 'Lockout/Tagout',
    component: SafetyAuditPerform
  },
  {
    path: '/app/audits/loto/summary/:safetyAuditId?',
    showLeftNav: false,
    leftNav: 'Lockout/Tagout',
    component: SafetyAuditSummary
  },
  {
    path: '/app/auditTemplates/safety',
    showLeftNav: true,
    leftNav: 'Safety Audit Templates',
    component: SafetyAuditTemplates
  },
  {
    path: '/app/auditTemplates/quality',
    showLeftNav: true,
    leftNav: 'Quality Audit Templates',
    component: SafetyAuditTemplates
  },
  {
    path: '/app/auditTemplates/loto',
    showLeftNav: true,
    leftNav: 'Lockout/Tagout Templates',
    component: SafetyAuditTemplates
  },
  {
    path:
      '/app/auditTemplates/quality/template/:safetyAuditTemplateId?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Custom Quality Audits',
    component: SafetyAuditTemplate
  },
  {
    path:
      '/app/auditTemplates/safety/template/:safetyAuditTemplateId?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Custom Safety Audits',
    component: SafetyAuditTemplate
  },
  {
    path:
      '/app/auditTemplates/loto/template/:safetyAuditTemplateId?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Lockout/Tagout',
    component: SafetyAuditTemplate
  },
  {
    path: '/app/safetyWalkContainer/:safetyWalkId?',
    showLeftNav: false,
    leftNav: 'Safety Walks',
    component: SafetyWalkContainer
  },
  {
    path: '/app/safetyWalkStages/',
    showLeftNav: true,
    leftNav: 'Safety Walks',
    component: SafetyWalkStages
  },
  {
    path: '/app/settings/:settingType/:openEditUser?',
    showLeftNav: true,
    leftNav: ({ match }) => {
      switch (match.params.settingType) {
        case 'CompanyDetails':
          return 'Company Division';
        case 'Locations':
          return 'Group / Establishment';
        case 'Projects':
          return 'Areas';
        default:
          return match.params.settingType;
      }
    },
    component: Settings
  },
  {
    path: '/app/taskContainer/:taskId?',
    showLeftNav: false,
    leftNav: 'Tasks',
    component: TaskContainer
  },
  {
    path: '/app/correctiveActionContainer/:caId?',
    showLeftNav: false,
    leftNav: 'Corrective Actions',
    component: CorrectiveActionContainer
  },
  {
    path: '/app/tasks/correctiveAction',
    showLeftNav: true,
    leftNav: 'Corrective Actions',
    component: TaskStages
  },
  {
    path: '/app/tasks/task',
    showLeftNav: true,
    leftNav: 'Tasks',
    component: TaskStages
  },
  {
    path: '/app/training/templates',
    showLeftNav: true,
    leftNav: 'Training Templates',
    component: TemplateList
  },
  {
    path: '/app/training/trainingAttachmentViewer',
    showLeftNav: false,
    leftNav: 'Training',
    component: TrainingAttachmentViewer
  },
  {
    path: '/app/training/create/:trainingId?',
    showLeftNav: false,
    leftNav: 'Training',
    component: TrainingCreate
  },
  {
    path: '/app/training/view/:id?/:trainingType?',
    showLeftNav: false,
    leftNav: 'Training',
    component: TrainingView
  },
  {
    path: '/app/training/list',
    showLeftNav: true,
    leftNav: 'Training',
    component: TrainingList
  },
  {
    path: '/app/training/quiz',
    showLeftNav: false,
    leftNav: 'Training',
    component: QuizViewer
  },
  {
    path: '/app/safetyMeeting/list',
    showLeftNav: true,
    leftNav: 'Safety Meetings',
    component: SafetyMeetingList
  },
  {
    path: '/app/safetyMeeting/templates',
    showLeftNav: true,
    leftNav: 'Safety Meeting Templates',
    component: SafetyMeetingTemplates
  },
  {
    path: '/app/safetyMeeting/template/:id?/:duplicate?',
    showLeftNav: false,
    leftNav: 'Safety Meeting Templates',
    component: SafetyMeetingTemplate
  },
  {
    path: '/app/safetyMeeting/container/:id?',
    showLeftNav: false,
    leftNav: 'Safety Meetings',
    component: SafetyMeetingContainer
  },
  {
    path: '/app/transitionContainer/:transitionId?',
    showLeftNav: false,
    leftNav: 'My Documents',
    component: TransitionContainer
  },
  {
    path: '/app/safetyInstructionContainer/:docId?',
    showLeftNav: false,
    leftNav: 'Safety Procedure',
    component: SafetyInstructionContainer
  },
  {
    path: '/app/sdsContainer/:docId?',
    showLeftNav: false,
    leftNav: 'SDS',
    component: SdsContainer
  },
  {
    path: '/app/oshaInfo/:docId?',
    showLeftNav: false,
    leftNav: 'OSHAInfo',
    component: OSHAInfoDoc
  },
  {
    path: '/app/qualificationContainer/:docId?',
    showLeftNav: false,
    leftNav: 'Qualifcations',
    component: QualificationContainer
  },
  {
    path: '/app/viewEditPersonnel',
    showLeftNav: false,
    leftNav: 'Employees',
    component: ViewEditEmployee
  },
  {
    path: '/app/covidTracking',
    showLeftNav: true,
    leftNav: 'Covid-19 Tracking',
    component: CovidEmployeeList
  },
  {
    path: '/app/covidTracking/employeeStatus/:id?',
    showLeftNav: false,
    leftNav: 'EmployeeCovidStatus',
    component: EmployeeCovidStatus
  },
  {
    path: '/app/covidTracking/exposure/:id?',
    showLeftNav: false,
    leftNav: 'EmployeeExposure',
    component: EmployeeExposure
  },
  {
    path: '/app/witnessStatements/:statementId?',
    showLeftNav: false,
    leftNav: 'WitnessStatement',
    component: WitnessStatementContainer
  },
  {
    path: '/app/witnessStatementList',
    showLeftNav: true,
    leftNav: 'Witness Statements',
    component: WitnessStatementList
  },
  {
    path: '/app/externalWitness/:statementId',
    showLeftNav: false,
    leftNav: '',
    component: ExternalStatement
  },
  {
    path: '/app/equipmentList',
    showLeftNav: true,
    leftNav: 'Equipment',
    component: EquipmentList
  },
  {
    path: '/app/equipmentContainer/:equipmentId?',
    showLeftNav: false,
    leftNav: 'Equipment',
    component: EquipmentContainer
  },
  {
    path: '/app/trainingException',
    showLeftNav: true,
    leftNav: 'Training Report',
    component: TrainingReport
  },
  {
    path: '/app/leaderboards',
    showLeftNav: true,
    leftNav: 'Leaderboards',
    component: Leaderboards
  },
  {
    path: '/app/leaderboards/create/:dashboardId?',
    showLeftNav: false,
    leftNav: 'Leaderboard',
    component: CreateMyLeaderboard
  },
  {
    path: '/app/leaderboards/edit/:id?',
    showLeftNav: false,
    leftNav: 'Leaderboard',
    component: Leaderboard
  },
  {
    path: '/app/leaderboards/dashboard/edit/:id?',
    showLeftNav: false,
    leftNav: 'Leaderboard',
    component: LeaderboardDashboard
  },
  {
    path: '/app/leaderboards/view/:id',
    showLeftNav: false,
    leftNav: 'Leaderboard',
    component: ViewLeaderboard
  },
  {
    path: '/app/auditException/safety',
    showLeftNav: true,
    leftNav: 'Safety Exception',
    component: SafetyAuditReport
  },
  {
    path: '/app/auditException/quality',
    showLeftNav: true,
    leftNav: 'Quality Exception',
    component: SafetyAuditReport
  },
  {
    path: '/app/fireexposure/:id?',
    showLeftNav: false,
    leftNav: 'My Documents',
    component: FireExposureForm
  },
  {
    path: '/app/announcementList',
    showLeftNav: true,
    leftNav: 'Announcements',
    component: AnnouncementList
  },
  {
    path: '/app/announcement/container/:id?',
    showLeftNav: false,
    leftNav: 'Announcements',
    component: AnnouncementContainer
  },
  {
    path: '/app/safetySuggestions/list',
    showLeftNav: true,
    leftNav: 'Safety Suggestions',
    component: SafetySuggestionsList
  },
  {
    path: '/app/safetySuggestions/:safetySuggestionId?',
    showLeftNav: false,
    leftNav: 'Safety Suggestions',
    component: safetySuggestionPage
  },
  {
    path: '/app/rootCauseContainer/:stage?/:taskId?',
    showLeftNav: false,
    leftNav: 'RootCause',
    component: RootCauseContainer
  },
  {
    path: '/app/WitnessComponentContainer/:stage?',
    showLeftNav: false,
    leftNav: 'WitnessComponent',
    component: WitnessComponentContainer
  },
  {
    path: '/app/returnToWorkContainer/:stage?/:taskId?',
    showLeftNav: false,
    leftNav: 'ReturnToWork',
    component: ReturnToWorkContainer
  }
];

export default routes;

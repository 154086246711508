import React from 'react';

import { email, normalizePhone } from '../../utils/formValidation';
import { PRIORITY_DROPDOWN } from '../../constants/constants';
import Button from '../../components/inputs/Button';
import { Dropdown, InputRow, Textbox } from '../../components/inputs';

import '../../components/inputs/input.css';
import '../form.css';
import { useEffect } from 'react';
import { DatePicker } from '../../components/inputs/DateTimePicker';
import FileDownloader from '../../components/FileDownloader';
import FileUploader from '../../components/FileUploader';
import HierarchySelector from '../../components/HierarchySelector';

import { useDispatch } from 'react-redux';
import {
  addUploadedAttachmentsRequest,
  deleteAttachmentRequest
} from '../../actions/attachments';

export default function EquipmentDocumentForm({
  activeCompany,
  onDelete,
  documentFolders,
  activeSI,
  siForm,
  setSIForm,
  addedAttachments,
  updating,
  setUpdating,
  groupDropdown,
  projectDropdown,
  disabled,
  missingRequired
}) {
  const dispatch = useDispatch();

  const activeAttachments = activeSI?.attachments;

  useEffect(() => {
    if (activeSI) {
      setUpdating(false);
    } else {
      setUpdating(true);
    }
  }, [activeSI, setUpdating]);

  const folderDropdown = documentFolders.map(folder => {
    return {
      value: folder._id,
      label: folder.name
    };
  });

  return (
    <>
      {activeSI && (updating || disabled) ? (
        <InputRow right>
          <Button
            text="Delete"
            color="red"
            onClick={() => onDelete(activeSI._id)}
            testID="deleteProcedure"
          />
        </InputRow>
      ) : activeSI && !disabled ? (
        <InputRow right>
          <Button
            text="Edit"
            color="blue"
            onClick={() => {
              setUpdating(true);
            }}
          />
        </InputRow>
      ) : (
        <></>
      )}
      <div>
        <HierarchySelector
          onGroupChange={v => setSIForm({ ...siForm, groupIds: v })}
          groupId={siForm?.groupIds ?? []}
          onProjectChange={v => setSIForm({ ...siForm, projectIds: v })}
          projectId={siForm?.projectIds}
          multi
          filteredGroupDropdown={groupDropdown}
          filteredProjectDropdown={projectDropdown?.filter(p =>
            siForm?.groupIds?.includes(p.groupId)
          )}
          groupRequired={false}
          groupDisabled={!updating}
          projectDisabled={!updating}
        />
        <Textbox
          name="label"
          currentValue={siForm?.label}
          onChange={r => setSIForm({ ...siForm, label: r })}
          fieldLabel="Title:"
          isRequired={true}
          disabled={!updating}
          error
          touched={!siForm?.label && missingRequired}
        />
        <Textbox
          name="description"
          fieldLabel="Description:"
          currentValue={siForm?.documentData?.description}
          onChange={r =>
            setSIForm({
              ...siForm,
              documentData: { ...siForm?.documentData, description: r }
            })
          }
          disabled={!updating}
        />
        <Dropdown
          name="documentFolderId"
          options={folderDropdown}
          fieldLabel="Add to Folder:"
          currentValue={siForm?.documentFolderId}
          onChange={r =>
            setSIForm({
              ...siForm,
              documentFolderId: r
            })
          }
          disabled={!updating}
        />
        <Dropdown
          name="currentPriority"
          fieldLabel="Priority Level:"
          options={PRIORITY_DROPDOWN}
          currentValue={siForm?.documentData?.currentPriority}
          onChange={r =>
            setSIForm({
              ...siForm,
              documentData: { ...siForm?.documentData, currentPriority: r }
            })
          }
          disabled={!updating}
        />
        <DatePicker
          name="expires"
          fieldLabel="Expiration Date:"
          currentValue={siForm?.expires}
          onChange={r => setSIForm({ ...siForm, expires: r })}
          disabled={!updating}
        />
        <Textbox
          name="nextSteps"
          fieldLabel="Next Steps:"
          currentValue={siForm?.documentData?.nextSteps}
          onChange={r =>
            setSIForm({
              ...siForm,
              documentData: { ...siForm?.documentData, nextSteps: r }
            })
          }
          disabled={!updating}
        />
        <Textbox
          name="notes"
          fieldLabel="Notes:"
          currentValue={siForm?.documentData?.notes}
          onChange={r =>
            setSIForm({
              ...siForm,
              documentData: { ...siForm?.documentData, notes: r }
            })
          }
          disabled={!updating}
        />
        <Textbox
          name="questionsPhone"
          fieldLabel="Point of Contact Phone:"
          currentValue={siForm?.documentData?.questionsPhone}
          onChange={r =>
            setSIForm({
              ...siForm,
              documentData: {
                ...siForm?.documentData,
                questionsPhone: normalizePhone(r)
              }
            })
          }
          disabled={!updating}
        />
        <Textbox
          name="questionsEmail"
          fieldLabel="Point of Contact Email:"
          error={
            email(siForm?.documentData?.questionsEmail)
              ? 'Not a valid email'
              : null
          }
          touched={siForm?.documentData?.questionsEmail}
          currentValue={siForm?.documentData?.questionsEmail}
          onChange={r =>
            setSIForm({
              ...siForm,
              documentData: { ...siForm?.documentData, questionsEmail: r }
            })
          }
          disabled={!updating}
        />
        <Textbox
          name="comments"
          fieldLabel="Comments:"
          currentValue={siForm?.documentData?.comments}
          onChange={r =>
            setSIForm({
              ...siForm,
              documentData: { ...siForm?.documentData, comments: r }
            })
          }
          disabled={!updating}
        />
        <div>
          <h4>Attachments</h4>
          {addedAttachments &&
            addedAttachments.map((attachment, attachmentIndex) => (
              <FileDownloader
                attachment={attachment}
                key={attachmentIndex}
                attachmentIndex={attachmentIndex}
                deleteAttachment={values => {
                  addedAttachments.splice(values.attachmentIndex, 1);
                  dispatch(deleteAttachmentRequest(values));
                }}
                disabled={!updating}
              />
            ))}
          {activeAttachments &&
            activeAttachments.map((attachment, attachmentIndex) => (
              <FileDownloader
                attachment={attachment}
                key={attachmentIndex}
                attachmentIndex={attachmentIndex}
                deleteAttachment={values => {
                  activeAttachments.splice(values.attachmentIndex, 1);
                  dispatch(deleteAttachmentRequest(values));
                }}
                disabled={!updating}
              />
            ))}
          {updating ? (
            <FileUploader
              activeCompanyId={activeCompany}
              addAttachment={attachment =>
                dispatch(addUploadedAttachmentsRequest(attachment))
              }
              ownerId={activeSI?._id ?? 'pending'}
              ownerType="documents"
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

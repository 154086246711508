import React, { Component } from 'react';
import moment from 'moment';
import Button from '../../inputs/Button';
import UserBadge from '../../UserBadge';
import { isImage } from '../../../utils/attachmentSelector';

import './index.css';

export default class AttachmentCard extends Component {
  state = {
    showForSure: false
  };

  handleRemoveClick = () => {
    const showForSure = this.state.showForSure;

    if (showForSure) {
      this.props.removeAttachment();
      this.setState({ showForSure: false });
    } else {
      this.setState({ showForSure: true });
    }
  };

  render() {
    const { attachment } = this.props;
    const { showForSure, canRemove } = this.state;

    return (
      <div className="reportAttachment">
        <div
          className={
            isImage(attachment)
              ? 'reportAttachmentCard'
              : 'reportAttachmentCard noImage'
          }
        >
          <div className="reportAttachmentCard-createdDate">
            <div>{moment(attachment.createdAt).format('hh:mm a')}</div>
            <div>{moment(attachment.createdAt).format('MM/DD/YYYY')}</div>
          </div>
          <div
            className={
              isImage(attachment)
                ? 'reportAttachmentCard-content'
                : 'reportAttachmentCard-content noImage'
            }
          >
            {attachment.isRemoved && (
              <div
                className={
                  attachment.isRemovedByOwner
                    ? 'reportAttachment-removedByOwner'
                    : 'reportAttachment-removed'
                }
              >
                {attachment.isRemovedByOwner ? 'Removed By Owner' : 'Removed'}
              </div>
            )}
            {isImage(attachment) ? (
              <div className="reportAttachmentCard-image">
                <img
                  src={attachment.source_url}
                  alt={attachment.original_filename}
                />
              </div>
            ) : (
              <div className="reportAttachmentCard-otherFile">
                <img
                  src={require('../../../assets/images/otherFile.png')}
                  alt={attachment.original_filename}
                />
                <div className="reportAttachmentCard-otherFile--name">
                  {attachment.original_filename}
                </div>
              </div>
            )}
            <UserBadge
              userId={attachment.createdByUserId}
              showImage
              showName
              className="reportAttachment-badge"
            />
          </div>
        </div>
        <div className="reportAttachmentCard-buttons">
          <Button
            onClick={() => window.open(attachment.source_url, '_blank')}
            type="button"
            text="Download"
            color="white"
          />
          {!attachment.isRemoved && canRemove && (
            <Button
              onClick={() => this.handleRemoveClick()}
              type="button"
              text={showForSure ? 'For Sure?' : 'Remove'}
              color="red"
            />
          )}
        </div>
      </div>
    );
  }
}

import React, { Component, useEffect, useState } from 'react';
import Intercom from 'react-intercom';
import { Route, Switch, useParams, useLocation } from 'react-router-dom';
import { UserAgentApplication } from 'msal';
import 'react-dates/initialize';

import routes from '../../constants/routes';
import MobileHeader from '../MobileHeader';
import { leftNavigation } from '../../constants/leftNavigation';
import history from '../../history';
import LogRocket from 'logrocket';
import LeftNav from '../LeftNav';

import './globals.scss';
import styles from './app.module.scss';

// this is used to allow self signed certs from the development server
// and allows use to use https (SSL/TLS) even when running locally
if (process.env.REACT_APP_STAGE === 'development') {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
}

const RouteWrapper = ({
  setShowLeftNav,
  setActiveSection,
  route,
  ...props
}) => {
  const Component = route.component;
  const params = useParams();
  const location = useLocation();
  const [match, setMatch] = useState({ params });

  useEffect(() => {
    /*
      This horrifying mess does nothing more than keep a reference
      to the params returned by useParams() and only send a new object
      out when one of them actually changes.

      Once we've changed all our containers that use react-router's params
      to be functional components, we no longer need to pass params or location
      in to them here and this mess can go.
    */
    const keys = Object.keys(params);
    if (keys.length) {
      let changed = false;
      keys.forEach(key => {
        if (params[key] !== match.params[key]) changed = true;
      });
      if (changed) setMatch({ params });
    } else if (Object.keys(match.params).length) {
      setMatch({ params: {} });
    }
  }, [params, match.params]);

  useEffect(() => {
    setShowLeftNav(route.showLeftNav);
    let tmp;
    let leftNav = route.leftNav;
    if (typeof leftNav === 'function') leftNav = leftNav({ match });

    for (let i = 0; i < leftNavigation.sections.length; i++) {
      let s = leftNavigation.sections[i];
      for (let j = 0; j < s.section.length; j++) {
        const text = s.section[j].navItem || s.section[j].text;
        if (text === leftNav) {
          tmp = [i, j];
        }
      }
    }
    if (tmp) setActiveSection(tmp);
  });

  return <Component {...props} match={match} location={location} />;
};

export class App extends Component {
  state = { showLeftNav: false, activeSection: [0, 0] };

  constructor(props) {
    super(props);
    const token = sessionStorage.getItem('token');
    if (!token) {
      history.push('/login');
    }
  }

  componentDidMount() {
    // This is weird but Microsoft login redirects back to the site and expects it to create the object before it closes the popup.
    // See https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/174
    if (window.location.hash.includes('id_token')) {
      new UserAgentApplication(
        '4ecf3d26-e844-4855-9158-b8f6c0121b50',
        null,
        null
      );
    }
  }

  setShowLeftNav = showLeftNav =>
    this.setState(state =>
      state.showLeftNav !== showLeftNav ? { showLeftNav } : undefined
    );
  setActiveSection = activeSection =>
    this.setState(state =>
      state.activeSection[0] !== activeSection[0] ||
      state.activeSection[1] !== activeSection[1]
        ? { activeSection }
        : undefined
    );

  render() {
    let user;
    const isProd = process.env.REACT_APP_STAGE === 'production';
    const token = sessionStorage.getItem('token');
    if (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const decoded = JSON.parse(window.atob(base64));
      const sessionUser = JSON.parse(sessionStorage.getItem('user'));
      user = {
        user_id: decoded?.user_id,
        email: sessionUser?.email,
        phone: sessionUser?.phoneNumber,
        name: sessionUser?.firstName + ' ' + sessionUser?.lastName,
        user_type: parseInt(sessionUser?.accessLevel, 10),
        'Company name': localStorage.getItem('companies'),
        isAccountOwner: sessionUser?.isAccountOwner,
        username: decoded?.username
      };
    }

    try {
      // link intercom and logrocket sessions and users
      if (isProd && user?.username) {
        Intercom('update', {
          logrocketURL: `https://app.logrocket.com/ireportsource/ireport-react-app/sessions?u=${user.username}`
        });
        LogRocket.getSessionURL(function(sessionURL) {
          Intercom('trackEvent', 'LogRocket', { sessionURL });
        });
      }
    } catch (e) {
      // Ignore, not useful. Intercom handles with retry, but need to stop exception from bubbling up in our app code.
      // We don't need to know or do anything and do not need it cluttering up our logs.
    }

    return (
      <div className={styles.App}>
        {isProd && user ? <Intercom appID="gpy0bzzd" {...user} /> : <></>}
        <MobileHeader activeSection={this.state.activeSection} />
        <LeftNav
          showLeftNav={this.state.showLeftNav}
          activeSection={this.state.activeSection}
        />
        <div className={styles.mainPage}>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact>
                <RouteWrapper
                  setShowLeftNav={this.setShowLeftNav}
                  setActiveSection={this.setActiveSection}
                  route={route}
                />
              </Route>
            ))}
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;

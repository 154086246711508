import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import {
  listAllReports,
  reportBreakdown,
  fetchUserListFilter,
  updateUserListFilter,
  archiveIncidents
} from '../../api/v4';
import {
  setActiveIncidentRequest,
  fetchIncidentBasicsRequest
} from '../../actions/incidents';
import {
  REACTIVE_REPORT_TYPES,
  PROACTIVE_REPORT_TYPES,
  CLAIM_STAGES
} from '../../constants/constants';
import { getIsPerrp } from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import { useActiveHeirarchy } from '../../utils/useActiveHeirarchy';
import { usePrevious } from '../../utils/hooks';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import IncidentsList from '../../components/IncidentsList';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import TypeSelection from '../../components/TypeSelection';
import DashboardButtons from '../../components/Modal/dashboardButtons';

const reportTypes = [
  ...REACTIVE_REPORT_TYPES,
  ...PROACTIVE_REPORT_TYPES.filter(r => r.value !== 8 && r.value !== 10)
];

export default function IncidentStages() {
  const dispatch = useDispatch();
  const loadIncident = payload => dispatch(setActiveIncidentRequest(payload));
  const routeLocation = useLocation();
  const { company, location, project } = useActiveHeirarchy();
  const user = useSelector(getLoggedInUser);
  const isPerrp = useSelector(getIsPerrp);
  const [selectedTab, setSelectedTab] = useState(
    routeLocation?.state?.selectedTab ?? 'List'
  );
  const [data, setData] = useState(null);
  const [breakdownData, setBreakdownData] = useState(null);
  const [openReportSelector, setOpenReportSelector] = useState(null);
  const [reportTemplateIds, setReportTemplateIds] = useState([]);
  const [tabs, setTabs] = useState(['List', 'Breakdown']);
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const prevPeriod = usePrevious(currentPeriod);
  const prevType = usePrevious(selectedTab);

  useEffect(() => {
    fetchUserListFilter(user._id, 'incidents').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });

    if (user.accessLevel > 400) setTabs(['List', 'Breakdown', 'Archived']);
  }, [user]);

  useEffect(() => {
    if (
      currentPeriod &&
      (!isEqual(prevPeriod, currentPeriod) || !isEqual(prevType, selectedTab))
    ) {
      if (selectedTab !== 'Breakdown')
        listAllReports({ ...currentPeriod, selectedType: selectedTab }).then(
          setData
        );
      else reportBreakdown(currentPeriod).then(setBreakdownData);
    }
  }, [currentPeriod, selectedTab, prevPeriod, prevType]);

  const modalProps = {
    reports: {
      title: 'Create New Incident Report',
      text: 'Select one or more if applicable',
      buttons: REACTIVE_REPORT_TYPES?.filter(
        rt => company?.showGeneral || rt.value !== 19
      )?.map(rt => ({
        ...rt,
        disabled:
          rt.value === 19
            ? reportTemplateIds?.length && !reportTemplateIds.includes(19)
            : reportTemplateIds?.includes(19)
      })),
      onClick: v => {
        reportTemplateIds?.includes(v)
          ? setReportTemplateIds(reportTemplateIds.filter(x => x !== v))
          : setReportTemplateIds(
              !reportTemplateIds ? [].concat(v) : reportTemplateIds.concat(v)
            );
      },
      modalSubmit: () => {
        dispatch(fetchIncidentBasicsRequest({ reportTemplateIds }));
      }
    }
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveIncidents({
      incidentIds: ids,
      isArchived: selectedTab === 'List'
    }).then(() =>
      listAllReports({ ...currentPeriod, selectedType: selectedTab }).then(
        setData
      )
    );
  };

  const breakdownColumns = [
    {
      key: 'reportStage',
      label: 'Status',
      accessor: r => (r.reportStage?.label === 'Closed' ? 'Completed' : 'Open'),
      enum: ['Open', 'Completed']
    },
    {
      key: 'claimStatus',
      label: 'Report Status',
      accessor: r => r.reportStage?.label,
      enum: CLAIM_STAGES
    },
    {
      key: 'reportTemplateIds',
      label: 'Report Types',
      accessor: r => r.reportTemplateIds.map(v => parseInt(v, 10)),
      datatype: 'svgs',
      disableSortBy: true,
      enum: company?.showAircraft
        ? reportTypes.filter(r => !r.label !== 'Spill')
        : reportTypes.filter(r => !r.isAircraft)
    },
    {
      key: 'incidentNumber',
      label: 'Incident Title'
    },
    {
      key: 'dateOccurred',
      label: 'Date Occurred',
      datatype: 'date'
    },
    {
      key: 'Photo Evidence',
      label: 'Photo Evidence'
    },
    {
      key: 'Diagrams',
      label: 'Diagrams'
    },
    {
      key: 'Witness Statements',
      label: 'Witness Statements'
    },
    {
      key: 'Supervisor Summary',
      label: 'Supervisor Summary'
    },
    {
      key: 'Root Cause',
      label: 'Root Cause'
    },
    {
      key: 'Corrective Actions',
      label: 'Corrective Actions'
    },
    {
      key: 'OSHA Compliance',
      label: isPerrp ? 'PERRP Compliance' : 'OSHA Compliance'
    },
    {
      key: 'Follow-Up',
      label: 'Follow-Up'
    },
    {
      key: 'Return to Work',
      label: 'Return to Work'
    },
    {
      key: 'Closure Checklist',
      label: 'Closure Checklist'
    }
  ];

  if (!project) {
    breakdownColumns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }

  if (!location) {
    breakdownColumns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  const breakdownSaveKey =
    breakdownData?.length > 0
      ? !location
        ? `BreakdownGroups`
        : location && !project
        ? `BreakdownAreas`
        : 'Breakdown'
      : null;

  return (
    <HeaderAndFooter
      Header={
        <Header
          title="All Incidents"
          rightButtons={{
            text: 'Create Report(s)',
            testID: 'Create Report(s)',
            color: 'blue',
            onClick: () => setOpenReportSelector('reports'),
            visible: !location?.isHidden && !project?.isHidden
          }}
        />
      }
    >
      {user.accessLevel > 400 ? (
        <TypeSelection
          selected={selectedTab}
          selectedArray={tabs}
          onClick={v => {
            setData([]);
            setSelectedTab(v);
          }}
        />
      ) : null}
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={value => {
          setData([]);
          setCurrentPeriod(value);
        }}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'incidents',
            period: v
          }).then(r => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <ScrollToTopOnMount />
      <DashboardButtons
        modalOpen={openReportSelector}
        modalProps={modalProps}
        handleCloseModal={() => {
          setOpenReportSelector(null);
          setReportTemplateIds([]);
        }}
        modalSelection={reportTemplateIds}
        setModalSelection={setReportTemplateIds}
        company={company}
      />
      {selectedTab !== 'Breakdown' ? (
        <IncidentsList
          content={data}
          saveKey={
            selectedTab === 'Archived' ? 'archivedAllIncidents' : 'incidents'
          }
          user={user}
          currentPeriod={currentPeriod}
          handleArchive={handleArchive}
        />
      ) : (
        <List
          dataIsHash
          data={breakdownData}
          settings={breakdownColumns}
          rowClick={row =>
            loadIncident({
              _id: row._id,
              returnPath: `/app/Incidents`,
              selectedTab
            })
          }
          saveKey={breakdownSaveKey}
        />
      )}
    </HeaderAndFooter>
  );
}

import React, { useEffect, useState } from 'react';
import Modal from '.';
import DashboardReports from '../../containers/DashboardReports';
import { EmployeeDropdown, Textbox } from '../inputs';
import { useSelector } from 'react-redux';
import { getMyUserId } from '../../selectors/users';
import { email } from '../../utils/formValidation';
import { getActiveCompany } from '../../selectors/company';

export default function ReportModal({
  isOpen,
  onChange,
  onRequestClose,
  submitActions,
  topMessage,
  exceptionType,
  disableSubmit,
  currentReports
}) {
  const myUserId = useSelector(getMyUserId);
  const company = useSelector(getActiveCompany);
  const [emailText, setEmailText] = useState('');
  const [warningText, setWarning] = useState(null);
  const [editing, setEditing] = useState(currentReports);

  useEffect(() => {
    setEditing(currentReports);
  }, [currentReports]);
  const addEmailToReport = emailToAdd => {
    emailToAdd = emailToAdd.trim();
    if (!emailToAdd) return;
    if (emailToAdd.split(',').length > 1) {
      emailToAdd.split(',').forEach(addEmailToReport);
      return;
    }
    const warning = editing?.find(r => r.externalEmail === emailToAdd)
      ? 'Email already receiving report'
      : email(emailToAdd);
    if (!warning?.length) {
      setEditing(current => [
        ...(current ?? []),
        {
          userId: myUserId,
          externalEmail: emailToAdd,
          type: 'csv',
          companyId: company?._id
        }
      ]);
    } else {
      setWarning(warning);
    }
  };
  return (
    <Modal
      title={`${exceptionType.charAt(0).toUpperCase() +
        exceptionType.slice(1)} Report`}
      titleClassName="blueHeader"
      isOpen={isOpen}
      submitButtonColor="blue"
      submitButtonText="Update"
      onRequestClose={onRequestClose}
      wide
      submitActions={() => submitActions(editing)}
      disableSubmit={
        disableSubmit ||
        !editing.every(
          e =>
            e.type?.length &&
            e.frequency &&
            e.sendOn &&
            (e.userId || e.externalEmail)
        )
      }
    >
      {topMessage}
      <EmployeeDropdown
        multi
        fieldLabel="Send to user"
        searchable
        permissions={[400, 500, 900]}
        exclude={[...(editing ?? []).map(s => s.userId)]}
        placeholder="Select a user to schedule a report for"
        selectButtons
        onChange={userIds => {
          setEditing(current => [
            ...(current ?? []),
            ...userIds.map(userId => {
              return {
                userId,
                type: ['csv'],
                companyId: company?._id,
                exceptionType
              };
            })
          ]);
        }}
      />
      <Textbox
        fieldLabel="Send to external email"
        name="emailText"
        currentValue={emailText}
        onChange={t => {
          setEmailText(t);
          setWarning('');
        }}
        placeholder="Email or comma separated list, Enter to submit"
        enter={addEmailToReport}
        onBlur={addEmailToReport}
        error={warningText}
        touched={warningText}
      />
      <DashboardReports
        title="Reports"
        expired={false}
        onChange={values => {
          setEditing([...values]);
          if (onChange) onChange(values);
        }}
        reports={editing}
        typeOptions={[{ value: 'csv', label: '.csv' }]}
      />
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import { SaveCancelFooter } from '../../../components/Footer';
import TrainingStages from '../../../components/training/TrainingStages';
import BasicTrainingCard from '../../../components/training/Cards/Basic';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import TrainingDetails from '../../../components/training/Cards/Details';
import { addMessage } from '../../../actions/messages';
import {
  addCustomTrainingTemplate,
  fetchAttachmentsByIds,
  updateCustomTrainingTemplate
} from '../../../api/v4';
import { getActiveCompany } from '../../../selectors/company';
import history from '../../../history';
import Modal from '../../../components/Modal';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

import styles from './styles.module.scss';

export default function AddCourseTraining() {
  const location = useLocation();
  const dispatch = useDispatch();
  const activeCompany = useSelector(getActiveCompany);

  const [selectedStage, setStage] = useState(0);
  const [template, setTemplate] = useState({});
  const [editing, setEditing] = useState(false);
  const [oldTemplate, setOldTemplate] = useState('');
  const [unsavedChanges, setUnsaved] = useState(false);
  const [stages] = useState(['Template Basics', 'Details']);
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [editTemplateModal, setEditTemplateModal] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    let training = JSON.parse(localStorage.getItem('trainingTemplate'));
    if (training) {
      let needToFetch =
        training?.attachments?.length &&
        !training?.attachments?.filter(a => a._id)?.length;
      if (needToFetch) {
        fetchAttachmentsByIds(training.attachments).then(r =>
          setTemplate({
            ...training,
            masterTemplateId: training._id ?? training.masterTemplateId,
            attachments: r
          })
        );
      } else {
        setTemplate({
          ...training,
          masterTemplateId: training._id ?? training.masterTemplateId
        });
      }
      if (training?._id ?? training.masterTemplateId) setEditing(true);
      setOldTemplate(training);
      setStage(training.stage ?? 0);
    } else if (location.state.template) {
      setTemplate({ ...location.state.template });
    }
    localStorage.removeItem('trainingTemplate');
  }, [location]);

  const onAnswer = value => {
    setTemplate({ ...template, ...value });
    setUnsaved(true);
    setDisabled(false);
  };

  const handleBack = () => {
    history.push({
      pathname: location.state?.fromScreen ?? '/app/courseTemplates/view',
      state: {
        creating: location.state?.creating
      }
    });
  };

  const handleSubmit = () => {
    if (!template.masterTemplateId) {
      addCustomTrainingTemplate({
        ...template,
        companyId: activeCompany._id,
        isCourseTemplate: !location.state.fromTemplate
      }).then(r => {
        history.push({
          pathname: location.state?.fromScreen ?? '/app/courseTemplates/view',
          state: {
            newTraining: { ...r, approver: template.approver },
            creating: location.state?.creating
          }
        });

        dispatch(
          addMessage({ error: false, message: 'New Training Template Created' })
        );
      });
    } else {
      if (
        location.state.fromTemplate &&
        !template.nscCompanyIds?.length &&
        unsavedChanges
      )
        setEditTemplateModal(true);
      else
        history.push({
          pathname: location.state?.fromScreen ?? '/app/courseTemplates/view',
          state: {
            newTraining: {
              ...template,
              approver: template.approver,
              attachments: template.attachments
            },
            oldTemplate: oldTemplate._id ?? oldTemplate.masterTemplateId,
            creating: location.state?.creating
          }
        });
    }
  };

  const advancePage = () => {
    setStage(selectedStage + 1);
    setUnsaved(false);
    setMissingRequired(false);
    setDisabled(true);
  };

  const isNSC = template?.nscCompanyIds?.length ?? false;

  const header = (
    <Header
      title="Training Template"
      section={`${oldTemplate ? 'Editing for' : 'Add to'} Course${
        location.state.fromTemplate ? ' Template' : ''
      }`}
      needsSaved={unsavedChanges}
      clickBack={() =>
        selectedStage === 0
          ? unsavedChanges
            ? setUnsavedModal(true)
            : handleBack()
          : setStage(selectedStage - 1)
      }
    />
  );
  const footer = (
    <SaveCancelFooter
      saveButtonText={
        selectedStage === stages.length - 1
          ? editing
            ? 'Save'
            : 'Create'
          : 'Next'
      }
      saveButtonClick={() =>
        !canSubmit && selectedStage !== 0
          ? setMissingModalOpen(true)
          : selectedStage === stages.length - 1
          ? handleSubmit()
          : advancePage()
      }
      cancelButtonClick={() =>
        selectedStage === 0
          ? unsavedChanges
            ? setUnsavedModal(true)
            : handleBack()
          : setStage(selectedStage - 1)
      }
      cancelButtonText={selectedStage === 0 ? 'Cancel' : 'Back'}
      reverseOrder
      editing={editing}
      onMouseEnter={() => setMissingRequired(true)}
      // disabling is required because the onMouseEnter fires when you go to the next card
      saveButtonDisabled={disabled && !canSubmit && selectedStage !== 0}
    />
  );

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        className={styles.trainingContainer}
      >
        <TrainingStages stages={stages} selectedStage={selectedStage} />
        {selectedStage === 0 ? (
          <BasicTrainingCard
            courseTraining
            excludeTemplates={location.state?.excludeTemplates}
            training={template}
            fromTemplate={location.state.fromTemplate}
            onAnswer={onAnswer}
            setCanSubmit={setCanSubmit}
            missingRequired={missingRequired}
          />
        ) : (
          <TrainingDetails
            training={template}
            onAnswer={onAnswer}
            titleWarning={
              !template.masterTemplateId &&
              location.state.invalidNames?.includes(
                template.title?.toLowerCase()?.trim()
              )
            }
            fromTemplate={location.state.fromTemplate}
            courseTraining
            setCanSubmit={setCanSubmit}
            editing={!isNSC || !location.state.fromTemplate}
            creating
            missingRequired={missingRequired}
          />
        )}
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={unsavedModal}
        onRequestClose={() => setUnsavedModal(false)}
        submitActions={() => handleBack()}
        savingWhat="a page"
      />
      <Modal
        title="Update Template"
        titleClassName="greenHeader"
        isOpen={editTemplateModal}
        submitButtonColor="green"
        onRequestClose={() => setEditTemplateModal(false)}
        submitActions={() => {
          updateCustomTrainingTemplate({
            ...template,
            _id: template.masterTemplateId,
            attachments: template.oldAttachments
          }).then(r => {
            dispatch(
              addMessage({
                error: false,
                message: 'Training Template Saved Successfully!'
              })
            );
            history.push({
              pathname:
                location.state?.fromScreen ?? '/app/courseTemplates/view',
              state: {
                newTraining: {
                  ...r,
                  approver: template.approver,
                  attachments: template.attachments
                },
                oldTemplate: oldTemplate._id,
                creating: location.state?.creating
              }
            });
          });
        }}
      >
        Saving these changes will update the training template at the company
        level. Are you sure you would like to save these changes?
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        subject="Template"
        onRequestClose={() => setMissingModalOpen(false)}
        isEditing={editing}
      />
    </>
  );
}

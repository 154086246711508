import React, { useState, useEffect } from 'react';
import RichTextEditor from 'react-rte';
import { Input, nameInput } from '../';
import '../input.css';
import styles from './richTextEditor.module.scss';

const RichTextTextbox = ({
  name,
  placeholder,
  onChange,
  onBlur,
  currentValue,
  disabled,
  type,
  inputClassName,
  rows,
  onMouseDown,
  autoFocus,
  inputRef,
  testID,
  enter,
  onKeyDown,
  error,
  touched,
  readOnly,
  ...props
}) => {
  const [richValue, setRichValue] = useState(RichTextEditor.createEmptyValue());
  const [currentValueCopy, setCurrentValueCopy] = useState(currentValue);
  const [startedTyping, setStartedTyping] = useState(false);
  useEffect(() => {
    // we need to check for these so the cursor doesn't get reset to position 0 when its not needed
    if ((currentValue && !startedTyping) || currentValue !== currentValueCopy) {
      setRichValue(
        RichTextEditor.createValueFromString(currentValue, 'markdown')
      );
      setStartedTyping(false);
    }
  }, [currentValue, currentValueCopy, startedTyping]);

  const change = value => {
    setRichValue(value);
    setCurrentValueCopy(value.toString('markdown'));
    setStartedTyping(true);
    onChange(value.toString('markdown'));
  };

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };

  return (
    <Input
      {...props}
      name={name}
      disabled={disabled}
      touched={touched}
      error={error}
    >
      <RichTextEditor
        editorClassName={
          touched && error ? styles.invalidEditor : styles.editor
        }
        toolbarConfig={toolbarConfig}
        toolbarClassName={
          touched && error ? styles.invalidToolbar : styles.toolbar
        }
        value={richValue}
        onChange={change}
        readOnly={readOnly || disabled}
      />
    </Input>
  );
};

let t = nameInput(RichTextTextbox);
export { t as RichTextTextbox };
export default t;

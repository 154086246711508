import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { password, renderField } from '../../utils/formValidation';

import '../../components/inputs/input.css';
import '../form.css';

const ChangeDefaultPasswordForm = props => {
  const { handleSubmit } = props;
  return (
    <form className="newCompanyForm" onSubmit={handleSubmit}>
      <div className="form-fields">
        <Field
          name="new_default_password"
          component={renderField}
          label="New Default User Password"
          validate={[password]}
          placeholder={''}
          requiredLabel={true}
        />
        <Field
          name="confirm_default_password"
          component={renderField}
          label="Confirm New User Default Password"
          validate={[password]}
          placeholder={''}
          requiredLabel={true}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'ChangeDefaultPasswordForm'
})(ChangeDefaultPasswordForm);

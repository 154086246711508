import React, { Component } from 'react';

import { EmployeeDropdown } from '../inputs/Dropdown';
import Card from '../Card';
import Signature from '../inputs/Signature';

import styles from './signatureCard.module.scss';

export default class SignatureCard extends Component {
  render() {
    const {
      name,
      header,
      permissions,
      dropdownName,
      onUserChange,
      userId,
      userDisabled,
      disabled,
      currentValue,
      testID,
      wide,
      touched,
      employees,
      missingRequired,
      ...props
    } = this.props;

    return (
      <Card showHeader title={header} name={name} wide={wide}>
        <div className={styles.signatureCard}>
          {dropdownName && (
            <EmployeeDropdown
              testID={testID}
              fieldLabel={dropdownName}
              permissions={permissions}
              onChange={onUserChange}
              currentValue={userId}
              disabled={userDisabled}
              searchable
              name="approver"
              touched={missingRequired && !userId}
              employees={employees}
            />
          )}
          {currentValue || !disabled ? (
            <div className="input">
              <label className={styles.label}>
                Any person who knowingly and with intent to defraud an insurer
                files a statement of claim containing false, inflated,
                incomplete, or misleading information is subject to prosecution
                and punishment for insurance fraud and may be subject to civil
                fines, criminal penalties, and denial of insurance benefits. By
                electronically submitting this form, I hereby attest that the
                information contained herein and attached in electronic format
                is accurate and correctly represents and warrants the
                truthfulness of the information provided.
              </label>
              <Signature
                currentValue={currentValue}
                disabled={disabled}
                touched={missingRequired && !currentValue}
                {...props}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Card>
    );
  }
}

import React, { useCallback, useEffect, useState } from 'react';
import Card from '../../../Card';
import { Dropdown } from '../../../inputs';
import RadioButtons from '../../../inputs/RadioButtons';
import {
  fetchCustomTrainingTemplates,
  fetchDocumentFoldersByType
} from '../../../../api/v4';
import { TRAINING_TYPES } from '../../../../constants/constants';

import styles from './basicCard.module.scss';

export default function BasicTrainingCard(props) {
  const {
    training,
    onAnswer,
    setCanSubmit,
    disabled,
    fromTemplate,
    courseTraining,
    excludeTemplates,
    onlyTemplates,
    fromApprove,
    missingRequired
  } = props;

  const [templates, setTemplates] = useState([]);
  const [folders, setFolders] = useState([]);

  const clearedTemplate = {
    _id: null,
    approver: '',
    attachments: [],
    category: null,
    doesExpire: false,
    expiresAfterNumber: '',
    expiresAfterOption: 'days',
    instructions: '',
    needsApproval: false,
    minMaterialTime: '',
    quizMasterId: '',
    title: '',
    youtubeLinks: []
  };
  const emptySchedule = {
    schedule: null,
    assignOn: null,
    dueDate: null,
    assignBy: null,
    assignedTo: null,
    frequency: null,
    repeatingOn: [],
    dueBy: null,
    admins: [],
    assignByJobTitles: [],
    completionDate: null,
    releaseType: null
  };

  const setSubmit = useCallback(() => {
    if (!setCanSubmit) return;
    if (!training.trainingType) setCanSubmit(false);
    else if (onlyTemplates && !training.masterTemplateId) setCanSubmit(false);
    else setCanSubmit(true);
  }, [setCanSubmit, training, onlyTemplates]);

  useEffect(() => {
    setSubmit();
  }, [setSubmit, training]);

  useEffect(() => {
    fetchDocumentFoldersByType('SafetyTraining').then(setFolders);
    fetchCustomTrainingTemplates().then(r => {
      setTemplates(
        onlyTemplates
          ? r.filter(temp => onlyTemplates?.includes(temp._id?.toString()))
          : r.filter(temp => !excludeTemplates?.includes(temp._id?.toString()))
      );
    });
  }, [excludeTemplates, onlyTemplates]);

  const allTemplates = [clearedTemplate, ...templates];

  return (
    <Card
      showHeader
      title={fromApprove ? 'Summary' : 'Basic Information'}
      name="title"
    >
      <Dropdown
        fieldLabel="Training Template"
        searchable
        currentValue={
          training.masterTemplateId ??
          training.training?.trainingTemplateId ??
          0
        }
        isRequired={onlyTemplates}
        options={allTemplates.map(t => ({
          value: t._id,
          label: t.title,
          isVisible: !t.isArchived
        }))}
        onChange={v => {
          const templateValues = allTemplates.find(t => t._id === v);
          const newYoutube = templateValues.youtubeLinks?.map(y => ({
            ...y,
            disabled:
              fromTemplate && !templateValues.nscCompanyIds?.length
                ? false
                : true
          }));
          const newAttachments = templateValues.attachments?.map(y => ({
            ...y,
            disabled:
              fromTemplate && !templateValues.nscCompanyIds?.length
                ? false
                : true
          }));
          onAnswer({
            ...templateValues,
            _id: null,
            oldAttachments: templateValues.attachments,
            quizMasterId: templateValues.quizMasterId ?? '',
            expiresAfterOption: templateValues.expiresAfterOption
              ? templateValues.expiresAfterOption
              : 'days',
            masterTemplateId: v,
            minMaterialTime: templateValues.minMaterialTime ?? '',
            canUpdateInstructions: !templateValues?.instructions?.length,
            youtubeLinks: newYoutube,
            attachments: newAttachments
          });
        }}
        disabled={disabled}
      />

      {!courseTraining ? (
        <>
          <RadioButtons
            fieldLabel="Training Format"
            currentValue={training.trainingType}
            options={TRAINING_TYPES}
            isRequired
            needSeparateLines
            onChange={v => onAnswer({ trainingType: v, ...emptySchedule })}
            disabled={disabled || training.equipmentId}
            touched={missingRequired && !training.trainingType}
          />
          <Dropdown
            fieldLabel="Add to Folder"
            className={styles.noTopMargin}
            currentValue={training.documentFolderId}
            options={folders.map(f => ({ value: f._id, label: f.name }))}
            onChange={v => onAnswer({ documentFolderId: v })}
            disabled={disabled}
          />
        </>
      ) : null}
    </Card>
  );
}

import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { loadAllUsersRequest } from '../../../actions/user';
import { getAllUsers } from '../../../selectors/users';
import Dropdown from './';

export const EmployeeDropdown = ({
  bareValues = true,
  employees,
  permissions,
  limit,
  exclude,
  group,
  hideDeactivated,
  ...props
}) => {
  const useRedux = !employees;
  let reduxEmployees = useSelector(getAllUsers, shallowEqual) ?? [];

  const hasAccess = user => {
    if (group) {
      return user.groupIds.includes(group);
    }
    return true;
  };

  const hasArrayAccess = user => {
    if (group) {
      return group.some(g => user.groupIds.includes(g));
    }
  };

  const dispatch = useDispatch();
  if (useRedux) employees = reduxEmployees;

  if (hideDeactivated) employees = employees.filter(e => !e.isDeactivated);
  if (permissions)
    employees = employees.filter(
      e => permissions.includes(e.accessLevel) && !e.isDeactivated
    );
  if (limit) employees = employees.filter(e => limit.includes(e._id));
  if (exclude) employees = employees.filter(e => !exclude.includes(e._id));
  if (Array.isArray(group)) {
    employees = employees.filter(e => hasArrayAccess(e));
  } else if (group) employees = employees.filter(e => hasAccess(e));

  const employeeDropdown = employees.map(e => ({
    value: e._id,
    label: `${e.firstName} ${e.lastName}`,
    status:
      [8, 17, 18, 19].includes(e.status) || e.isDeactivated
        ? 'inactive'
        : 'active',
    accessLevel: e.accessLevel
  }));

  React.useEffect(() => {
    if (useRedux) dispatch(loadAllUsersRequest());
  });

  return (
    <Dropdown
      {...props}
      options={employeeDropdown}
      bareValues={bareValues}
      touched={props.touched}
    />
  );
};

export default EmployeeDropdown;

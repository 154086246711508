import { useEffect, useState } from 'react';

import { useActiveHeirarchy } from '../../utils/useActiveHeirarchy';
// import { getCard } from '../../api';
import { getCard } from '../../api/v4';

export function useCardData(cardData, timeFrame) {
  const [data, setData] = useState(undefined);
  const { location, project } = useActiveHeirarchy();
  const locationId = location?._id;
  const projectId = project?._id;

  useEffect(() => {
    if (!cardData) return;

    setData(undefined);
    getCard({
      ...cardData,
      locationId,
      projectId,
      timeFrame
    }).then(setData);
  }, [cardData, timeFrame, locationId, projectId]);

  return data;
}

export default useCardData;

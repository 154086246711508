import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import history from '../../../history';
import {
  getJobList,
  fetchTrainingExceptionTitles,
  fetchSafetyAuditExceptionTitles,
  fetchLeaderboardById,
  updateLeaderboardById
} from '../../../api/v4';
import { addMessage } from '../../../actions/messages';
import useActiveHeirarchy from '../../../utils/useActiveHeirarchy';
import BlockText from '../../../components/inputs/BlockText';
import {
  Button,
  Dropdown,
  EmployeeDropdown,
  Textbox
} from '../../../components/inputs';
import Card from '../../../components/Card';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import {
  LEADERBOARD_CATEGORIES,
  LEADERBOARD_SUB_CATEGORIES
} from '../../../constants/constants';
import RadioButtons from '../../../components/inputs/RadioButtons';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import { SaveCancelFooter } from '../../../components/Footer';

import styles from '../leaderboardStyles.module.scss';

export default function Leaderboard({
  leaderboards,
  onChange,
  onLeftNavChange,
  missingRequired
}) {
  const { company } = useActiveHeirarchy();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [leaderboard, setLeaderboard] = useState({});
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [auditTitles, setAuditTitles] = useState([]);
  // if we have more filters, we'll move this to the backend, like analytics
  const [trainingTemplates, setTrainingTemplates] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [filterEmps, setFilterEmps] = useState(false);

  useEffect(() => {
    if (id) {
      fetchLeaderboardById(id).then(r => {
        setLeaderboard(r);
        let cat = LEADERBOARD_CATEGORIES.find(c => c.value === r?.category);
        setFilterEmps(cat?.filterEmps);
      });
    }
    fetchSafetyAuditExceptionTitles({ fromLeaderboard: true }).then(
      setAuditTitles
    );
    fetchTrainingExceptionTitles().then(setTrainingTemplates);
    getJobList().then(r => {
      setJobList(r?.map(job => ({ value: job, label: job })));
    });
  }, [id]);

  const groupDropdown = company?.groups
    ?.filter(g => !g.isHidden)
    .map(g => {
      return { value: g._id, label: g.name };
    });

  const updateLeaderBoard = (index, value) => {
    if (id) {
      setLeaderboard({ ...leaderboard, ...value });
      setHasChanges(true);
    } else {
      let updated = leaderboards?.map((l, i) => {
        if (i === index) {
          return { ...l, ...value };
        }
        return l;
      });
      onChange(updated);
    }
  };

  const deleteLeaderBoard = board => {
    let updated = leaderboards?.filter(l => l !== board);
    let updatedLeftNav = updated.map((u, i) => {
      return {
        value: `Leaderboard ${i + 1}`,
        label: `Leaderboard ${i + 1}`,
        id: `Leaderboard ${i + 1}`
      };
    });
    onChange(updated);
    onLeftNavChange(updatedLeftNav);
  };

  const handleSave = () => {
    updateLeaderboardById(id, {
      ...leaderboard,
      title: leaderboard?.title?.trim()
    }).then(() => {
      dispatch(addMessage({ error: false, message: 'Successfully Updated' }));
      history.goBack();
    });
  };

  const renderCard = (l, i) => (
    <Card
      title={`Leaderboard ${i + 1}`}
      name={`Leaderboard ${i + 1}`}
      showHeader={true}
    >
      <Dropdown
        options={LEADERBOARD_CATEGORIES}
        fieldLabel="Category"
        onChange={values => {
          updateLeaderBoard(i, { category: values.value, subCategory: null });
          setFilterEmps(values?.filterEmps);
        }}
        alphabetize
        isRequired
        currentValue={l.category}
        touched={missingRequired && !l.category}
        bareValues={false}
      />
      <Dropdown
        options={LEADERBOARD_SUB_CATEGORIES?.filter(
          s => s.category === l.category
        )}
        fieldLabel="Subcategory"
        onChange={values =>
          [
            'Vehicle Accidents by Driver',
            'Property Damage by Involved Employee'
          ]?.includes(values)
            ? updateLeaderBoard(i, {
                subCategory: values,
                displayContent: 'Individual Employees'
              })
            : updateLeaderBoard(i, { subCategory: values })
        }
        isRequired
        currentValue={l.subCategory}
        alphabetize
        touched={missingRequired && !l.subCategory}
      />
      <Textbox
        currentValue={l.title}
        fieldLabel="Title"
        onChange={value => updateLeaderBoard(i, { title: value })}
        tooltip="If left blank, the title will autofill with subcategory and display content"
        displayTooltip
      />
      {l?.subCategory === 'Most Missed Quiz Questions' ? (
        <Dropdown
          options={trainingTemplates}
          fieldLabel="Training Title"
          onChange={value =>
            updateLeaderBoard(i, { displayContent: 'All', filterString: value })
          }
          alphabetize
          isRequired
          currentValue={l.filterString}
          touched={missingRequired && !l.filterString}
        />
      ) : (
        <RadioButtons
          fieldLabel="Display Content For"
          options={[
            { value: 'Individual Employees', label: 'Individual Employees' },
            { value: 'Locations', label: 'Locations' }
          ]}
          isRequired
          onChange={values =>
            values === 'Locations'
              ? updateLeaderBoard(i, {
                  displayContent: values,
                  groupIds: [],
                  additionalFilters: {
                    empsIds: null,
                    jobTitles: null
                  }
                })
              : updateLeaderBoard(i, { displayContent: values })
          }
          currentValue={l.displayContent}
          needSeparateLines
          disabled={[
            'Vehicle Accidents by Driver',
            'Property Damage by Involved Employee'
          ]?.includes(l.subCategory)}
          touched={missingRequired && !l.displayContent}
        />
      )}
      {l.category?.includes('Audits') ? (
        <Dropdown
          options={auditTitles.filter(t =>
            l.category === 'safetyAudits'
              ? t.type === 'safety'
              : l.category === 'lotoAudits'
              ? t.type === 'loto'
              : t.type === 'quality'
          )}
          fieldLabel="Audit Title"
          onChange={values =>
            updateLeaderBoard(i, {
              auditTitles: values
            })
          }
          alphabetize
          currentValue={l.auditTitles}
          multi
        />
      ) : null}
      {l.displayContent && l.displayContent !== 'Locations' ? (
        <>
          <Dropdown
            options={groupDropdown}
            fieldLabel="Group/Establishment"
            onChange={values => updateLeaderBoard(i, { groupIds: values })}
            currentValue={l.groupIds}
            searchable
            selectButtons
            multi
          />
          {filterEmps ? (
            <>
              <BlockText blockOfText="Add filters for employee(s) or job titles. You cannot filter for employee(s) and job titles at the same time" />
              <EmployeeDropdown
                searchable
                selectButtons
                currentValue={l?.additionalFilters?.empIds}
                onChange={values =>
                  updateLeaderBoard(i, {
                    additionalFilters: {
                      empIds: values,
                      jobTitles: null
                    }
                  })
                }
                fieldLabel="Employee(s)"
                multi
                disabled={l?.additionalFilters?.jobTitles?.length}
              />
              <p>
                <b>- OR -</b>
              </p>
              <Dropdown
                selectButtons
                fieldLabel="Job Titles"
                options={jobList}
                searchable
                onChange={values =>
                  updateLeaderBoard(i, {
                    additionalFilters: {
                      jobTitles: values,
                      empIds: null
                    }
                  })
                }
                multi
                currentValue={l?.additionalFilters?.jobTitles}
                disabled={l?.additionalFilters?.empIds?.length}
              />
            </>
          ) : null}
        </>
      ) : null}
      {leaderboards?.length > 1 && (
        <Button
          color="red"
          onClick={() => deleteLeaderBoard(l)}
          text="Delete"
          inputClassName={styles.deleteButton}
        />
      )}
    </Card>
  );

  const handleGoBack = () => {
    hasChanges ? setShowSaveChangesModal(true) : history.goBack();
  };

  const header = (
    <Header
      section="Edit Leaderboard"
      title={id ? leaderboard?.title : 'Leaderboard'}
      clickBack={handleGoBack}
      needsSaved={hasChanges}
    />
  );

  const footer = (
    <SaveCancelFooter
      editing
      cancelButtonClick={handleGoBack}
      saveButtonDisabled={
        !hasChanges || !leaderboard.category || !leaderboard.subCategory
      }
      saveButtonClick={handleSave}
      saveButtonText="Save"
    />
  );

  return id ? (
    <HeaderAndFooter Header={header} Footer={footer}>
      {renderCard(leaderboard, 0)}
      <SaveChangesModal
        savingWhat="Leaderboard"
        isOpen={showSaveChangesModal}
        onRequestClose={() => setShowSaveChangesModal(false)}
        submitActions={() => history.goBack()}
      />
    </HeaderAndFooter>
  ) : (
    leaderboards?.map((l, i) => renderCard(l, i))
  );
}

import React from 'react';
import Card from '../../Card';
import SafetyTrainingList from '../../training/SafetyTrainingList';
import history from '../../../history';

export default function EquipmentTrainingList(props) {
  const {
    activeEquipment,
    cardClassName,
    childGroupDropdown,
    childProjectDropdown,
    disabled
  } = props;

  return (
    <Card
      title="Trainings"
      testID="Trainings Card"
      className={cardClassName}
      rightButton={{
        text: 'Assign',
        testID: 'Assign',
        color: 'blue',
        onClick: () => {
          localStorage.removeItem('trainingTemplate');
          history.push({
            pathname: '/app/training/create',
            state: {
              equipmentId: activeEquipment._id,
              validTemplateIds: activeEquipment.linkedTrainingTemplates.map(
                t => t._id
              ),
              equipmentGroupDropdown: childGroupDropdown,
              equipmentGroups: activeEquipment.groupIds,
              equipmentProjectDropdown: childProjectDropdown,
              equipmentProjects: activeEquipment.projectIds,
              fromScreen: `/app/equipmentContainer/${activeEquipment._id}`
            }
          });
        },
        disabled: disabled
      }}
      showHeader
    >
      <SafetyTrainingList
        trainings={activeEquipment.linkedTrainings}
        saveKey="equipmentTrainingList"
      />
    </Card>
  );
}

import React, { useState } from 'react';

import Card from '../../Card';
import List from '../../List';
import history from '../../../history';

export default function EquipmentAuditList(props) {
  const [selectedTab, setState] = useState(0);

  const {
    activeEquipment,
    cardClassName,
    childGroupDropdown,
    childProjectDropdown,
    activeGroupId,
    activeProjectId,
    type,
    availableGroups,
    disabled
  } = props;

  const typeName =
    type === 'safety' ? 'Safety Audits' : 'Lockout/Tagout Procedures';

  const listContent =
    type === 'safety'
      ? [
          activeEquipment?.linkedSafetyAudits?.filter(
            sa => sa.currentWfStage === 0 && !sa.isDeleted
          ),
          activeEquipment?.linkedSafetyAudits?.filter(
            sa => sa.currentWfStage === 1 && !sa.isDeleted
          ),
          activeEquipment?.linkedSafetyAudits?.filter(
            sa => sa.currentWfStage === 2 && !sa.isDeleted
          ),
          activeEquipment?.linkedSafetyAudits?.filter(
            sa => sa.currentWfStage === 3 && !sa.isDeleted
          )
        ]
      : [
          activeEquipment?.linkedLockoutTagouts?.filter(
            sa => sa.currentWfStage === 0 && !sa.isDeleted
          ),
          activeEquipment?.linkedLockoutTagouts?.filter(
            sa => sa.currentWfStage === 1 && !sa.isDeleted
          ),
          activeEquipment?.linkedLockoutTagouts?.filter(
            sa => sa.currentWfStage === 2 && !sa.isDeleted
          ),
          activeEquipment?.linkedLockoutTagouts?.filter(
            sa => sa.currentWfStage === 3 && !sa.isDeleted
          )
        ];

  const activeSettings = [
    { key: 'title', label: 'Title' },
    { key: 'description' },
    {
      key: 'assignedTo',
      datatype: 'users',
      label: 'Assignee(s)'
    },
    {
      key: 'dueDate',
      label: 'Due By',
      datatype: 'date'
    }
  ];

  const tabList = {
    selected: selectedTab,
    onClick: idx => setState(idx),
    list: [
      {
        name: 'New',
        color: 'blue',
        count: listContent[0]?.length
      },
      {
        name: 'In Progress',
        color: 'blue',
        count: listContent[1]?.length
      },
      {
        name: 'Severity Review',
        color: 'blue',
        count: listContent[2]?.length
      },
      {
        name: 'Closed',
        color: 'blue',
        count: listContent[3]?.length
      }
    ]
  };
  return (
    <Card
      title={typeName}
      testID={`${typeName} Card`}
      className={cardClassName}
      rightButton={{
        text: 'Assign',
        testID: 'Assign',
        color: 'blue',
        onClick: () =>
          history.push(`/app/audits/${type}/create/`, {
            audit: {
              ownerType: 'equipment',
              ownerId: activeEquipment?._id,
              groupId:
                availableGroups?.length === 1
                  ? availableGroups[0]
                  : activeGroupId ?? '',
              projectId:
                availableGroups?.length === 1 &&
                activeEquipment?.projectIds?.length === 1
                  ? activeEquipment?.projectIds[0]
                  : activeProjectId ?? '',
              schedule: 'immediate',
              type: type
            },
            linkedAuditTemplates:
              type === 'safety'
                ? activeEquipment.linkedSafetyAuditTemplates
                : activeEquipment.linkedLOTOTemplates,
            equipmentGroupDropdown: childGroupDropdown,
            equipmentProjectDropdown: childProjectDropdown
          }),
        disabled: disabled
      }}
      showHeader
    >
      <List
        saveKey="equipmentAudits"
        rowClick={row =>
          row.isCompleted
            ? history.push(`/app/audits/${type}/summary/${row._id}`)
            : history.push(`/app/audits/${type}/perform/${row._id}`)
        }
        data={listContent[selectedTab]}
        dataIsHash
        settings={activeSettings}
        tabs={tabList}
      />
    </Card>
  );
}

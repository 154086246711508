import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import moment from 'moment';
import {
  fetchSafetyWalks,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter
} from '../../api/v4';
import { loadSafetyWalk } from '../../actions/safetyWalks';
import { SAFETY_WALKS_STAGES } from '../../constants/constants';
import { getActiveCompany, getActiveLocationId } from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import { usePrevious } from '../../utils/hooks';
import { useSocket } from '../../utils/withSocket';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import List from '../../components/List';
import TypeSelection from '../../components/TypeSelection';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';
import Modal from '../../components/Modal';
import { Dropdown } from '../../components/inputs';

import styles from '../Audits/safetyAudits.module.scss';

export default function SafetyWalkStages() {
  const dispatch = useDispatch();
  const getSafetyWalk = payload => dispatch(loadSafetyWalk(payload));

  const [safetyWalks, setSafetyWalks] = useState([]);
  const [listType, setListType] = useState('Active');
  const [selectedStage, setSelectedStage] = useState(0);
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [bulkDLModalOpen, setBulkDLModalOpen] = useState(false);
  const [bulkOptions, setBulkOptions] = useState({});
  const [bulkReport, setBulkReport] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);

  const user = useSelector(getLoggedInUser);
  const activeCompany = useSelector(getActiveCompany);
  const activeLocation = useSelector(getActiveLocationId);
  const prevPeriod = usePrevious(currentPeriod);
  const prevType = usePrevious(listType);
  const socket = useSocket();

  const dashOrSW = activeCompany.isDash ? 'DASH Report' : 'Safety Walk';

  useEffect(() => {
    fetchUserListFilter(user._id, 'safetyWalk').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });

    if (!socket) return;
    socket.emit(
      'subscribeBulkSafetyWalks',
      user._id + activeCompany._id + activeLocation?._id
    );
    socket.on('bulksafetyWalksPdf', value => {
      if (!value) return;
      if (moment().isAfter(moment(value?.createdAtTime).add(7, 'days')))
        socket.emit('deletePdf', {
          id: user._id + activeCompany._id + activeLocation?._id,
          type: 'bulksafetyWalks'
        });
      else {
        setBulkReport(value);
        setLoadingReport(value && !value?.url);
      }
    });
    socket.on('printFail', error => {
      setBulkReport(null);
      console.log(error);
    });
  }, [user._id, socket, activeCompany._id, activeLocation._id]);

  useEffect(() => {
    if (
      currentPeriod &&
      (!isEqual(prevPeriod, currentPeriod) || !isEqual(prevType, listType)) &&
      (currentPeriod.mode !== 'absolute' ||
        (currentPeriod.startDate && currentPeriod.endDate))
    )
      fetchSafetyWalks({ ...currentPeriod, selectedType: listType }).then(
        setSafetyWalks
      );
  }, [prevPeriod, currentPeriod, listType, prevType]);

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'safetyWalk',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      fetchSafetyWalks({ ...currentPeriod, selectedType: listType }).then(
        setSafetyWalks
      )
    );
  };

  const content = SAFETY_WALKS_STAGES.map(status =>
    safetyWalks && safetyWalks.length > 0
      ? safetyWalks.filter(
          sw => sw?.stage?.value === status.value && !sw.isArchived
        )
      : []
  );

  const tabList = {
    selected: selectedStage,
    onClick: setSelectedStage,
    list: SAFETY_WALKS_STAGES.map((stage, i) => ({
      name: stage.label,
      color: 'blue',
      count: content[i]?.length
    }))
  };

  const columns = [
    {
      key: 'safetyWalkNumber',
      label: activeCompany.isDash ? 'DASH title' : 'SW Title'
    },
    {
      key: 'createdByUser',
      label: 'Reporter',
      datatype: 'user',
      accessor: r => r.auditLog[0]?.user || r.createdBy
    },
    {
      key: 'hazardsFound',
      label: 'Hazard Count',
      accessor: r => r.summary?.hazardsFound,
      filter: false
    },
    {
      key: 'bravosFound',
      label: 'Bravos Count',
      accessor: d => d.summary?.bravosFound,
      filter: false
    },
    {
      key: 'correctiveActionsCreated',
      label: 'Corrective Actions Created',
      accessor: d => d.summary?.correctiveActionsCreated,
      filter: false
    },
    {
      key: 'createdAt',
      label: 'Created Date',
      datatype: 'date'
    }
  ];

  if (!activeLocation) {
    columns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  const header = (
    <Header
      title={`${dashOrSW}s`}
      pageActionOptions={[
        {
          label: `Export ${dashOrSW}s`,
          color: 'blueOutline',
          onClick: () => {
            setBulkDLModalOpen(true);
            setBulkOptions({});
          },
          visible: true
        },
        {
          label: `${loadingReport ? 'Generating' : 'Download'} Bulk Report`,
          color: loadingReport ? 'blueOutline' : 'greenOutline',
          onClick: () =>
            !loadingReport && bulkReport?.url
              ? window.open(bulkReport.url, '_blank')
              : null,
          visible: loadingReport || bulkReport?.url,
          disabled: loadingReport
        }
      ]}
    />
  );

  const missingRequiredOptions =
    !bulkOptions.period?.mode ||
    (bulkOptions.period?.mode === 'absolute' &&
      !bulkOptions.period?.startDate &&
      !bulkOptions.period?.endDate);

  return (
    <HeaderAndFooter Header={header}>
      <ScrollToTopOnMount />
      {user.accessLevel > 400 ? (
        <TypeSelection
          selected={listType}
          selectedArray={['Active', 'Archived']}
          onClick={v => {
            setSafetyWalks([]);
            setListType(v);
          }}
        />
      ) : null}
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={v => {
          setSafetyWalks([]);
          setCurrentPeriod(v);
        }}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'safetyWalk',
            period: v
          }).then(() => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <List
        saveKey={`${listType}swStages`}
        tabs={listType === 'Active' ? tabList : null}
        data={listType === 'Active' ? content[selectedStage] : safetyWalks}
        dataIsHash
        settings={columns}
        getRowId={r => r._id}
        rowClick={row => getSafetyWalk(row._id)}
        actions={
          user.accessLevel > 400
            ? [
                {
                  color: 'blue',
                  label: `${
                    listType === 'Archived' ? 'Un-' : ''
                  }Archive ${dashOrSW}s`,
                  onClick: handleArchive
                }
              ]
            : null
        }
      />
      <Modal
        isOpen={bulkDLModalOpen}
        onRequestClose={() => {
          setBulkDLModalOpen(false);
          setMissingRequired(false);
        }}
        title={`Export ${dashOrSW}s`}
        titleClassName="blueHeader"
        submitButtonText="Generate"
        submitActions={() => {
          if (!missingRequiredOptions) {
            setLoadingReport(true);
            socket.emit('generateBulkReport', {
              ...bulkOptions,
              user: user._id,
              company: activeCompany._id,
              type: 'safetyWalks',
              group: activeLocation?._id
            });
            setBulkDLModalOpen(false);
            setBulkReport(null);
          }
        }}
        submitButtonColor="blue"
        onMouseEnter={() => setMissingRequired(missingRequiredOptions)}
        wide
      >
        <AnalyticsPeriodPicker
          period={bulkOptions.period}
          onChange={v => {
            setBulkOptions({ ...bulkOptions, period: v });
            setMissingRequired(false);
          }}
          error={missingRequired}
          isBulkPicker
        />
        <Dropdown
          options={SAFETY_WALKS_STAGES}
          onChange={v => setBulkOptions({ ...bulkOptions, stages: v })}
          currentValue={bulkOptions.stages}
          fieldLabel={`${dashOrSW} Stages`}
          className={styles.stageDropdown}
          multi
          searchable
          selectButtons
        />
        <p className={styles.infoText}>
          Once the report has finished generating, it can be accessed for one
          week via the Download Report option in the {`${dashOrSW}s`} List Page
          Actions menu.
        </p>
      </Modal>
    </HeaderAndFooter>
  );
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { EQUIPMENT_STATUS_DROPDOWN } from '../../constants/constants';
import { deleteAttachment, uploadAttachment } from '../../api/v4';
import {
  createEquipment,
  deleteEquipment,
  fetchEquipmentById,
  fetchSafetyAuditTemplates,
  updateEquipmentById,
  equipmentUpdateStatus,
  fetchCustomTrainingTemplates,
  updateDocument,
  createDocument,
  fetchDocumentsByType,
  deleteDocument,
  fetchDocumentFoldersByType,
  getCompanyGroups,
  downloadEquipmentQrCodes
} from '../../api/v4';
import { clearUploadedAttachments } from '../../actions/attachments';
import { addMessage } from '../../actions/messages';
import { loadTask, setActiveTaskRequest } from '../../actions/tasks';
import { formatFiles } from '../../utils/attachmentSelector';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import {
  getActiveCompany,
  getActiveLocationId,
  getActiveProject,
  getAllLocations
} from '../../selectors/company';
import { getLoggedInUser } from '../../selectors/users';
import AttachmentUploadCard from '../../components/AttachmentUploadCard';
import Card from '../../components/Card';
import EquipmentDocumentsList from '../../components/EquipmentCards/EquipmentDocumentsList';
import EquipmentAuditList from '../../components/EquipmentCards/EquipmentAuditList';
import EquipmentTaskList from '../../components/EquipmentCards/EquipmentTaskList';
import EquipmentTrainingList from '../../components/EquipmentCards/EquipmentTrainingList';
import { SaveCancelFooter } from '../../components/Footer';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import HierarchySelector from '../../components/HierarchySelector';
import { Dropdown, TwoColumn, Textbox, Button } from '../../components/inputs';
import LeftNav from '../../components/LeftNav';
import Modal from '../../components/Modal';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import StageSelector from '../../components/StageSelector';
import TypeSelection from '../../components/TypeSelection';
import { DatePicker } from '../../components/inputs/DateTimePicker';
import Money from '../../components/inputs/Money';
import history from '../../history';
import styles from './index.module.scss';
import EquipmentDocumentForm from '../../forms/EquipmentDocumentForm';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function EquipmentContainer() {
  const { equipmentId } = useParams();
  const dispatch = useDispatch();

  const activeCompany = useSelector(getActiveCompany);
  const activeLocation = useSelector(getActiveLocationId);
  const activeProject = useSelector(getActiveProject);
  const allGroups = useSelector(getAllLocations);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const activeUser = useSelector(getLoggedInUser);

  // navigation states
  const [isCreating, setCreating] = useState(true);
  const [isEditing, setEditing] = useState(false);
  const [hasUnsavedChanges, setUnsaved] = useState(false);
  const [openUnsavedChangesModal, setUnsavedModal] = useState(false);
  const [openDeleteEquipmentModal, setDeleteModal] = useState(false);
  const [safetyProcedureModal, setProcedureModal] = useState(false);
  const [updatingSI, setUpdatingSI] = useState(false);
  const [view, setView] = useState('Safety Audits');
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [docMissingRequired, setDocMissingRequired] = useState(false);

  //fetched data
  const [saTemplateDropdown, setSATemplateDropdown] = useState([]);
  const [lotoTemplateDropdown, setLotoTemplateDropdown] = useState([]);
  const [trainingTemplateDropdown, setTrainingTemplateDropdown] = useState([]);
  const [companyDocuments, setCompanyDocuments] = useState([]);
  const [documentFolders, setDocumentFolders] = useState([]);
  const [activeSI, setActiveSI] = useState(null);

  // stored attachments prior to creating
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [attachmentsToUpload, setAttachmentsToUpload] = useState([]);
  const [companyGroups, setCompanyGroups] = useState([]);

  // copy to be edited
  const [activeEquipment, updateEquipmentState] = useState({});
  const [editingEquipment, setEditingEquipment] = useState({
    groupIds: [],
    projectIds: [],
    title: '',
    location: '',
    serial: '',
    companyThatMade: '',
    model: '',
    status: 1,
    linkedSafetyAuditTemplates: [],
    linkedLOTOTemplates: [],
    linkedDocuments: [],
    linkedTrainingTemplates: []
  });

  const {
    groupIds,
    projectIds,
    title,
    location,
    serial,
    companyThatMade,
    model,
    status,
    linkedSafetyAuditTemplates,
    linkedLOTOTemplates,
    linkedDocuments,
    linkedTrainingTemplates,
    expirationDate,
    manufactureDate,
    costOfEquipment,
    equipmentQuantity,
    isArchived
  } = editingEquipment;

  const emptySI = {
    label: '',
    documentFolderId: '',
    expires: '',
    documentData: {
      description: '',
      currentPriority: '',
      nextSteps: '',
      notes: '',
      questionsPhone: '',
      questionsEmail: '',
      comments: ''
    }
  };
  const [SIForm, setSIForm] = useState(emptySI);

  const groups = activeCompany.groups?.filter(g => groupIds?.includes(g._id));

  const hiddenOrArchived = groups.every(g => g?.isHidden) || isArchived;

  const resetSIForm = () => {
    setActiveSI(null);
    setSIForm(emptySI);
    dispatch(clearUploadedAttachments());
  };

  const handleAnswer = update => {
    setEditingEquipment({ ...editingEquipment, ...update });
    setUnsaved(true);
  };

  const findGroupNames = groupIds =>
    companyGroups
      ?.filter(g => groupIds.includes(g._id))
      ?.map(g => g.name)
      .join(', ');

  const findProjectNames = (groupIds, projectIds) => {
    let groups = companyGroups?.filter(g => groupIds.includes(g._id));
    const projects = groups
      ?.map(g => {
        return g.projects?.map(p => ({ ...p, groupName: g?.name }));
      })
      .reduce((acc, x) => acc.concat(x), []);
    return projects
      ?.filter(p => projectIds?.includes(p._id))
      ?.map(g => `${g.groupName} - ${g.name}`)
      .join(', ');
  };
  const canSubmit =
    groupIds?.length && title.trim().length > 2 && location.trim().length > 2;

  const handleBack = () => {
    if (hasUnsavedChanges) setUnsavedModal(true);
    else history.push('/app/equipmentList');
  };

  const setActiveEquipment = update => {
    updateEquipmentState({
      ...update.equipment,
      attachments: update?.attachments || activeEquipment.attachments || []
    });
    if (!isEditing)
      setEditingEquipment({ ...editingEquipment, ...update.equipment });
  };

  const createOrUpdateEquipment = () => {
    try {
      let id = activeEquipment?._id;
      if (isCreating) {
        if (!groupIds.length)
          setEditingEquipment({
            ...editingEquipment,
            groupId: activeLocation._id
          });
        createEquipment({
          ...editingEquipment,
          companyId: activeCompany._id
        }).then(response => {
          id = response._id;

          if (filesToUpload?.length > 0) {
            let data = formatFiles(filesToUpload);

            uploadAttachment(data, {
              ownerId: id,
              ownerType: 'equipment',
              isSignature: false,
              isPhotoEvidence: false,
              photoType: ''
            }).then(r => {
              setAttachmentsToUpload([]);
              setFilesToUpload([]);
              history.push(`/app/equipmentContainer/${id}`);
            });
          } else {
            history.push('/app/equipmentContainer/' + id);
          }
        });
      } else {
        updateEquipmentById(editingEquipment).then(r => {
          fetchEquipmentById(r.equipment._id).then(r2 =>
            setActiveEquipment(r2)
          );
        });
      }
    } catch (e) {
      dispatch(addMessage({ error: true, message: e }));
    }
    setEditing(false);
    setUnsaved(false);
  };

  const updateEquipmentStage = status =>
    equipmentUpdateStatus({
      _id: activeEquipment._id,
      status
    }).then(r => {
      setActiveEquipment(r);
    });

  const addAttachments = async attachments => {
    try {
      let data = formatFiles(attachments);
      uploadAttachment(data, {
        ownerId: activeEquipment._id ?? 'pending',
        ownerType: 'equipment',
        isSignature: false,
        photoType: ''
      }).then(response => {
        updateEquipmentState({
          ...activeEquipment,
          attachments: activeEquipment.attachments.concat(response)
        });
        dispatch(
          addMessage({
            error: false,
            message: 'Attachment Successfully Uploaded'
          })
        );
      });
    } catch (e) {
      dispatch(addMessage({ error: true, message: e }));
    }
  };

  const handleDeleteAttachment = attachment => {
    try {
      deleteAttachment(attachment._id).then(
        fetchEquipmentById(activeEquipment._id).then(response =>
          updateEquipmentState({
            ...activeEquipment,
            attachments: response.attachments
          })
        )
      );
    } catch (e) {
      dispatch(addMessage({ error: true, message: e }));
    }
  };

  const createSafetyDocument = () => {
    const values = {
      groupIds,
      projectIds,
      ...SIForm,
      expires: SIForm.expires
        ? moment(SIForm.expires).format('MM/DD/YYYY')
        : '',
      documentType: 'SafetyInstruction',
      companyId: activeCompany._id,
      attachments: activeSI?.attachments?.length
        ? [...activeSI?.attachments, ...addedAttachments]
        : addedAttachments
    };

    if (updatingSI && activeSI) {
      updateDocument(values).then(() => {
        updateDocumentList();
      });
    } else {
      if (isCreating || isEditing)
        createDocument({
          ...values,
          documentType: 'SafetyInstruction'
        }).then(r => {
          linkedDocuments.push(r);
          setUnsaved(true);
          updateDocumentList();
        });
      else
        createDocument({
          ...values,
          ownerType: 'equipment',
          ownerId: equipmentId,
          documentType: 'SafetyInstruction'
        }).then(r => {
          linkedDocuments.push(r);
          fetchEquipmentById(activeEquipment._id).then(setActiveEquipment);
          updateDocumentList();
        });
    }
    resetSIForm();
    setProcedureModal(false);
  };

  const updateDocumentList = () => {
    fetchDocumentsByType('SafetyInstruction').then(r => {
      setCompanyDocuments(r);
    });
  };

  useEffect(() => {
    getCompanyGroups(activeCompany?._id).then(r =>
      setCompanyGroups(r?.filter(g => !g.isHidden))
    );
    fetchSafetyAuditTemplates().then(response => {
      setSATemplateDropdown(
        response.map(template => {
          return { label: template.title, _id: template._id };
        })
      );
    });
    if (activeCompany?.hasLockoutTagout) {
      fetchSafetyAuditTemplates('loto').then(response => {
        setLotoTemplateDropdown(
          response.map(template => {
            return { label: template.title, _id: template._id };
          })
        );
      });
    }
    fetchCustomTrainingTemplates().then(response => {
      setTrainingTemplateDropdown(
        response.map(tt => ({ label: tt.title, _id: tt._id }))
      );
    });
    fetchDocumentsByType('SafetyInstruction').then(r => {
      if (companyDocuments.length !== r.length) setCompanyDocuments(r);
    });
    fetchDocumentFoldersByType('SafetyInstruction').then(setDocumentFolders);

    if (equipmentId) {
      fetchEquipmentById(equipmentId).then(r => {
        setActiveEquipment(r);
        setCreating(false);
      });
    } else {
      setEditingEquipment({
        ...editingEquipment,
        groupIds: activeLocation?._id ? [activeLocation?._id] : groupIds,
        projectIds: activeProject?._id ? [activeProject?._id] : projectIds
      });
    }

    // eslint wants to include setState functions (which triggers a loop)
    // eslint-disable-next-line
  }, [equipmentId, activeLocation, activeProject, companyDocuments]);

  const handleArchive = () => {
    updateEquipmentById({
      ...editingEquipment,
      isArchived: !activeEquipment.isArchived
    }).then(response => {
      if (response.equipment.isArchived) history.push('/app/equipmentList');
      else {
        setActiveEquipment(response);
        setUnsaved(false);
      }
    });
  };

  const header = (
    <Header
      title={
        !isEditing && !isCreating
          ? title
          : isCreating
          ? 'Add Equipment'
          : `Edit ${title}`
      }
      needsSaved={hasUnsavedChanges}
      clickBack={handleBack}
      center={
        !isCreating &&
        !isEditing && (
          <StageSelector
            label="Status"
            options={EQUIPMENT_STATUS_DROPDOWN}
            currentValue={status}
            onChange={updateEquipmentStage}
            testID="stageSelector"
          />
        )
      }
      pageActionOptions={
        !isCreating
          ? [
              {
                label: 'Edit Equipment',
                visible: !isEditing && !isArchived,
                color: 'blueOutline',
                onClick: () => setEditing(true)
              },
              {
                label: 'Delete Equipment',
                visible: isEditing || activeEquipment.isArchived,
                color: 'redOutline',
                onClick: () => setDeleteModal(true)
              },
              {
                label: `${
                  activeEquipment?.isArchived ? 'Un-' : ''
                }Archive Equipment`,
                visible: true,
                color: 'blueOutline',
                onClick: () => handleArchive()
              }
            ]
          : null
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        canSubmit ? createOrUpdateEquipment() : setMissingModalOpen(true)
      }
      cancelButtonClick={() =>
        isEditing && hasUnsavedChanges
          ? setUnsavedModal(true)
          : isEditing
          ? setEditing(false)
          : history.goBack()
      }
      editing={isEditing}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const disabled = !isEditing && !isCreating;

  const formatCost = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const leftNavEditing = [
    { label: 'Equipment Information', id: 'equipmentEditing' },
    { label: 'Attachments', id: 'attachments' },
    { label: 'Documents', id: 'documents' },
    { label: 'Safety Audits', id: 'safetyAudits' },
    { label: 'Trainings', id: 'trainings' }
  ];
  const leftEditing = <LeftNav items={leftNavEditing} />;

  const leftNav = [
    { label: 'Equipment Information', id: 'equipmentInfo' },
    { label: 'Attachments', id: 'attachments' },
    { label: 'Assignables', id: 'assignables' }
  ];
  const left = <LeftNav items={leftNav} />;

  const handleGroupChange = values => {
    const validProjectIds = activeCompany.groups
      .map(g => {
        if (values.includes(g._id)) {
          return g.projects.map(p => p._id);
        } else {
          return [];
        }
      })
      .reduce((acc, x) => acc.concat(x), []);

    const newProjectIds = projectIds?.filter(p => validProjectIds.includes(p));
    handleAnswer({ groupIds: values, projectIds: newProjectIds });
  };

  const childGroupDropdown = activeCompany.groups
    ?.filter(g => groupIds?.includes(g._id) && !g.isHidden)
    ?.map(g => ({ label: g.name, value: g._id }));

  const childProjectDropdown = () => {
    let projects = allGroups
      .filter(g => groupIds?.includes(g._id))
      ?.reduce((acc, cur) => acc.concat(cur.projects), []);

    if (projectIds?.length) {
      projects = projects.filter(
        p => projectIds?.includes(p._id) && !p.isHidden
      );
    }

    return projects?.map(p => ({
      value: p._id,
      label: p.name,
      groupId: p.groupId
    }));
  };

  const availableAssignmentGroups =
    activeUser.accessLevel === 500
      ? activeEquipment.groupIds?.filter(id => activeUser.groupIds.includes(id))
      : activeEquipment?.groupIds;

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        Left={isCreating || isEditing ? leftEditing : left}
        showFooter={isCreating || isEditing}
      >
        {isCreating || isEditing ? (
          <>
            <Card
              name="equipmentEditing"
              title="Equipment Information"
              showHeader
            >
              <TwoColumn>
                <Textbox
                  currentValue={title}
                  fieldLabel="Title"
                  onChange={v => handleAnswer({ title: v })}
                  placeholder="Type a clear and concise title."
                  isRequired={true}
                  disabled={disabled}
                  touched={title.length < 3 && missingRequired}
                  errorMessage="Title must be 3 or more characters"
                  showWarning={title && title.length < 3}
                />
                <Textbox
                  currentValue={location}
                  fieldLabel="Location"
                  onChange={v => handleAnswer({ location: v })}
                  placeholder="Type here"
                  isRequired={true}
                  disabled={disabled}
                  touched={location.length < 3 && missingRequired}
                  errorMessage="Location must be 3 or more characters"
                  showWarning={location && location.length < 3}
                />
              </TwoColumn>
              <HierarchySelector
                onGroupChange={handleGroupChange}
                groupId={groupIds}
                groupDisabled={disabled}
                onProjectChange={v =>
                  handleAnswer({ projectIds: v.length ? v : [] })
                }
                projectId={projectIds}
                projectDisabled={disabled}
                multi
                touched={missingRequired && !groupIds.length}
              />
              <TwoColumn>
                <Textbox
                  currentValue={serial}
                  fieldLabel="Serial Number"
                  onChange={v => handleAnswer({ serial: v })}
                  placeholder="Type here"
                  disabled={disabled}
                />
                <Textbox
                  currentValue={companyThatMade}
                  fieldLabel="Manufacturer"
                  onChange={v => handleAnswer({ companyThatMade: v })}
                  placeholder="Type here"
                  disabled={disabled}
                />
                <Textbox
                  currentValue={model}
                  fieldLabel="Model"
                  onChange={v => handleAnswer({ model: v })}
                  placeholder="Type here"
                  disabled={disabled}
                />
                <Dropdown
                  options={EQUIPMENT_STATUS_DROPDOWN}
                  fieldLabel="Status"
                  currentValue={status}
                  onChange={v => handleAnswer({ status: v })}
                  disabled={disabled}
                />
                <DatePicker
                  fieldLabel="Expiration Date"
                  onChange={v => handleAnswer({ expirationDate: v })}
                  disabled={disabled}
                  currentValue={expirationDate}
                  name="expirationDate"
                />
                <DatePicker
                  fieldLabel="Manufacture Date"
                  onChange={v => handleAnswer({ manufactureDate: v })}
                  disabled={disabled}
                  currentValue={manufactureDate}
                  name="manufactureDate"
                />
                <Money
                  fieldLabel="Cost of Equipment"
                  name="costOfEquipment"
                  onChange={v => handleAnswer({ costOfEquipment: v })}
                  currentValue={costOfEquipment || null}
                  placeholder="0.00"
                  disabled={disabled}
                />
                <Textbox
                  fieldLabel="Quantity of Equipment"
                  currentValue={equipmentQuantity}
                  type="number"
                  onChange={v => handleAnswer({ equipmentQuantity: v })}
                  placeholder="0"
                  disabled={disabled}
                />
              </TwoColumn>
            </Card>{' '}
          </>
        ) : (
          <div id="equipmentInfo" className={styles.infoAndQrFlexbox}>
            <Card
              title="Equipment Information"
              className={styles.equipmentInfo}
              showHeader
            >
              <div className={styles.infoCard}>
                <div className={styles.infoLabels}>
                  {[
                    'Title',
                    'Location',
                    'Group/Est.',
                    'Area',
                    'Serial Number',
                    'Manufacturer',
                    'Model',
                    'Status',
                    'Expiration Date',
                    'Manufacture Date',
                    'Cost of Equipment',
                    'Quantity of Equipment'
                  ].map(label => (
                    <>
                      {`${label}:`}
                      <br />
                    </>
                  ))}
                </div>
                <div className={styles.info}>
                  {[
                    activeEquipment?.title,
                    activeEquipment?.location,
                    <>
                      <span data-tip data-for="groupNames">
                        {findGroupNames(activeEquipment?.groupIds) || ''}
                      </span>
                      {activeEquipment?.groupIds?.length > 3 ? (
                        <ReactTooltip
                          className={styles.tooltip}
                          id="groupNames"
                        >
                          <span className={styles.userLocationBubble}>
                            {findGroupNames(activeEquipment?.groupIds) || ''}
                          </span>
                        </ReactTooltip>
                      ) : null}
                    </>,
                    <>
                      <span data-tip data-for={`projectNames`}>
                        {findProjectNames(
                          activeEquipment?.groupIds,
                          activeEquipment?.projectIds
                        ) || ''}
                      </span>
                      {activeEquipment?.projectIds?.length > 3 ? (
                        <ReactTooltip
                          className={styles.tooltip}
                          id="projectNames"
                        >
                          <span>
                            {findProjectNames(
                              activeEquipment?.groupIds,
                              activeEquipment?.projectIds
                            ) || ''}
                          </span>
                        </ReactTooltip>
                      ) : null}
                    </>,
                    activeEquipment?.serial || '',
                    activeEquipment?.companyThatMade || '',
                    activeEquipment?.model || '',
                    EQUIPMENT_STATUS_DROPDOWN.filter(
                      v => v.value === activeEquipment.status
                    )[0]?.label || 'N/A',
                    activeEquipment?.expirationDate
                      ? moment(activeEquipment?.expirationDate).format(
                          'MM/DD/YYYY'
                        )
                      : '',
                    activeEquipment?.manufactureDate
                      ? moment(activeEquipment?.manufactureDate).format(
                          'MM/DD/YYYY'
                        )
                      : '',
                    activeEquipment?.costOfEquipment
                      ? formatCost.format(activeEquipment?.costOfEquipment)
                      : '',
                    activeEquipment?.equipmentQuantity || ''
                  ].map(v => (
                    <>
                      {v}
                      <br />
                    </>
                  ))}
                </div>
              </div>
            </Card>
            <Card
              name="qrCode"
              title="QR Code"
              className={styles.qrCodeCard}
              showHeader
            >
              <img
                src={'https://' + activeEquipment.qrCodeImageUrl}
                className={styles.qrCode}
                alt={'Error Displaying QR Code'}
              />
              <Button
                color="blue"
                text="Download"
                onClick={() =>
                  downloadEquipmentQrCodes({
                    ids: activeEquipment?._id
                  }).then(response => window.open(response, '_blank'))
                }
              />
            </Card>
          </div>
        )}

        <AttachmentUploadCard
          name="attachments"
          addAttachments={addAttachments}
          deleteAttachment={handleDeleteAttachment}
          isCreating={isCreating}
          isEditing={isEditing}
          addedAttachments={activeEquipment.attachments || []}
          attachmentsToUpload={attachmentsToUpload}
          handleAnswer={(currentAttachments, currentFiles) => {
            setAttachmentsToUpload(currentAttachments);
            setFilesToUpload(currentFiles);
            setUnsaved(true);
          }}
          ownerType="equipment"
          formattedAttachmentsToUpload={filesToUpload}
        />

        {isCreating || isEditing ? (
          <>
            <Card
              name="documents"
              title="Documents"
              showHeader
              rightButton={
                <Button
                  onClick={() => {
                    setProcedureModal(true);
                    setUpdatingSI(true);
                  }}
                  type="button"
                  text="Upload Document"
                  color="blue"
                  disabled={!canSubmit && isCreating}
                />
              }
            >
              <Dropdown
                multi
                placeholder="Choose one or more"
                options={companyDocuments}
                disabled={!canSubmit && isCreating}
                currentValue={linkedDocuments}
                onChange={v => handleAnswer({ linkedDocuments: v })}
                searchable
                selectButtons
                valueKey={'_id'}
                testID="Documents Dropdown"
              />
            </Card>
            <Card name="safetyAudits" title="Safety Audits" showHeader>
              <Dropdown
                multi
                placeholder="Choose one or more"
                options={saTemplateDropdown}
                disabled={!canSubmit && isCreating}
                currentValue={linkedSafetyAuditTemplates}
                onChange={v => handleAnswer({ linkedSafetyAuditTemplates: v })}
                searchable
                selectButtons
                valueKey={'_id'}
                testID="Safety Audits Dropdown"
              />
            </Card>
            {activeCompany?.hasLockoutTagout ? (
              <Card
                name="lotoAudits"
                title="Lockout/Tagout Procedures"
                showHeader
              >
                <Dropdown
                  multi
                  placeholder="Choose one or more"
                  options={lotoTemplateDropdown}
                  disabled={!canSubmit && isCreating}
                  currentValue={linkedLOTOTemplates}
                  onChange={v => handleAnswer({ linkedLOTOTemplates: v })}
                  searchable
                  selectButtons
                  valueKey={'_id'}
                  testID="Lockout/Tagout Dropdown"
                />
              </Card>
            ) : null}

            <Card name="trainings" title="Trainings" showHeader>
              <Dropdown
                multi
                placeholder="Choose one or more"
                options={trainingTemplateDropdown}
                disabled={!canSubmit && isCreating}
                currentValue={linkedTrainingTemplates}
                onChange={v => handleAnswer({ linkedTrainingTemplates: v })}
                searchable
                selectButtons
                valueKey={'_id'}
                testID="Trainings Dropdown"
              />
            </Card>
          </>
        ) : (
          <div
            className={styles.AssignablesList}
            id="assignables"
            title="Assignables"
          >
            <TypeSelection
              className={styles.typeSelection}
              selected={view}
              selectedArray={[
                'Safety Audits',
                activeCompany?.hasLockoutTagout
                  ? 'Lockout/Tagout Procedures'
                  : null,
                'Trainings',
                'Tasks',
                'Documents'
              ]}
              onClick={setView}
            />
            {view === 'Tasks' && (
              <EquipmentTaskList
                setActiveTask={task => dispatch(setActiveTaskRequest(task))}
                loadTask={t => dispatch(loadTask(t))}
                activeEquipment={activeEquipment}
                cardClassName={styles.equipmentAttachmentLists}
                childGroupDropdown={childGroupDropdown}
                childProjectDropdown={childProjectDropdown()}
                activeGroupId={activeLocation?._id}
                activeProjectId={activeProject?._id}
                availableGroups={availableAssignmentGroups}
                disabled={hiddenOrArchived}
              />
            )}
            {view === 'Documents' && (
              <EquipmentDocumentsList
                data={companyDocuments.filter(r => {
                  return (isEditing
                    ? linkedDocuments
                    : activeEquipment.linkedDocuments
                  )
                    .map(doc => doc._id)
                    .includes(r._id);
                })}
                cardClassName={styles.equipmentAttachmentLists}
                createDocument={() => {
                  resetSIForm();
                  setUpdatingSI(true);
                  setProcedureModal(true);
                }}
                setDocument={row => {
                  setSIForm(row);
                  setActiveSI(row);
                  setProcedureModal(true);
                }}
                disabled={activeEquipment?.isArchived}
              />
            )}
            {view === 'Safety Audits' && (
              <EquipmentAuditList
                activeEquipment={activeEquipment}
                cardClassName={styles.equipmentAttachmentLists}
                childGroupDropdown={childGroupDropdown}
                childProjectDropdown={childProjectDropdown()}
                activeGroupId={activeLocation?._id}
                activeProjectId={activeProject?._id}
                type="safety"
                availableGroups={availableAssignmentGroups}
                disabled={hiddenOrArchived}
              />
            )}
            {view === 'Lockout/Tagout Procedures' && (
              <EquipmentAuditList
                activeEquipment={activeEquipment}
                cardClassName={styles.equipmentAttachmentLists}
                childGroupDropdown={childGroupDropdown}
                childProjectDropdown={childProjectDropdown()}
                activeGroupId={activeLocation?._id}
                activeProjectId={activeProject?._id}
                type="loto"
                availableGroups={availableAssignmentGroups}
                disabled={hiddenOrArchived}
              />
            )}
            {view === 'Trainings' && (
              <EquipmentTrainingList
                activeEquipment={activeEquipment}
                cardClassName={styles.equipmentAttachmentLists}
                childGroupDropdown={childGroupDropdown}
                childProjectDropdown={childProjectDropdown()}
                disabled={hiddenOrArchived}
              />
            )}
          </div>
        )}
      </HeaderAndFooter>

      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setUnsavedModal(false)}
        submitActions={history.goBack}
        savingWhat="a page"
      />
      <Modal
        submitActions={() => (SIForm.label ? createSafetyDocument() : null)}
        hideButtons={!updatingSI}
        title={
          (activeSI ? (updatingSI ? 'Update' : 'View') : 'Add') +
          ' Safety Procedure'
        }
        titleClassName="greenHeader"
        wide
        submitButtonColor="green"
        testID="modal"
        isOpen={safetyProcedureModal}
        onRequestClose={() => {
          resetSIForm();
          setProcedureModal(false);
          setUpdatingSI(false);
        }}
        onMouseEnter={() => setDocMissingRequired(!SIForm.label)}
        submitTooltip={docMissingRequired ? 'Document Title Required' : null}
      >
        <EquipmentDocumentForm
          activeCompany={activeCompany}
          onDelete={value => {
            deleteDocument(value).then(() => {
              updateDocumentList();
              resetSIForm();
              setProcedureModal(false);
            });
          }}
          documentFolders={documentFolders}
          activeSI={activeSI}
          siForm={SIForm}
          setSIForm={setSIForm}
          addedAttachments={addedAttachments}
          updating={updatingSI}
          setUpdating={setUpdatingSI}
          groupDropdown={childGroupDropdown}
          projectDropdown={childProjectDropdown()}
          disabled={activeEquipment?.isArchived}
          missingRequired={docMissingRequired}
        />
      </Modal>

      <Modal
        title="Delete Equipment"
        titleClassName="redHeader"
        isOpen={openDeleteEquipmentModal}
        submitButtonColor="red"
        submitButtonText="Delete Equipment"
        onRequestClose={() => setDeleteModal(false)}
        submitActions={() =>
          deleteEquipment(activeEquipment).then(() => {
            history.push('/app/equipmentList/');
            dispatch(
              addMessage({ error: false, message: 'Deleted Successfully' })
            );
          })
        }
      >
        <div className="reportSectionContainer-emailAssigneeText">
          <div style={{ color: '#c74846', fontWeight: 'bold' }}>
            Deleting an Equipment is permanent. No one will be able to view or
            edit this specific Equipment again. This will delete any associated
            tasks, audits, and trainings.
          </div>
          A new Equipment that is similar or identical can still be created.
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Equipment"
        isEditing={isEditing}
      />
    </>
  );
}

import React from 'react';

import Card from '../../Card';
import List from '../../List';

export default function EquipmentTaskList(props) {
  const {
    setActiveTask,
    loadTask,
    activeEquipment,
    cardClassName,
    childGroupDropdown,
    childProjectDropdown,
    activeGroupId,
    activeProjectId,
    availableGroups,
    disabled
  } = props;

  const activeSettings = [
    { key: 'label', label: 'Title' },
    { key: 'description' },
    {
      key: 'assignedTo',
      datatype: 'users',
      label: 'Assignee(s)'
    },
    {
      key: 'dueDate',
      datatype: 'futureDate',
      label: 'Due Date'
    },
    {
      key: 'isCompleted',
      label: 'Status',
      accessor: r => (r?.isCompleted ? 'Completed' : 'Open'),
      enum: [
        { value: 'Open', label: 'Open' },
        { value: 'Completed', label: 'Completed' }
      ]
    }
  ];

  return (
    <Card
      title="Tasks"
      testID="Tasks Card"
      className={cardClassName}
      rightButton={{
        text: 'Create Task',
        color: 'blue',
        testID: 'Create Task',
        onClick: () =>
          setActiveTask({
            ownerType: 'equipment',
            ownerId: activeEquipment._id,
            groupId:
              availableGroups?.length === 1
                ? availableGroups[0]
                : activeGroupId ?? '',
            projectId:
              activeEquipment?.groupIds.length === 1 &&
              activeEquipment?.projectIds?.length === 1
                ? activeEquipment?.projectIds[0]
                : activeProjectId ?? '',
            schedule: 'immediate',
            equipmentGroupDropdown: childGroupDropdown,
            equipmentProjectDropdown: childProjectDropdown
          }),
        disabled: disabled
      }}
      showHeader
    >
      <List
        saveKey="equipmentActiveTaskList"
        rowClick={row => loadTask(row._id)}
        dataIsHash
        data={activeEquipment?.linkedTasks?.filter(task => !task.isDeleted)}
        settings={activeSettings}
      />
    </Card>
  );
}

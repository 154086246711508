import React from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer
} from 'recharts';
import ReactTooltip from 'react-tooltip';

import { InputRow } from '../inputs';
import Button from '../inputs/Button';
import classnames from 'classnames/bind';
import Loading from '../Loading';
import Card from '../Card';
import svgMapper from '../../utils/svgMapper';
import {
  REACTIVE_REPORT_TYPES,
  ALL_REPORT_TYPES
} from '../../constants/constants';
import { useCardData } from './useCardData';
import { useActiveHeirarchy } from '../../utils/useActiveHeirarchy';

import styles from './headerCards.module.scss';
const bStyles = classnames.bind(styles);

const periods = [
  { name: 'This Week', value: 'WTD' },
  { name: 'This Month', value: 'MTD' },
  { name: 'This Quarter', value: 'QTD' },
  { name: 'This Year', value: 'YTD' }
];

let ItemGraphCard = ({ card, className }) => {
  const [selected, setSelected] = React.useState('WTD');
  const data = useCardData(card, selected);
  const { company, location, project } = useActiveHeirarchy();
  let sdata = [];

  Object.keys(data?.incidents || {}).forEach(key => {
    sdata.push({
      count: data.incidents[key],
      key
    });
  });

  Object.keys(data?.observations || {}).forEach((key, i) => {
    if (!data.observations[key]) return;

    sdata.push({
      count: data.observations[key],
      key,
      observation: true
    });
  });

  const getReportTypes = () => {
    let output = [];
    ALL_REPORT_TYPES.forEach(element =>
      output.push(
        <ReactTooltip
          className="svgHover"
          id={'ItemGraphCard-Tooltip-' + element.value}
        >
          {element.label}
        </ReactTooltip>
      )
    );
    return output;
  };

  const handleTick = v => {
    if (!v.payload?.value) return;
    switch (v.payload.value) {
      case 'bo':
        v.payload.value = '12';
        break;
      case 'ra':
        v.payload.value = '8';
        break;
      case 'safetyWalk':
        v.payload.value = '10';
        break;
      case 's4':
        v.payload.value = '11';
        break;
      default:
        break;
    }
    return (
      <foreignObject x={v.x - 15} y={v.y} width="35" height="35">
        <div
          data-for={'ItemGraphCard-Tooltip-' + v.payload.value}
          data-tip="svgHover"
        >
          {svgMapper(v.payload.value)}
        </div>
      </foreignObject>
    );
  };

  const where = project?.name || location?.name || company?.name;

  return (
    <Card
      className={classnames(styles.card, className)}
      title={`${where} - ${card?.name}`}
      showHeader
      wide
    >
      {data === undefined ? (
        <Loading />
      ) : sdata.length === 0 ? (
        <div className={styles.empty}>No Items Available</div>
      ) : (
        <>
          <ResponsiveContainer
            width="80%"
            height={300}
            className={styles.chartContainer}
          >
            <BarChart data={sdata} className={styles.itemGraph}>
              <Tooltip
                labelFormatter={l => {
                  let type = REACTIVE_REPORT_TYPES.find(
                    t => t.value === parseInt(l, 10)
                  );
                  if (type?.label) return type.label;

                  switch (l) {
                    case 'safetyWalk':
                      return 'Safety Walk';
                    case 'bo':
                      return 'Behavior Observation';
                    case 's4':
                      return 'See Something Say Something';
                    case 'ra':
                      return 'Safety Audit';
                    default:
                      return l;
                  }
                }}
              />
              <XAxis dataKey="key" tick={handleTick} />
              <YAxis allowDecimals={false} />
              <Bar dataKey="count">
                {sdata.map((e, i) => (
                  <Cell
                    key={i}
                    className={
                      e.observation
                        ? styles.observationCell
                        : styles.incidentCell
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          {getReportTypes()}
        </>
      )}
      <InputRow className={styles.timeFrame} center>
        {periods.map(p => (
          <Button
            className={bStyles({
              button: true,
              selected: selected === p.value
            })}
            key={p.value}
            onClick={() => {
              setSelected(p.value);
            }}
            text={p.name}
            color="white"
          />
        ))}
      </InputRow>
    </Card>
  );
};

export { ItemGraphCard };
export default ItemGraphCard;

import React from 'react';

import { Checkbox, EmployeeDropdown, TwoColumn } from '../../inputs';
import Card from '../../Card';
import { DatePicker } from '../../inputs/DateTimePicker';
import Signature from '../../inputs/Signature';
import Textbox from '../../inputs/Textbox';

export default function RecurrenceCard({
  onChange,
  employeeName,
  dateOfRecurrence,
  dateReported,
  datePhysicianAppt,
  physicianInfo,
  incidentDesc,
  reinjuryOccurred,
  injuryHistory,
  disabled,
  dateOfOriginalInjury,
  timeNotDetermined,
  name,
  missingRequired,
  isNa,
  incidentId,
  employeeSignature,
  availableApprovers,
  supervisorId,
  supervisorSignature,
  supervisorCanSign,
  group,
  employeeId,
  disableNa,
  _id,
  changeApprovers,
  changeEmployees,
  needsToCreate,
  handleRemoveForm
}) {
  const renderInfoForIncident = (
    <>
      <Signature
        fieldLabel="Employee Signature"
        currentValue={employeeSignature}
        onChange={v => onChange({ employeeSignature: v })}
        ownerType="incidentCc"
        ownerId={_id}
        isRequired
        disabled={disabled || employeeSignature}
        touched={missingRequired && !employeeSignature}
      />
      <EmployeeDropdown
        fieldLabel="Select Your Supervisor"
        onChange={v => onChange({ supervisorId: v, supervisorSignature: null })}
        currentValue={supervisorId}
        disabled={
          isNa ? true : !changeApprovers && (disabled || supervisorSignature)
        }
        searchable
        name="approver"
        required
        employees={availableApprovers}
        touched={missingRequired && !supervisorId}
      />
      {supervisorSignature || supervisorCanSign ? (
        <Signature
          fieldLabel="Supervisor Signature"
          currentValue={supervisorSignature}
          onChange={v => onChange({ supervisorSignature: v })}
          ownerType="incidentCc"
          ownerId={_id}
          disabled={!supervisorCanSign}
          isRequired
          touched={missingRequired && !supervisorSignature}
        />
      ) : null}
    </>
  );
  return (
    <Card
      name={name}
      showHeader
      title="Recurrence, Relapse During Recovery & Continuing Treatment"
      wide
      rightButton={
        incidentId ? (
          needsToCreate ? (
            <img
              src={require('../../../assets/images/remove.png')}
              alt="remove row"
              onClick={handleRemoveForm}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <Checkbox
              fieldLabel="Not Applicable"
              currentValue={isNa}
              onChange={v => onChange({ isNa: v })}
              disabled={disableNa}
            />
          )
        ) : null
      }
    >
      <TwoColumn>
        {incidentId ? (
          <EmployeeDropdown
            fieldLabel="Employee Name"
            onChange={v => onChange({ employeeId: v, employeeSignature: null })}
            currentValue={employeeId}
            disabled={
              isNa ? true : !changeEmployees && (disabled || employeeSignature)
            }
            searchable
            name="approver"
            group={group}
            isRequired
            touched={missingRequired && !employeeId}
          />
        ) : (
          <Textbox
            fieldLabel="Employee Name"
            placeholder="ex. Jane Doe"
            isRequired
            onChange={v => onChange({ employeeName: v })}
            currentValue={employeeName}
            disabled={disabled}
            touched={missingRequired && !employeeName}
            error
          />
        )}
        <DatePicker
          pickTime
          fieldLabel="Date of Recurrence"
          isRequired
          disabled={disabled}
          onChange={values => onChange({ dateOfRecurrence: values })}
          currentValue={dateOfRecurrence}
          name="dateOfRecurrence"
          touched={missingRequired && !dateOfRecurrence}
        />
        <DatePicker
          pickTime
          fieldLabel="Date Reported"
          isRequired
          disabled={disabled}
          onChange={value => onChange({ dateReported: value })}
          currentValue={dateReported}
          name="dateReported"
          touched={missingRequired && !dateReported}
        />
        <DatePicker
          pickTime
          fieldLabel="Date of Physician Appointment"
          isRequired
          disabled={disabled}
          onChange={value => onChange({ datePhysicianAppt: value })}
          currentValue={datePhysicianAppt}
          name="physicianAppointment"
          touched={missingRequired && !datePhysicianAppt}
        />
        <DatePicker
          pickTime
          fieldLabel="Date of Original Injury"
          isRequired
          disabled={disabled}
          onChange={value =>
            onChange({
              dateOfOriginalInjury: value,
              timeNotDetermined: false
            })
          }
          currentValue={dateOfOriginalInjury}
          showNA
          isNA={timeNotDetermined}
          onNAChange={() =>
            onChange({
              timeNotDetermined: !timeNotDetermined,
              dateOfOriginalInjury: ''
            })
          }
          name="originalInjury"
          touched={missingRequired && !dateOfOriginalInjury}
        />
      </TwoColumn>
      <Textbox
        fieldLabel="Physician Name and Information"
        type="textarea"
        placeholder="Name, Specialization, Office Address, Contact Info"
        onChange={v => onChange({ physicianInfo: v })}
        currentValue={physicianInfo}
        disabled={disabled}
      />
      <Textbox
        fieldLabel="Describe the incident that caused this re-injury (include body parts affected)"
        placeholder="Type here"
        type="textarea"
        isRequired
        onChange={v => onChange({ incidentDesc: v })}
        currentValue={incidentDesc}
        disabled={disabled}
        touched={missingRequired && !incidentDesc}
      />
      <Textbox
        fieldLabel="Where the re-injury occurred"
        placeholder="(Work, Home, Grocery Store, etc.)"
        isRequired
        onChange={v => onChange({ reinjuryOccurred: v })}
        currentValue={reinjuryOccurred}
        disabled={disabled}
        touched={missingRequired && !reinjuryOccurred}
      />
      <Textbox
        fieldLabel="Describe, in detail, the history of problems related to the injury"
        placeholder="Type here"
        type="textarea"
        isRequired
        onChange={v => onChange({ injuryHistory: v })}
        currentValue={injuryHistory}
        disabled={disabled}
        touched={missingRequired && !injuryHistory}
      />
      {incidentId ? renderInfoForIncident : null}
    </Card>
  );
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import history from '../../history';
import {
  downloadUserQualifications,
  fetchDocumentsByType,
  fetchEmployeeQualifications,
  archiveMany,
  fetchUserListFilter,
  updateUserListFilter
} from '../../api/v4';
import {
  getActiveFolderSelector,
  getDocumentFoldersSelector
} from '../../selectors/documents';
import { getLoggedInUser } from '../../selectors/users';
import { addDocumentsToFolderRequest } from '../../actions/documents';
import { usePrevious } from '../../utils/hooks';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import AddToFolderModal from '../../components/Modal/addToFolderModal';
import AnalyticsPeriodPicker from '../AnalyticsPeriodPicker';
import DocumentFolder from '../../components/DocumentFolder';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import List from '../../components/List';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import TypeSelection from '../TypeSelection';

export default function QualificationList(props) {
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [addToFolderModal, setAddToFolderModal] = useState(false);
  const [folderId, setFolderId] = useState('');
  const [documentIds, setDocumentIds] = useState([]);
  const [listType, setListType] = useState('Active');
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();

  const user = useSelector(getLoggedInUser);
  const { location, project } = useActiveHeirarchy();
  const folderList = useSelector(getDocumentFoldersSelector);
  const activeFolder = useSelector(getActiveFolderSelector);
  const prevPeriod = usePrevious(currentPeriod);
  const prevFolder = usePrevious(activeFolder);
  const prevType = usePrevious(listType);
  const { employeeId } = props;

  useEffect(() => {
    fetchUserListFilter(user._id, 'qualification').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });
  }, [user._id]);

  useEffect(() => {
    if (
      currentPeriod &&
      (currentPeriod.mode !== 'absolute' ||
        (currentPeriod.startDate && currentPeriod.endDate)) &&
      (!isEqual(prevPeriod, currentPeriod) ||
        !isEqual(prevFolder, activeFolder) ||
        !isEqual(prevType, listType))
    ) {
      if (employeeId) {
        fetchEmployeeQualifications(employeeId).then(response => {
          setDocuments(response?.documents);
        });
      } else {
        fetchDocumentsByType('Qualification', activeFolder._id, {
          ...currentPeriod,
          selectedType: listType
        }).then(setDocuments);
      }
    }
  }, [
    employeeId,
    activeFolder,
    prevFolder,
    prevPeriod,
    currentPeriod,
    listType,
    prevType
  ]);

  const handleAddMultipleToFolders = () => {
    let addDocsToFolder = [];
    documents.forEach(document => {
      if (documentIds.indexOf(document._id) > -1) {
        addDocsToFolder.push(document._id);
      }
    });

    dispatch(
      addDocumentsToFolderRequest({
        documents: addDocsToFolder,
        documentFolderId: folderId,
        type: 'documents'
      })
    );
    setAddToFolderModal(false);
    setDocumentIds([]);
  };

  const handleArchive = ids => {
    if (!ids.length) return;
    archiveMany({
      type: 'Qualification',
      ids: ids,
      isArchived: listType === 'Active'
    }).then(() =>
      fetchDocumentsByType('Qualification', activeFolder._id, {
        ...currentPeriod,
        selectedType: listType
      }).then(setDocuments)
    );
  };

  const columns = [
    { key: 'title', accessor: r => r.documentData?.qualificationType?.label },
    {
      key: 'employeeId',
      label: 'Employee Name',
      datatype: 'users',
      accessor: r => r.documentData.employeeId
    },
    {
      key: 'scheduledDate',
      datatype: 'futureDate',
      accessor: r => r.documentData.scheduledDate
    },
    { key: 'expires', datatype: 'futureDate' },
    { key: 'attachments', label: 'Certificate', datatype: 'attachments' }
  ].concat(
    employeeId
      ? [
          {
            key: 'isArchived',
            label: 'Archived',
            accessor: q => (q.isArchived ? 'Yes' : 'No'),
            enum: ['Yes', 'No']
          }
        ]
      : []
  );

  if (!location) {
    columns.splice(1, 0, {
      key: 'groupIds',
      label: 'Group/Est.',
      datatype: 'groups'
    });
  }

  return employeeId ? (
    <>
      <Header
        pageActionOptions={[
          {
            label: 'Add Qualification',
            visible: !location?.isHidden && !project?.isHidden,
            color: 'greenOutline',
            onClick: () =>
              history.push(`/app/qualificationContainer`, {
                employeeId: employeeId
              })
          },
          {
            label: 'Download Qualifications',
            visible: documents?.length,
            color: 'blueOutline',
            onClick: () =>
              downloadUserQualifications(employeeId, {
                documentIds: documents?.map(d => d._id)
              }).then(response => window.open(response, '_blank'))
          }
        ]}
        hideBoxShadow
      />
      <List
        data={documents}
        dataIsHash
        settings={columns}
        getRowId={r => r._id}
        rowClick={row => history.push(`/app/qualificationContainer/${row._id}`)}
        saveKey="qualificationList"
        actions={[
          {
            color: 'blue',
            label: 'Add to Folder',
            visible: user.accessLevel !== 100,
            onClick: ids => {
              setAddToFolderModal(true);
              setDocumentIds(ids);
            }
          }
        ]}
      />
    </>
  ) : (
    <HeaderAndFooter
      Header={
        <Header
          title="Qualifications"
          rightButtons={{
            color: 'green',
            text: 'Add Qualification',
            visible:
              user.accessLevel !== 100 &&
              !location?.isHidden &&
              !project?.isHidden,
            onClick: () => history.push(`/app/qualificationContainer`)
          }}
        />
      }
    >
      <ScrollToTopOnMount />
      {user.accessLevel > 400 ? (
        <TypeSelection
          selectedArray={['Active', 'Archived']}
          onClick={setListType}
          selected={listType}
        />
      ) : null}
      <AnalyticsPeriodPicker
        period={currentPeriod}
        onChange={setCurrentPeriod}
        onSave={v =>
          updateUserListFilter({
            empId: user._id,
            type: 'qualification',
            period: v
          }).then(() => setEmpPeriod(v))
        }
        allowAll
        savedPeriod={empPeriod}
        listPicker
      />
      <DocumentFolder content={documents} docType="Qualifcation" />
      <List
        data={documents}
        dataIsHash
        settings={columns}
        getRowId={r => r._id}
        rowClick={row => history.push(`/app/qualificationContainer/${row._id}`)}
        saveKey="qualificationList"
        actions={[
          {
            color: 'blue',
            label: 'Add to Folder',
            visible: user.accessLevel !== 100,
            onClick: ids => {
              setAddToFolderModal(true);
              setDocumentIds(ids);
            }
          },
          {
            color: 'blue',
            label: `${listType === 'Archived' ? 'Un-' : ''}Archive Documents`,
            visible: user.accessLevel > 400,
            onClick: handleArchive
          }
        ]}
      />
      <AddToFolderModal
        isOpen={addToFolderModal}
        folderId={folderId}
        onRequestClose={() => setAddToFolderModal(false)}
        submitActions={() => handleAddMultipleToFolders()}
        folderOptions={folderList?.map(f => ({ value: f._id, label: f?.name }))}
        onChange={setFolderId}
      />
    </HeaderAndFooter>
  );
}

import React from 'react';

import Card from '../../Card';
import List from '../../List';

export const EquipmentDocumentsList = ({
  cardClassName,
  data,
  createDocument,
  setDocument,
  disabled
}) => {
  const activeSettings = [
    { key: 'label', label: 'Title' },
    {
      key: 'currentPriority',
      label: 'Priority',
      accessor: r => r.documentData?.currentPriority,
      enum: [
        { value: 1, label: 'Low' },
        { value: 2, label: 'Medium' },
        { value: 3, label: 'High' }
      ]
    },
    { key: 'attachments', datatype: 'attachments' },
    { key: 'expires', label: 'Expiration Date', datatype: 'futureDate' }
  ];

  return (
    <Card
      title="Documents"
      testID="Documents Card"
      className={cardClassName}
      rightButton={{
        text: 'Upload',
        testID: 'Upload',
        color: 'blue',
        onClick: createDocument,
        disabled: disabled
      }}
      showHeader
    >
      <List
        saveKey="documents"
        rowClick={setDocument}
        data={data}
        dataIsHash
        settings={activeSettings}
      />
    </Card>
  );
};

export default EquipmentDocumentsList;

import React from 'react';
import moment from 'moment';

import Dropdown from '../components/inputs/Dropdown';
import { EmployeeDropdown } from '../components/inputs';
import RadioButtons from '../components/inputs/RadioButtons';
import { DatePicker } from '../components/inputs/DateTimePicker';
import Textbox from '../components/inputs/Textbox';
import UserAccessButtons from '../components/inputs/UserAccessButtons';
import { Checkbox } from '../components/inputs';

export const required = value => {
  if (typeof value === 'string' && !value?.trim()) return 'Required!';
  return value !== undefined && value !== '' && value !== null
    ? undefined
    : 'Required!';
};

export const password = value => {
  if (!value) return 'Required!';
  if (value.includes(' ')) return 'Passwords cannot include spaces';
  return value !== undefined && value !== '' && value !== null
    ? undefined
    : 'Required!';
};

export const dropDownRequired = value => {
  return parseInt(value) >= 0 || value ? undefined : 'Required!';
};

export const booleanRequired = value => {
  return value === true || value === false ? undefined : 'Required!';
};

export const multiSelectRequired = value => {
  if (!value) {
    return 'Required!';
  }
  return value.length > 0 ? undefined : 'Required!';
};

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;

export const numeric = value =>
  value && /[^0-9 ]/i.test(value) ? 'Only number characters' : undefined;

export const alpha = value =>
  value && /[^a-zA-Z ]/i.test(value) ? 'Only alphabet characters' : undefined;

export const commonName = value =>
  value &&
  /[^\0-\x20"',\-.0-9\x41-\x5A\x60-\x7A~\x7F\xC0-\xD6\xD8-\xF6\xF8-\u017E]+ */i.test(
    value
  )
    ? 'Only common name characters'
    : undefined;

export const email = value =>
  value && !/(.+)@(.+){2,}\.(.+){2,}/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const phone = value =>
  value && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(value)
    ? 'Invalid phone number'
    : undefined;

export const validZip = value =>
  value && (/[^0-9]/i.test(value) || value.length !== 5)
    ? `Must be 5 numbers`
    : undefined;

export const validNAICS = value =>
  value && (/[^0-9]/i.test(value) || value.length !== 6)
    ? `Must be 6 numbers`
    : undefined;

export const validSIC = value =>
  value && (/[^0-9]/i.test(value) || value.length !== 4)
    ? `Must be 4 numbers`
    : undefined;

export const isValidDate = value => {
  if (!value) {
    return false;
  }
  if (moment.isMoment(value)) return;

  var pattern = /(0[1-9]|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)(\d{2})/;
  var m = value.match(pattern);
  if (!m) return 'Invalid date';
};

export const notFutureDate = (value, onlyDate = false) => {
  if (onlyDate) {
    return moment(value).isAfter(moment(), 'day')
      ? 'Cannot be a Future Date'
      : undefined;
  }
  return moment(value).isAfter(moment())
    ? 'Cannot be a Future Date'
    : undefined;
};

export const isFutureDate = value =>
  moment(value).isAfter(moment()) ? undefined : 'Must be a Future Date';

export const normalizeLibertyMutualPhone = (value, previousValue) => {
  if (!value) {
    return value;
  }

  let onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.substring(0, 1) === '1') {
    onlyNums = onlyNums.slice(1);
  }

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-';
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 10);
};

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3 && value.charAt(3) !== '-') {
      return onlyNums + '-';
    }
    if (onlyNums.length === 6 && value.charAt(7) !== '-') {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-';
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }
  return (
    onlyNums.slice(0, 3) +
    '-' +
    onlyNums.slice(3, 6) +
    '-' +
    onlyNums.slice(6, 10)
  );
};

export const normalizeSSN = (value, previousValue) => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, '');

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3 && value.charAt(3) !== '-') {
      return onlyNums + '-';
    }
    if (onlyNums.length === 5 && value.charAt(6) !== '-') {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-';
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }
  return (
    onlyNums.slice(0, 3) +
    '-' +
    onlyNums.slice(3, 5) +
    '-' +
    onlyNums.slice(5, 9)
  );
};

export const positiveIntsOnly = value => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, '');

  return onlyNums;
};

export const normalizeFloat = (value, decimals = 2) => {
  const [intPart, decimalPart] = value.split('.');
  if (!decimalPart?.length) return parseInt(intPart);

  const normalInt = intPart.replace(/[^\d]/g, '');
  const normalDecimal = decimalPart.replace(/[^\d]/g, '');
  return parseFloat(`${normalInt}.${normalDecimal}`).toFixed(
    normalDecimal.length > decimals ? decimals : normalDecimal.length
  );
};

export const normalizeWebsite = (value, previousValue) => {
  if (!value) {
    return value;
  }

  if (!previousValue || value.length > previousValue.length) {
    if (/^http:\/\//i.test(value) || /^https:\/\//i.test(value)) {
      return value;
    } else {
      return 'http://' + value;
    }
  }
  return value;
};

export const normalizeTime = value => {
  if (!value) {
    return value;
  }
  let onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length > 2) {
    onlyNums = onlyNums.slice(0, 2) + ':' + onlyNums.slice(2);
  }

  return onlyNums;
};

export const normalizeBwcPolicy = value => {
  if (!value) {
    return value;
  }

  let regexValue = value;

  if (value.length >= 9) {
    return regexValue.slice(0, 8) + '-' + regexValue.slice(9, 12);
  }

  return regexValue;
};

export const isValidObjectId = id => {
  const idRegex = /\d/g;
  const validIdLength = 24;
  return id && idRegex.test(id) && id.length === validIdLength;
};

export const renderField = ({
  input,
  label,
  type,
  disabled,
  requiredLabel,
  placeholder,
  className,
  children,
  testID,
  meta: { touched, error, warning }
}) => (
  <Textbox
    className={className}
    onChange={input.onChange}
    disabled={disabled}
    isRequired={requiredLabel}
    fieldLabel={label}
    type={type}
    touched={touched}
    error={error}
    warning={warning}
    placeholder={placeholder}
    currentValue={input.value}
    testID={testID}
  />
);

export const renderUsernameField = ({
  input,
  label,
  type,
  disabled,
  requiredLabel,
  placeholder,
  className,
  children,
  meta: { touched, error, warning }
}) => (
  <Textbox
    className={className}
    onChange={input.onChange}
    disabled={disabled}
    isRequired={requiredLabel}
    fieldLabel={label}
    type={type}
    touched={touched}
    error={error}
    warning={warning}
    placeholder={placeholder}
    currentValue={input.value}
  />
);

export const renderTextareaField = ({
  input,
  label,
  type,
  disabled,
  requiredLabel,
  placeholder,
  className,
  children,
  meta: { touched, error, warning }
}) => (
  <Textbox
    className={className}
    onChange={input.onChange}
    disabled={disabled}
    isRequired={requiredLabel}
    fieldLabel={label}
    type="textarea"
    touched={touched}
    error={error}
    warning={warning}
    placeholder={placeholder}
    currentValue={input.value}
  />
);

export const renderDropdownField = ({
  input,
  options,
  label,
  disabled,
  requiredLabel,
  placeholder,
  className,
  inputClassName,
  children,
  displayToolTip,
  dropUp,
  searchable,
  testID,
  meta: { touched, error }
}) => (
  <Dropdown
    name={input.name}
    options={options}
    disabled={disabled}
    currentValue={input.value}
    onChange={input.onChange}
    placeholder={placeholder}
    className={className}
    openUp={dropUp}
    searchable={searchable}
    touched={touched}
    error={error}
    isRequired={requiredLabel}
    fieldLabel={label}
    displayTooltip={displayToolTip}
    tooltip={children}
    testID={testID}
  />
);

export const renderEmployeeDropdown = ({
  input,
  label,
  disabled,
  requiredLabel,
  placeholder,
  className,
  children,
  displayToolTip,
  permissions,
  meta: { touched, error, warning }
}) => (
  <EmployeeDropdown
    multi
    name={input.name}
    disabled={disabled}
    currentValue={input.value}
    onChange={input.onChange}
    placeholder={placeholder}
    className={className}
    searchable
    selectButtons
    touched={touched}
    error={error}
    warning={warning}
    isRequired={requiredLabel}
    fieldLabel={label}
    displayTooltip={displayToolTip}
    tooltip={children}
    permissions={permissions}
  />
);

export const renderMultiSelectField = ({
  input,
  label,
  requiredLabel,
  meta: { touched, error, warning },
  ...props
}) => (
  <Dropdown
    multi
    fieldLabel={label}
    isRequired={requiredLabel}
    name={input.name}
    currentValue={input.value}
    onChange={input.onChange}
    touched={touched}
    error={error}
    warning={warning}
    {...props}
  />
);

export const renderDatePicker = ({
  input,
  label,
  disabled,
  requiredLabel,
  meta: { touched, error, warning }
}) => (
  <DatePicker
    {...input}
    currentValue={input.value}
    onChange={value => input.onChange(value)}
    disabled={disabled}
    onBlur={input.onBlur}
    touched={touched}
    error={error}
    warning={warning}
    isRequired={requiredLabel}
    fieldLabel={label}
  />
);

export const renderUserButtons = ({
  input,
  label,
  disabled,
  requiredLabel,
  meta: { touched, error, warning }
}) => (
  <UserAccessButtons
    {...input}
    fieldLabel={label}
    isRequired={requiredLabel}
    currentValue={input.value}
    disabled={disabled}
    onClick={value => input.onChange(value)}
    touched={touched}
    error={error}
    warning={warning}
  />
);

export const renderRadioButtons = ({
  input,
  options,
  name,
  label,
  disabled,
  requiredLabel,
  displayToolTip,
  children,
  meta: { touched, error, warning }
}) => (
  <RadioButtons
    tooltip={children}
    displayTooltip={displayToolTip}
    currentValue={input.value}
    name={input.name}
    isRequired={requiredLabel}
    fieldLabel={label}
    options={options}
    touched={touched}
    error={error}
    warning={warning}
    onChange={input.onChange}
  />
);

export const renderNumberField = ({
  input,
  label,
  disabled,
  className,
  requiredLabel,
  placeholder,
  children,
  displayToolTip,
  testID,
  meta: { touched, error, warning }
}) => (
  <Textbox
    type="number"
    displayTooltip={displayToolTip}
    tooltip={children}
    isRequired={requiredLabel}
    placeholder={placeholder}
    disabled={disabled}
    fieldLabel={label}
    className={className}
    onChange={input.onChange}
    touched={touched}
    error={error}
    warning={warning}
    currentValue={input.value}
    testID={testID}
  />
);

export const renderCheckbox = ({
  children,
  displayToolTip,
  input,
  requiredLabel,
  label,
  options,
  touched,
  error,
  warning
}) => (
  <Checkbox
    tooltip={children}
    displayTooltip={displayToolTip}
    currentValue={input.value}
    name={input.name}
    isRequired={requiredLabel}
    fieldLabel={label}
    options={options}
    touched={touched}
    error={error}
    warning={warning}
    onChange={input.onChange}
  />
);

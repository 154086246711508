import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveCompany, getIsPerrp } from '../../selectors/company';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Card from '../Card';

import './index.css';
import CompanyForm from '../../forms/CompanyForm';
import {
  deleteCompanyRequest,
  updateCompanyRequest
} from '../../actions/company';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments
} from '../../actions/attachments';
import { Button, Dropdown, InputRow, TwoColumn } from '../inputs';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { submit } from 'redux-form';
import BlockText from '../inputs/BlockText';
import {
  BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS,
  CLAIMS_NOTIFICATIONS,
  DOCUMENT_NOTIFICATION_OPTIONS,
  FROI_NOTIFICATION_OPTIONS,
  QUALITY_AUDIT_NOTIFICATION_OPTIONS,
  REPORT_NOTIFICATION_OPTIONS,
  REPORT_NOTIFICATION_TYPES,
  RISK_ASSESSMENT_NOTIFICATION_OPTIONS,
  SAFETY_SUGGESTION_NOTIFICATION_OPTIONS,
  SAFETY_WALK_NOTIFICATION_OPTIONS,
  TASK_NOTIFICATION_OPTIONS,
  TRAINING_NOTIFICATION_OPTIONS,
  USER_ROLES,
  LOCKOUT_TAGOUT_NOTIFICATION_OPTIONS
} from '../../constants/constants';
import { InlineTabs } from '../InlineTabs';
import { getAccessLevel } from '../../selectors/users';
import {
  getCompanyNotifications,
  updateCompanyNotifications
} from '../../api/v4';

export default function CompanyDetails() {
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [editNotifs, setEditNotifs] = useState(false);
  const [selectedTab, setTab] = useState(900);
  const activeCompany = useSelector(getActiveCompany);
  const isPerrp = useSelector(getIsPerrp);
  const accessLevel = useSelector(getAccessLevel);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const [notifications, setNotifications] = useState({
    emailNotificationsActive: false
  });
  const [allNotifications, setAllNotifications] = useState({});

  useEffect(() => {
    getCompanyNotifications(activeCompany._id, true).then(response => {
      setAllNotifications(response);
    });
  }, [activeCompany, editNotifs]);

  useEffect(() => {
    if (allNotifications && selectedTab)
      setNotifications(allNotifications[selectedTab]);
  }, [selectedTab, allNotifications]);

  const handleSaveNotifs = () => {
    updateCompanyNotifications({
      ...allNotifications,
      companyId: activeCompany._id
    }).then(r => {
      setEditNotifs(false);
    });
  };

  const getAnswers = (options, label) => {
    let answers = options?.filter(o => notifications?.[label]?.[o.value]);
    return answers;
  };
  const handleNotificationChange = (values, options, label) => {
    let userNotifications = { ...notifications };
    if (values === 'clearAll') {
      let newAnswer = userNotifications[label]?.filter(
        v => !options.includes(v)
      );
      userNotifications[label] = newAnswer;
    } else if (values === 'selectAll') {
      let answer = [];
      options.forEach(option => {
        answer.push(option.value);
      });
      userNotifications[label] = answer;
    } else if (label === 'groups' || label === 'projects') {
      let answer = [];
      options.forEach(option => {
        if (values?.includes(option.value)) {
          answer.push(option.value);
        }
      });
      userNotifications[label] = answer;
    } else {
      for (let x = 0; x < options.length; x++) {
        const notification = options[x];
        userNotifications[label][notification.value] =
          values.findIndex(value => value === notification.value) > -1;
      }
    }
    setNotifications(userNotifications);
  };

  const tabs = {
    selected: selectedTab,
    onClick: setTab,
    list: USER_ROLES.filter(r => r.value >= 400)
  };

  let reportTypes = REPORT_NOTIFICATION_TYPES;
  if (!activeCompany.showAircraft) {
    reportTypes = REPORT_NOTIFICATION_TYPES.filter(r => !r.showAircraft);
  }
  reportTypes = reportTypes.map(t => ({
    label: t.label,
    fieldValue: t.value,
    options: REPORT_NOTIFICATION_OPTIONS
  }));
  const notificationDropdowns = [
    {
      fieldValue: 'froi',
      label: 'Employee Injury',
      options: FROI_NOTIFICATION_OPTIONS.filter(
        option => option.minAccessLevel <= accessLevel
      ).map(option => {
        if (option.label === 'Marked OSHA Recordable' && isPerrp) {
          return {
            label: 'Marked PERRP Recordable',
            value: option.value
          };
        }

        return option;
      })
    },
    ...reportTypes,
    {
      fieldValue: 'behaviorObservation',
      label: 'Behavior Observations',
      options: BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'safetyAudit',
      label: 'Safety Audits',
      options: RISK_ASSESSMENT_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'qualityAudit',
      label: 'Quality Audits',
      options: QUALITY_AUDIT_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'lotoAudit',
      label: 'Lockout/Tagout Procedures',
      options: LOCKOUT_TAGOUT_NOTIFICATION_OPTIONS,
      hide: !activeCompany.hasLockoutTagout
    },
    {
      fieldValue: 'safetyWalk',
      label: activeCompany.isDash ? 'DASH Report' : 'Safety Walks',
      options: SAFETY_WALK_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'training',
      label: 'Training',
      options: TRAINING_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'document',
      label: 'Documents',
      options: DOCUMENT_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'task',
      label: 'Tasks',
      options: TASK_NOTIFICATION_OPTIONS
    },
    {
      fieldValue: 'safetySuggestions',
      label: 'Safety Suggestions',
      options: SAFETY_SUGGESTION_NOTIFICATION_OPTIONS,
      hide: selectedTab !== 900
    },
    {
      fieldValue: 'claims',
      label: 'Claims',
      options: CLAIMS_NOTIFICATIONS,
      hide: !activeCompany.showClaims
    }
  ];

  return (
    <>
      <Card
        title={editing ? 'Edit Company' : 'Account Details'}
        showHeader
        wide={editing}
        rightButton={
          editing ? (
            <InputRow right>
              <Button
                color="blue"
                text="Update"
                onClick={() => dispatch(submit('CompanyForm'))}
              />
              <Button
                color="white"
                text="Cancel"
                onClick={() => setEditing(false)}
              />
            </InputRow>
          ) : (
            <Button
              color="blue"
              text="Edit"
              onClick={() => setEditing(true)}
              testID="Edit Details"
            />
          )
        }
      >
        <ScrollToTopOnMount />
        {editing ? (
          <CompanyForm
            onSubmit={values =>
              dispatch(updateCompanyRequest(values)) && setEditing(false)
            }
            initialValues={{ ...activeCompany }}
            activeCompany={activeCompany}
            onDelete={() => dispatch(deleteCompanyRequest(activeCompany._id))}
            isUpdate
            addedAttachments={addedAttachments}
            addAttachment={values =>
              dispatch(addUploadedAttachmentsRequest(values))
            }
            removeAttachment={() => dispatch(clearUploadedAttachments())}
          />
        ) : (
          <div className="accountSettings">
            <div className="leftColumn">
              <img
                src={
                  activeCompany.logoUrl ||
                  require('../../assets/images/no-image-placeholder.jpg')
                }
                alt={`${activeCompany.name}'s Logo`}
              />
            </div>

            <div className="rightColumn-company">
              <div className="smallHeader">COMPANY</div>
              <h1 className="companyName">{activeCompany.name}</h1>

              <h4>
                {activeCompany.mainAddress
                  ? `${activeCompany.mainAddress.streetAddress} ${activeCompany.mainAddress.city}, ${activeCompany.mainAddress.stateName} ${activeCompany.mainAddress.zip}`
                  : 'Address not available'}
              </h4>

              <div className="smallHeader-stats">PHONE</div>
              <h5 className="companyName">
                {activeCompany.phoneNumber
                  ? activeCompany.phoneNumber.fullPhoneNumber
                  : 'Not available'}
              </h5>

              <div className="smallHeader-stats">WEBSITE</div>
              {activeCompany.website ? (
                <a
                  href={activeCompany.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {activeCompany.website}
                </a>
              ) : (
                <h5 className="companyName">Not available</h5>
              )}
            </div>
          </div>
        )}
      </Card>
      <Card
        title="Company Notification Settings"
        showHeader
        rightButton={
          editNotifs ? (
            <InputRow right>
              <Button color="blue" text="Update" onClick={handleSaveNotifs} />
              <Button
                color="white"
                text="Cancel"
                onClick={() => setEditNotifs(false)}
              />
            </InputRow>
          ) : (
            <Button
              color="blue"
              text="Edit"
              onClick={() => {
                setEditNotifs(true);
              }}
            />
          )
        }
      >
        <BlockText blockOfText="Users will always receive notifications for Tasks, Safety/Quality Audits, and Trainings assigned to them." />
        <InlineTabs tabs={tabs} />
        <TwoColumn>
          {notificationDropdowns.map(dropdown => (
            <>
              {dropdown.hide ? (
                <></>
              ) : (
                <Dropdown
                  multi
                  fieldLabel={dropdown.label}
                  placeholder="Notify users when..."
                  options={dropdown.options}
                  disabled={!editNotifs}
                  currentValue={getAnswers(
                    dropdown.options,
                    dropdown.fieldValue
                  )}
                  onChange={values =>
                    handleNotificationChange(
                      values,
                      dropdown.options,
                      dropdown.fieldValue
                    )
                  }
                  searchable
                  selectButtons
                />
              )}
            </>
          ))}
        </TwoColumn>
      </Card>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import history from '../../../history';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import {
  createCourseTemplate,
  deleteCourseTemplate,
  getCourseTemplateById,
  getCourseTemplates,
  updateCourseTemplate
} from '../../../api/v4';
import Card from '../../../components/Card';
import {
  Button,
  Checkbox,
  InputRow,
  Textbox
} from '../../../components/inputs';
import { TRAINING_TYPES } from '../../../constants/constants';
import { Rearranger } from '../../../components/Rearranger';
import DeleteTemplateModal from '../../../components/Modal/deleteTemplateModal';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

import styles from './styles.module.scss';

export default function CourseTemplate() {
  const { id, duplicate } = useParams();
  const location = useLocation();

  const [template, setTemplate] = useState({
    title: '',
    instructions: '',
    sequential: false,
    groupIds: [],
    projectIds: [],
    trainings: []
  });
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUnsavedModal, setOpenUnsavedModal] = useState(false);
  const [originalTitle, setOriginalTitle] = useState('');
  const [titleList, setTitleList] = useState([]);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const canSubmit =
    template.title.trim() &&
    !titleList
      .map(t => t.toLowerCase())
      .filter(t => t !== originalTitle.toLowerCase())
      .includes(template.title.trim().toLowerCase()) &&
    template.trainings.length;

  const handleAnswer = answer => {
    setUnsavedChanges(true);
    setTemplate({ ...template, ...answer });
  };

  useEffect(() => {
    getCourseTemplates().then(response =>
      setTitleList(response.map(r => r.title))
    );
    const local = JSON.parse(localStorage.getItem('courseTemplate'));
    if (local) {
      setUnsavedChanges(!!location.state?.newTraining || local.unsavedChanges);
      const newTrainings = (
        local.trainings?.filter(
          t => t.title !== location.state?.newTraining?.title
        ) ?? []
      ).concat(location.state?.newTraining ?? []);
      setTemplate({
        ...local,
        trainings: newTrainings
      });
      if (id) setEditing(true);
      else setCreating(true);
      setOriginalTitle(local.title);
      delete location.state?.newTraining;
      localStorage.removeItem('courseTemplate');
    } else if (id)
      getCourseTemplateById(id).then(r => {
        if (duplicate) {
          delete r._id;
          setTemplate({
            ...{
              title: '',
              instructions: '',
              sequential: false,
              groupIds: [],
              projectIds: [],
              trainings: []
            },
            ...r,
            title: `${r.title} - Copy`
          });
          setCreating(true);
        } else {
          setTemplate({
            ...{
              title: '',
              instructions: '',
              sequential: false,
              groupIds: [],
              projectIds: [],
              trainings: []
            },
            ...r
          });
        }
        if (!duplicate) setOriginalTitle(r.title);
      });
    else {
      setCreating(true);
    }
  }, [id, location.state, duplicate]);

  const handleSubmit = () => {
    if (template._id) {
      updateCourseTemplate({
        ...template,
        trainings: template.trainings?.map(
          tr => tr._id ?? tr.masterTemplateId ?? tr
        )
      }).then(handleBack);
    } else {
      createCourseTemplate(template).then(handleBack);
    }
  };

  const handleBack = () => {
    history.push('/app/courseTemplates');
  };

  const handleArchive = () => {
    updateCourseTemplate({
      ...template,
      trainings: template.trainings?.map(
        tr => tr._id ?? tr.masterTemplateId ?? tr
      ),
      isArchived: !template.isArchived
    }).then(response => {
      if (response.isArchived) handleBack();
      else {
        getCourseTemplates().then(r => setTitleList(r.map(r1 => r1.title)));
        getCourseTemplateById(id).then(r => {
          setTemplate({
            ...{
              title: '',
              instructions: '',
              sequential: false,
              groupIds: [],
              projectIds: [],
              trainings: []
            },
            ...r
          });
        });
        setOriginalTitle(response.title);
        setUnsavedChanges(false);
      }
    });
  };

  const header = (
    <Header
      title="Training Course Template"
      needsSaved={unsavedChanges}
      section={`
        ${creating ? 'Create' : editing ? 'Edit' : 'View'} Template`}
      clickBack={() =>
        unsavedChanges ? setOpenUnsavedModal(true) : handleBack()
      }
      rightButtons={
        editing
          ? {
              text: 'Delete Template',
              onClick: () => setOpenDeleteModal(true),
              color: 'red',
              visible: !creating
            }
          : {
              text: 'Edit Template',
              onClick: () => setEditing(true),
              color: 'blue',
              visible: !creating
            }
      }
      pageActionOptions={
        !creating
          ? [
              {
                label:
                  editing || template?.isArchived
                    ? 'Delete Template'
                    : 'Edit Template',
                onClick: () =>
                  editing || template?.isArchived
                    ? setOpenDeleteModal(true)
                    : setEditing(true),
                color:
                  editing || template?.isArchived
                    ? 'redOutline'
                    : 'blueOutline',
                visible: true
              },
              {
                label: `${template?.isArchived ? 'Un-' : ''}Archive Template`,
                onClick: () => handleArchive(),
                color: 'blueOutline',
                visible: true
              }
            ]
          : null
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit ? setMissingModalOpen(true) : handleSubmit()
      }
      cancelButtonClick={() =>
        unsavedChanges
          ? setOpenUnsavedModal(true)
          : creating
          ? handleBack()
          : setEditing(false)
      }
      editing={editing}
      onMouseEnter={() => setMissingRequired(!canSubmit)}
    />
  );

  const trainingBox = training => (
    <div className={styles.trainingText}>
      <p style={{ fontWeight: 'bold' }}>{training.title}</p>
      <p>
        {TRAINING_TYPES.find(t => t.value === training.type)?.label ??
          'Digital Training'}
      </p>
    </div>
  );

  const titleTaken = titleList
    .map(t => t.toLowerCase())
    .filter(t => t !== originalTitle.toLowerCase())
    .includes(template.title.trim().toLowerCase());

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        showFooter={editing || creating}
      >
        <Card>
          <Textbox
            isRequired
            fieldLabel="Training Course Template Title"
            currentValue={template.title}
            disabled={!editing && !creating}
            onChange={v => handleAnswer({ title: v })}
            touched={titleTaken || (missingRequired && !template.title)}
            error
            errorMessage={titleTaken ? 'That title is already taken' : ''}
          />
          <Textbox
            fieldLabel="Instructions"
            type="textarea"
            disabled={!editing && !creating}
            onChange={v => handleAnswer({ instructions: v })}
            currentValue={template.instructions}
          />
          <Checkbox
            fieldLabel="Release Sequentially"
            currentValue={template.sequential}
            disabled={!editing && !creating}
            onChange={v => handleAnswer({ sequential: v })}
            tooltip="If selected, the trainings will release one after the other. Otherwise trainings will release all at once."
            displayTooltip
          />
          {/* hide until course creating all the trainings is addressed (https://gitlab.com/ireportsource/ireportsource-react-app/-/issues/1162#note_1859805001)*/}
          {/* <Checkbox
            fieldLabel="Immediately Reassign if Assignee(s) fail?"
            currentValue={template.immediatelyReassign}
            displayTooltip
            disabled={!editing && !creating}
            tooltip={
              <div>
                <p>
                  When this is selected, any assignee that fails this course
                  will be automatically reassigned this course.
                </p>
                <p>
                  This course will be reassigned after everyone has completed
                  the course.
                </p>
              </div>
            }
            onChange={value =>
              handleAnswer({
                immediatelyReassign: value
              })
            }
          /> */}
        </Card>
        <InputRow className={styles.overallFooter}>
          {template.trainings?.length ? (
            <Rearranger
              className={styles.trainingCards}
              itemStyles={styles.trainingCard}
              items={template?.trainings ?? []}
              onChange={newTrainings => {
                setUnsavedChanges(true);
                setTemplate({ ...template, trainings: [...newTrainings] });
              }}
              disabled={!editing && !creating}
              renderItem={trainingBox}
              hideDuplicate
              allowEdit
              inline
              handleEditRow={training => {
                localStorage.setItem(
                  'courseTemplate',
                  JSON.stringify(template)
                );
                localStorage.setItem(
                  'trainingTemplate',
                  JSON.stringify({ ...training, stage: 0 })
                );
                history.push({
                  pathname: `/app/courseTemplates/addTraining`,
                  state: {
                    fromScreen: `/app/courseTemplates/view/${id ?? ''}`,
                    creating,
                    excludeTemplates: template.trainings
                      .map(t => t._id ?? t.masterTemplateId ?? t)
                      .filter(
                        t =>
                          t !==
                          (
                            training._id ??
                            training.masterTemplateId ??
                            training
                          ).toString()
                      ),
                    invalidNames: template.trainings
                      .filter(t => t.title !== training.title)
                      .map(t => t.title.trim()),
                    template,
                    fromTemplate: true
                  }
                });
              }}
            />
          ) : null}

          {editing || creating ? (
            <Button
              color="blue"
              text="Add Training Template"
              onClick={() => {
                localStorage.setItem(
                  'courseTemplate',
                  JSON.stringify({
                    ...template,
                    unsavedChanges: unsavedChanges
                  })
                );
                history.push({
                  pathname: `/app/courseTemplates/addTraining`,
                  state: {
                    fromScreen: `/app/courseTemplates/view/${id ?? ''}`,
                    creating,
                    excludeTemplates: template.trainings.map(
                      t => t._id ?? t.masterTemplateId ?? t
                    ),
                    invalidNames: template.trainings.map(t =>
                      t.title.toLowerCase().trim()
                    ),
                    fromTemplate: true
                  }
                });
              }}
              disabled={!template.title}
              inputClassName={
                missingRequired && !template.trainings?.length && template.title
                  ? styles.trainingRequiredButton
                  : ''
              }
            />
          ) : null}
        </InputRow>
      </HeaderAndFooter>
      <DeleteTemplateModal
        isOpen={openDeleteModal}
        deletingWhat="Course"
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={() => {
          deleteCourseTemplate(template).then(handleBack);
        }}
      ></DeleteTemplateModal>
      <SaveChangesModal
        isOpen={openUnsavedModal}
        savingWhat="Course Template"
        onRequestClose={() => setOpenUnsavedModal(false)}
        submitActions={handleBack}
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        subject="Template"
        onRequestClose={() => setMissingModalOpen(false)}
        isEditing={editing}
      />
    </>
  );
}

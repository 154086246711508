import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchSafetySuggestion,
  createSafetySuggestion,
  updateSafetySuggestion,
  uploadAttachment,
  fetchTaskByOwnerId
} from '../../../api/v4';
import { addMessage } from '../../../actions/messages';
import history from '../../../history';
import { getMyUserId, getAccessLevel } from '../../../selectors/users';
import { formatFiles } from '../../../utils/attachmentSelector';
import useActiveHeirarchy from '../../../utils/useActiveHeirarchy';
import AttachmentUploadCard from '../../../components/AttachmentUploadCard';
import Header from '../../../components/Header';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import { SaveCancelFooter } from '../../../components/Footer';
import Card from '../../../components/Card';
import HierarchySelector from '../../../components/HierarchySelector';
import { Textbox, Input } from '../../../components/inputs';
import RadioButtons from '../../../components/inputs/RadioButtons';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import LeftNav from '../../../components/LeftNav';
import StageSelector from '../../../components/StageSelector';
import AssignCorrectiveActionButton from '../../../components/inputs/AssignCorrectiveActionButton';
import Modal from '../../../components/Modal';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

import styles from './page.module.scss';

function SafetySuggestionTemplate() {
  const { safetySuggestionId } = useParams();
  const dispatch = useDispatch();
  const { company, location, project } = useActiveHeirarchy();
  const [isCreating, setIsCreating] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [photosToUpload, setPhotosToUpload] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [createdModal, setCreatedModal] = useState(false);
  const [safetySuggestion, setSafetySuggestion] = useState({});
  const [isClosed, setIsClosed] = useState(false);
  const [openSubmitResponseModal, setOpenSubmitResponseModal] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const {
    groupId,
    projectId,
    hazardDescription,
    suggestionDescription,
    suggestionImplemented,
    explanation,
    isArchived
  } = safetySuggestion;
  const userId = useSelector(getMyUserId);
  const accessLevel = useSelector(getAccessLevel);

  const group = company.groups?.find(g => g._id === groupId);
  const area = group?.projects?.find(p => p._id === projectId);

  const hiddenOrArchived = group?.isHidden || area?.isHidden || isArchived;

  const handleAnswer = value => {
    setSafetySuggestion({ ...safetySuggestion, ...value });
    setHasUnsavedChanges(true);
  };

  const handleBack = () => {
    if (hasUnsavedChanges) {
      setOpenUnsavedChangesModal(true);
    } else {
      history.push('/app/safetySuggestions/list');
    }
    sessionStorage.removeItem('suggestion');
  };

  const handleAddAttachments = attachments => {
    let data = formatFiles(attachments);
    uploadAttachment(data, {
      ownerType: 'safetySuggestion',
      isSignature: false
    })
      .then(res => {
        if (res === 'No file object attached') {
          return;
        }

        const attachmentIds = [...res?.map(a => a._id)];

        if (isCreating) {
          createSuggestion({
            ...safetySuggestion,
            attachments: attachmentIds
          });
        }
      })
      .catch(e => {
        dispatch(
          addMessage({
            error: true,
            message: e.message
          })
        );
      });
  };

  const handleSubmit = async () => {
    if (safetySuggestionId) {
      setOpenSubmitResponseModal(true);
    } else {
      if (filesToUpload.length > 0) {
        await handleAddAttachments(filesToUpload);
      } else {
        createSuggestion(safetySuggestion);
      }
    }
  };

  const createSuggestion = suggestion => {
    createSafetySuggestion({
      ...suggestion
    }).then(response => {
      history.push('/app/safetySuggestions/' + response._id);
      setCreatedModal(true);
      setSafetySuggestion(response);
    });
  };

  const createResponse = (response, stage) => {
    updateSafetySuggestion({
      ...response,
      stage: stage
    }).then(() => {
      history.push('/app/safetySuggestions/list');
    });
  };

  const handleArchive = () => {
    updateSafetySuggestion({
      ...safetySuggestion,
      isArchived: !safetySuggestion?.isArchived
    }).then(response => {
      if (response.isArchived) history.push('/app/safetySuggestions/list');
      // need to do the fetch because these are anonymous and that gets handled in the backend or else it won't set correctly
      else
        fetchSafetySuggestion(safetySuggestionId).then(r => {
          setSafetySuggestion(r);
          setIsClosed(r.stage === 'Closed');
        });
    });
  };

  const canSubmit =
    groupId?.length &&
    (hazardDescription?.trim().length > 3 ||
      suggestionDescription?.trim().length > 3);

  const canRespond =
    !hiddenOrArchived &&
    userId &&
    safetySuggestion?.creatorId &&
    userId !== safetySuggestion?.creatorId &&
    accessLevel >= 500;

  const canViewResponse =
    (!isCreating && canRespond) ||
    (accessLevel < 500 &&
      safetySuggestion.stage &&
      safetySuggestion.stage !== 'Open');

  useEffect(() => {
    setSafetySuggestion({
      groupId: location?._id,
      projectId: project?._id,
      companyId: company?._id
    });
  }, [location, project, company]);

  useEffect(() => {
    if (safetySuggestionId) {
      setIsCreating(false);
      if (sessionStorage.getItem('suggestion')) {
        const storedSuggestion = JSON.parse(
          sessionStorage.getItem('suggestion')
        );
        fetchTaskByOwnerId(safetySuggestionId).then(r => {
          setSafetySuggestion({
            ...storedSuggestion.safetySuggestion,
            task: r[0]
          });
        });

        sessionStorage.removeItem('suggestion');
      } else {
        fetchSafetySuggestion(safetySuggestionId).then(r => {
          setSafetySuggestion(r);
          setIsClosed(r.stage === 'Closed');
        });
      }
    }
  }, [safetySuggestionId]);

  const center = (
    <StageSelector
      options={[
        { label: 'Open', value: 'Open' },
        { label: 'Under Review', value: 'Under Review' },
        { label: 'Closed', value: 'Closed' }
      ]}
      currentValue={safetySuggestion.stage}
      onChange={s => {
        updateSafetySuggestion({
          _id: safetySuggestion._id,
          stage: s.value,
          explanation: safetySuggestion.explanation,
          suggestionImplemented: safetySuggestion.suggestionImplemented
        }).then(r => {
          setSafetySuggestion({ ...safetySuggestion, stage: r.stage });
          setIsClosed(r.stage === 'Closed');
          setHasUnsavedChanges(false);
        });
      }}
      label="Stage"
      bareValues={false}
      disabled={
        hiddenOrArchived ||
        accessLevel < 500 ||
        userId === safetySuggestion?.creatorId ||
        safetySuggestion?.isArchived
      }
    />
  );

  const header = (
    <Header
      title={isCreating ? 'Create Safety Suggestion' : 'Safety Suggestion'}
      clickBack={handleBack}
      section={!isCreating && safetySuggestion?.safetySuggestionId}
      center={!isCreating && center}
      pageActionOptions={
        accessLevel > 400 && !isCreating
          ? [
              {
                label: `${
                  safetySuggestion?.isArchived ? 'Un-' : ''
                }Archive Safety Suggestion`,
                color: 'blueOutline',
                onClick: () => handleArchive(),
                visible: true
              }
            ]
          : null
      }
    />
  );
  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit ? setMissingModalOpen(true) : handleSubmit()
      }
      cancelButtonClick={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : history.goBack()
      }
      editing={canRespond}
      onMouseEnter={() => setMissingRequired(!canSubmit)}
    />
  );

  const leftNavItemsViewing = [
    { label: 'Safety Suggestion', id: 'safetySuggestion' },
    { label: 'Attachments', id: 'attachments' }
  ];

  if (canRespond || safetySuggestion?.stage === 'Closed') {
    leftNavItemsViewing.push({ label: 'Response', id: 'response' });
  }

  const leftNavViewing = <LeftNav items={leftNavItemsViewing} />;

  const leftNavItems = [
    { label: 'Instructions', id: 'instructions' },
    { label: 'Safety Suggestion', id: 'safetySuggestion' },
    { label: 'Attachments', id: 'attachments' }
  ];
  const leftNav = <LeftNav items={leftNavItems} />;

  const radioOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const responseDisabled =
    isClosed || accessLevel < 500 || safetySuggestion?.isArchived;

  return (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={safetySuggestion?.isArchived ? null : footer}
        Left={isCreating ? leftNav : leftNavViewing}
        showFooter={(!isClosed && canRespond) || isCreating}
      >
        {isCreating && (
          <Card showHeader title="Instructions" name="instructions">
            <div className={styles.instructions}>
              Safety suggestions will be submitted without your name, username,
              or other identifying user information.
            </div>
          </Card>
        )}

        <Card showHeader title="Safety Suggestion" name="safetySuggestion">
          <HierarchySelector
            groupRequired
            onGroupChange={v => {
              let newProject =
                v === safetySuggestion.groupId
                  ? safetySuggestion.projectId
                  : null;
              handleAnswer({
                ...safetySuggestion,
                groupId: v,
                projectId: newProject
              });
            }}
            groupId={location ? location._id : groupId}
            onProjectChange={v =>
              handleAnswer({ projectId: v.length ? v : null })
            }
            projectId={project ? project._id : projectId}
            groupDisabled={!isCreating}
            projectDisabled={!isCreating}
            touched={missingRequired && !location._id && !groupId}
          />
          <Textbox
            fieldLabel="Location"
            onChange={v => handleAnswer({ location: v })}
            currentValue={safetySuggestion?.location}
            disabled={!isCreating}
          />
          {isCreating && (
            <Input
              fieldLabel={
                (hazardDescription === undefined ||
                  hazardDescription?.trim().length < 4) &&
                (suggestionDescription === undefined ||
                  suggestionDescription?.trim().length < 4)
                  ? 'Either a Description of Hazard or a Description of Suggestion are required, but you are not required to have both.'
                  : ''
              }
              labelStyles={
                !canSubmit && missingRequired
                  ? styles.missingRequiredText
                  : styles.requiredText
              }
            />
          )}

          <Textbox
            fieldLabel="Description of Hazard"
            onChange={v => handleAnswer({ hazardDescription: v })}
            currentValue={
              hazardDescription?.replaceAll(' ', '').length > 0
                ? hazardDescription
                : ''
            }
            type="textarea"
            disabled={!isCreating}
          />
          <Textbox
            fieldLabel="Description of Suggestion"
            onChange={v => handleAnswer({ suggestionDescription: v })}
            currentValue={
              suggestionDescription?.replaceAll(' ', '').length > 0
                ? suggestionDescription
                : ''
            }
            type="textarea"
            disabled={!isCreating}
          />
        </Card>
        <AttachmentUploadCard
          name="attachments"
          addAttachments={handleAddAttachments}
          addedAttachments={safetySuggestion?.attachments || []}
          isCreating={isCreating}
          addPhotosOnly
          attachmentsToUpload={photosToUpload}
          ownerType="safetySuggestion"
          handleAnswer={(currentAttachments, currentFiles) => {
            setPhotosToUpload(currentAttachments);
            setFilesToUpload(currentFiles);
            setHasUnsavedChanges(true);
          }}
          formattedAttachmentsToUpload={filesToUpload}
          keepAttachmentsAnonymous
        />
        {(canViewResponse || safetySuggestion.stage === 'Closed') && (
          <Card showHeader title="Response" name="response">
            <RadioButtons
              fieldLabel="Will this suggestion be implemented?"
              options={radioOptions}
              currentValue={suggestionImplemented}
              onChange={v => {
                handleAnswer({ suggestionImplemented: v });
              }}
              needSeparateLines
              isRequired={canRespond}
              disabled={responseDisabled}
            />
            <Textbox
              fieldLabel="Explanation"
              onChange={v => {
                handleAnswer({ explanation: v });
              }}
              currentValue={explanation}
              type="textarea"
              disabled={responseDisabled}
            />
            <AssignCorrectiveActionButton
              task={safetySuggestion?.task}
              groupId={groupId}
              projectId={projectId}
              disabled={responseDisabled}
              ownerType="safetySuggestion"
              label={`Safety Suggestion #${safetySuggestion.safetySuggestionId}`}
              schedule="immediate"
              ownerId={safetySuggestion._id}
              pictures={safetySuggestion?.attachments}
              onClick={() => {
                sessionStorage.setItem(
                  'suggestion',
                  JSON.stringify({ safetySuggestion })
                );
              }}
              type="correctiveAction"
            />
          </Card>
        )}
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={history.goBack}
        savingWhat="a Safety Suggestion"
      />
      <Modal
        title="Suggestion Created"
        submitButtonColor="green"
        isOpen={createdModal}
        submitActions={() => {
          history.push('/app/safetySuggestions/list');
        }}
        titleClassName="greenHeader"
        hideCancelButton
        onRequestClose={() => history.push('/app/safetySuggestions/list')}
        submitButtonText="Close"
      >
        <p>
          You have submitted a Safety Suggestion. Your user information will be
          kept confidential, but you will be able to view a response to your
          Safety Suggestion.
        </p>
        <p>
          The ID assigned to your Safety Suggestion is{' '}
          <b>{safetySuggestion?.safetySuggestionId}</b>.
        </p>
      </Modal>
      <Modal
        title="Close Suggestion?"
        isOpen={openSubmitResponseModal}
        onRequestClose={() => setOpenSubmitResponseModal(false)}
        titleClassName="greenHeader"
        cancelButtonText="Save for Later"
        cancelActions={() =>
          createResponse(safetySuggestion, safetySuggestion.stage)
        }
        submitButtonText="Submit & Close"
        submitButtonColor="green"
        submitActions={() => createResponse(safetySuggestion, 'Closed')}
      >
        <p>
          Would you like to submit and close this Safety Suggestion or leave it{' '}
          {safetySuggestion?.stage} and save it for later? If a Safety
          Suggestion is closed, you can always come back later and re-open it to
          make changes.
        </p>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Safety Suggestion"
      />
    </>
  );
}

export default SafetySuggestionTemplate;

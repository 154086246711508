import React from 'react';
import ReactTooltip from 'react-tooltip';
import Modal from '.';
import classnames from 'classnames/bind';
import { Button } from '../inputs';
import svgMapper from '../../utils/svgMapper';

import styles from './styles.module.scss';

export default function DashboardButtons(props) {
  const {
    handleCloseModal,
    modalProps,
    modalOpen,
    modalSelection,
    setModalSelection,
    company,
    alreadySelected,
    submitButtonText
  } = props;
  return (
    <Modal
      medium
      title={modalOpen ? modalProps?.[modalOpen].title : ''}
      titleClassName="blueHeader"
      isOpen={modalOpen}
      onRequestClose={handleCloseModal}
      submitActions={modalProps?.[modalOpen]?.modalSubmit}
      submitButtonText={submitButtonText ?? 'Create'}
      submitButtonColor="blue"
      disableSubmit={!modalSelection?.length}
    >
      <span className={styles.modalText}>{modalProps?.[modalOpen]?.text}</span>
      <div
        className={
          modalProps?.[modalOpen]?.buttons ? styles.modalButtons : null
        }
      >
        {modalProps?.[modalOpen]?.buttons
          ? modalProps?.[modalOpen]?.buttons
              .filter(
                b =>
                  !(
                    (!company.showAircraft && b.isAircraft) ||
                    (!company.showExposure && b.value === 'exposure') ||
                    (company.showAircraft && b.label === 'Spill') ||
                    (!company.hasLockoutTagout && b.value === 'lockoutTagout')
                  )
              )
              .map(x => (
                <>
                  <div data-for={x.label} data-tip="customTheme">
                    <Button
                      className={styles.modalButton}
                      color={
                        modalSelection?.includes(x.value) ||
                        alreadySelected?.includes(x.value)
                          ? 'blue'
                          : x.disabled
                          ? 'grey-stroke'
                          : ''
                      }
                      image={svgMapper(x.svg)}
                      imageClass={classnames([
                        styles.imageStyles,
                        x.disabled
                          ? styles.disabled
                          : modalSelection?.includes(x.value) ||
                            alreadySelected?.includes(x.value)
                          ? styles.selected
                          : styles.notSelected
                      ])}
                      inputClassName={styles.button}
                      text={<p className={styles.buttonText}>{x.label}</p>}
                      onClick={() =>
                        modalProps?.[modalOpen]?.onClick
                          ? modalProps?.[modalOpen]?.onClick(x.value)
                          : setModalSelection(x.value)
                      }
                      testID={x.label}
                      disabled={
                        x.disabled || alreadySelected?.includes(x.value)
                      }
                    />
                  </div>
                  <ReactTooltip
                    className={classnames(styles.tooltip, 'customTheme')}
                    id={x.label}
                  >
                    {x.label}
                  </ReactTooltip>
                </>
              ))
          : modalProps?.[modalOpen]?.dropdown ?? <></>}
      </div>
    </Modal>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import history from '../../../history';
import {
  fetchSafetyAuditTemplateById,
  createSafetyAuditTemplate,
  updateSafetyAuditTemplate,
  deleteSafetyAuditTemplate
} from '../../../api/v4';
import { isValidTableQuestion } from '../../../utils/formHelpers';
import { getActiveCompany } from '../../../selectors/company';
import Card from '../../../components/Card';
import DeleteTemplateModal from '../../../components/Modal/deleteTemplateModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import Modal from '../../../components/Modal';
import { Rearranger } from '../../../components/Rearranger';
import { SaveCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';
import {
  Textbox,
  Checkbox,
  Button,
  InputRow,
  TwoColumn,
  Dropdown
} from '../../../components/inputs';
import RadioButtons from '../../../components/inputs/RadioButtons';
import { RichTextTextbox } from '../../../components/inputs/RichTextEditor';
import { SAFETY_AUDIT_QUESTION_TYPES } from '../../../constants/constants';
import HoverImage from '../../../components/HoverImage';
import TableQuestion from '../../../components/inputs/TableQuestion';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';

import styles from './template.module.scss';

export default function SafetyAuditTemplate() {
  const company = useSelector(getActiveCompany);
  const [template, setTemplate] = useState({});
  const [sections, setSections] = useState([]);
  const [deletingIndex, setDeleteIndex] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [showUnsavedTemplateModal, setShowUnSavedTemplateModal] = useState(
    false
  );
  const [showSavingTemplateModal, setShowSavingTemplateModal] = useState(false);
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
  const [showDeleteSectionModal, setDeleteSectionsModal] = useState(false);
  const [optionsModal, setOptionsModal] = useState({
    modalOpen: false,
    sectionIndex: null,
    rowIndex: null,
    activeOptions: [],
    selectedFieldType: null
  });
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const { safetyAuditTemplateId, duplicate } = useParams();
  const location = useLocation();
  const auditType = location.pathname.includes('safety')
    ? 'safety'
    : location.pathname.includes('quality')
    ? 'quality'
    : 'loto';
  const auditTypeName =
    auditType === 'loto'
      ? 'Lockout/Tagout Procedure'
      : `${auditType.charAt(0).toUpperCase() + auditType.slice(1)} Audit`;

  useEffect(() => {
    if (safetyAuditTemplateId && duplicate) {
      fetchSafetyAuditTemplateById(safetyAuditTemplateId).then(response => {
        delete response._id;
        const newTitle = `${response.title} - Copy`;
        setSections(response.sections);
        setTemplate({ ...response, title: newTitle });
        setIsCreating(true);
      });
    } else if (safetyAuditTemplateId) {
      fetchSafetyAuditTemplateById(safetyAuditTemplateId).then(response => {
        setSections(response.sections);
        setTemplate(response);
      });
    } else {
      setIsCreating(true);
    }
  }, [safetyAuditTemplateId, duplicate]);

  const handleQuestionChange = (sectionIndex, questionIndex, value) => {
    sections[sectionIndex].questions[questionIndex] = {
      ...sections[sectionIndex].questions[questionIndex],
      ...value
    };
    setSections([...sections]);
    setHasChanges(true);
  };

  const handleSectionChange = (sectionIndex, value, key) => {
    sections[sectionIndex][key] = value;

    if (value === 'informational' && key === 'type') {
      let updatedQuestions = sections[sectionIndex]?.questions?.map(q => ({
        ...q,
        type: q.type === 'yesNo' ? '' : q.type
      }));
      sections[sectionIndex].questions = updatedQuestions;
    }

    setSections([...sections]);
    setHasChanges(true);
  };

  const addRows = (sectionIndex, numberOfRows) => {
    let questions = sections[sectionIndex].questions;
    for (let i = 1; i <= numberOfRows; i++) {
      questions.push({ label: '', type: '' });
    }
    sections[sectionIndex].questions = questions;

    setSections([...sections]);
    setHasChanges(true);
  };

  const removeQuestion = (sectionIndex, questions) => {
    sections[sectionIndex].questions = [...questions];
    setSections([...sections]);
    setHasChanges(true);
  };

  const addSection = () => {
    sections.push({ title: '', questions: [{ label: '', type: '' }] });
    setSections([...sections]);
    setHasChanges(true);
  };
  const deleteSection = i => {
    setSections(sections.filter(s => sections.indexOf(s) !== i));
    setHasChanges(true);
  };

  const questionIsMissing =
    sections.filter(
      section =>
        section.questions.filter(
          question => !question.label.replace(/[\W_]+/g, '').trim().length
        ).length > 0
    ).length > 0;

  const scorableSections = sections?.filter(s => s.type === 'scorable');

  const allScorableSectionsHaveYesNo = !scorableSections.some(
    section => !section.questions.some(q => q.type === 'yesNo' || q.type === '')
  );

  const optionsFilledOut = sections?.every(section =>
    section.questions.every(question => {
      let isValid =
        question.label.replace(/\u200B/g, '').trim().length &&
        question.label !== '\n' &&
        question.type?.length;
      if (['dropdown', 'multiselect'].includes(question.type)) {
        isValid =
          isValid &&
          question?.options?.length &&
          question.options?.every(o => o.length);
      }
      if (question.type === 'table') {
        isValid = isValid && isValidTableQuestion(question);
      }
      return isValid;
    })
  );

  const canSubmit =
    (hasChanges || duplicate) &&
    template.title?.trim()?.length &&
    sections.length > 0 &&
    !sections.some(section => !section.title?.trim()?.length) &&
    !sections.some(section => !section.type) &&
    !sections.some(section => !section.questions.length) &&
    scorableSections?.length &&
    allScorableSectionsHaveYesNo &&
    !questionIsMissing &&
    optionsFilledOut;

  const submit = () => {
    template.sections = sections;
    if (template._id && !duplicate) {
      updateSafetyAuditTemplate(template).then(() =>
        history.push(`/app/auditTemplates/${auditType}`)
      );
    } else {
      createSafetyAuditTemplate({
        ...template,
        type: auditType,
        companyId: company._id
      }).then(() => history.push(`/app/auditTemplates/${auditType}`));
    }
  };
  const setChangeOptions = (options, sectionIndex, rowIndex, fieldType) => {
    setOptionsModal(true);
    setOptionsModal({
      ...optionsModal,
      modalOpen: true,
      sectionIndex,
      rowIndex,
      activeOptions: options,
      selectedFieldType: fieldType?.value
    });
  };

  const handleArchive = () => {
    updateSafetyAuditTemplate({
      ...template,
      isArchived: !template.isArchived
    }).then(response => {
      if (response.isArchived) history.goBack();
      else {
        setSections(response.sections);
        setTemplate(response);
      }
    });
  };

  const header = (
    <Header
      title={`Custom ${auditTypeName}`}
      section={
        !isCreating && !isEditing
          ? 'View Template'
          : isCreating
          ? 'Create New Template'
          : 'Edit Template'
      }
      needsSaved={hasChanges}
      clickBack={() =>
        hasChanges && isEditing
          ? setShowSaveChangesModal(true)
          : hasChanges && isCreating
          ? setShowUnSavedTemplateModal(true)
          : history.goBack()
      }
      pageActionOptions={
        !isCreating
          ? [
              {
                label: isEditing ? 'Delete Template' : 'Edit Template',
                color: isEditing ? 'redOutline' : 'blueOutline',
                visible: !template?.isArchived,
                onClick: () => {
                  isEditing
                    ? setShowDeleteTemplateModal(true)
                    : setIsEditing(true);
                }
              },
              {
                label: `${template?.isArchived ? 'Un-' : ''}Archive Template`,
                color: 'blueOutline',
                visible: true,
                onClick: () => handleArchive()
              }
            ]
          : null
      }
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        !canSubmit
          ? setMissingModalOpen(true)
          : isEditing
          ? setShowSavingTemplateModal(true)
          : submit()
      }
      cancelButtonClick={() =>
        isEditing && hasChanges
          ? setShowSaveChangesModal(true)
          : isEditing && !hasChanges
          ? setIsEditing(false)
          : setShowSaveChangesModal(true)
      }
      editing={isEditing}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      showFooter={isCreating || isEditing}
    >
      <Card title={`${auditTypeName} Details`} showHeader>
        <Textbox
          currentValue={template.title}
          fieldLabel="Title"
          isRequired
          onChange={text => {
            setTemplate({ ...template, title: text });
            setHasChanges(true);
          }}
          disabled={!isCreating && !isEditing}
          touched={missingRequired && !template.title}
          error
        />
        <Textbox
          currentValue={template.description}
          fieldLabel="Description"
          onChange={text => {
            setTemplate({ ...template, description: text });
            setHasChanges(true);
          }}
          disabled={!isCreating && !isEditing}
          type="textarea"
        />
        <Textbox
          currentValue={template.instructions}
          fieldLabel="Instructions"
          onChange={text => {
            setTemplate({ ...template, instructions: text });
            setHasChanges(true);
          }}
          disabled={!isCreating && !isEditing}
          type="textarea"
        />
        <Checkbox
          currentValue={template.allowBulkActions}
          fieldLabel="Allow Bulk Actions"
          displayTooltip
          tooltip={`Bulk Actions allow a user to quickly mark all Yes/No questions in a section with \u2713, X, N/A`}
          onChange={checked => {
            setTemplate({ ...template, allowBulkActions: checked });
            setHasChanges(true);
          }}
          disabled={!isEditing && !isCreating}
        />
        <Checkbox
          fieldLabel="Require Photo for Unacceptable Items"
          currentValue={template.requirePhotoForUnacceptableItems}
          onChange={checked => {
            setTemplate({
              ...template,
              requirePhotoForUnacceptableItems: checked
            });
            setHasChanges(true);
          }}
          disabled={!isEditing && !isCreating}
        />
        <Checkbox
          fieldLabel="Require Note for Unacceptable Items"
          currentValue={template.requireNoteForUnacceptableItems}
          onChange={checked => {
            setTemplate({
              ...template,
              requireNoteForUnacceptableItems: checked
            });
            setHasChanges(true);
          }}
          disabled={!isEditing && !isCreating}
        />
        <Checkbox
          fieldLabel="Require Corrective Action for Unacceptable Items"
          currentValue={template.requireCAForUnacceptableItems}
          onChange={checked => {
            setTemplate({
              ...template,
              requireCAForUnacceptableItems: checked
            });
            setHasChanges(true);
          }}
          disabled={!isEditing && !isCreating}
        />
        {!scorableSections?.length ? (
          <p className={styles.error}>
            * Each {auditType === 'loto' ? 'Procedure' : 'Audit'} must have at
            least 1 scorable section
          </p>
        ) : !allScorableSectionsHaveYesNo ? (
          <p className={styles.error}>
            * Each {auditType === 'loto' ? 'Procedure' : 'Audit'} section that
            is scorable must have at least one Yes/No question
          </p>
        ) : (
          <></>
        )}
      </Card>
      {sections?.map((section, index) => (
        <Card
          key={index}
          showFooter
          footer={
            <InputRow>
              <Button
                color="blue"
                text="Add Row"
                onClick={() => addRows(index, 1)}
                disabled={!isCreating && !isEditing}
                testID="Add Row"
              />
              <Button
                color="blue"
                text="Add 5 Rows"
                onClick={() => addRows(index, 5)}
                disabled={!isCreating && !isEditing}
                testID="Add 5 Rows"
              />
            </InputRow>
          }
        >
          <TwoColumn className={styles.sectionRow}>
            <Textbox
              className={styles.sectionTitle}
              fieldLabel="Section Title"
              isRequired
              onChange={v => handleSectionChange(index, v, 'title')}
              currentValue={section.title}
              disabled={!isCreating && !isEditing}
              touched={missingRequired && !section.title}
              error
            />
            {isCreating || isEditing ? (
              <Button
                color="red"
                text="Delete Section"
                onClick={() => {
                  setDeleteIndex(index);
                  setDeleteSectionsModal(true);
                }}
                disabled={!isCreating && !isEditing}
              />
            ) : null}
          </TwoColumn>
          <RadioButtons
            fieldLabel="Section Type"
            options={[
              { value: 'informational', label: 'Informational' },
              { value: 'scorable', label: 'Scorable' }
            ]}
            isRequired
            onChange={value => handleSectionChange(index, value, 'type')}
            currentValue={section.type}
            disabled={!isCreating && !isEditing}
            displayTooltip
            tooltip="Informational sections may contain all question types except yes/no, as these sections are not scored. Scorable sections can contain any question type, but must include at least one yes/no question. Only yes/no questions contribute to the score."
            touched={missingRequired && !section.type}
          />
          <Rearranger
            items={section.questions}
            renderItem={(item, questionIndex) => (
              <>
                <RichTextTextbox
                  key={index}
                  currentValue={item.label}
                  onChange={e =>
                    handleQuestionChange(index, questionIndex, { label: e })
                  }
                  type="textarea"
                  placeholder={`Type your question here`}
                  disabled={!(isEditing || isCreating)}
                  className={styles.question}
                  testID={`question ${questionIndex}`}
                  touched={
                    missingRequired &&
                    item.label.replace(/\u200B/g, '')?.trim() === ''
                  }
                  error
                />
                <Dropdown
                  options={
                    section?.type === 'scorable'
                      ? SAFETY_AUDIT_QUESTION_TYPES
                      : SAFETY_AUDIT_QUESTION_TYPES.filter(
                          q => q.label !== 'Yes/No'
                        )
                  }
                  fieldLabel="Type of Input"
                  onChange={values =>
                    handleQuestionChange(index, questionIndex, {
                      type: values,
                      options: []
                    })
                  }
                  isRequired
                  bareValues
                  placeholder="Choose a question type"
                  currentValue={item.type}
                  testID={`dropdown${index}`}
                  disabled={!(isCreating || isEditing)}
                  touched={missingRequired && !item.type}
                />
                {item?.type === 'dropdown' || item?.type === 'multiselect' ? (
                  <>
                    <Button
                      text="View Options"
                      color="blue"
                      onClick={() =>
                        setChangeOptions(
                          item?.options ?? [],
                          index,
                          questionIndex,
                          item.type
                        )
                      }
                      disabled={!(isEditing || isCreating)}
                    />
                    {item.options?.some(o => !o.length) ||
                    !item.options?.length ? (
                      <p className={styles.error}>
                        * Must add at least one option
                      </p>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {item?.type === 'table' ? (
                  <TableQuestion
                    options={item?.options}
                    handleChange={value =>
                      handleQuestionChange(index, questionIndex, value)
                    }
                    disabled={!(isEditing || isCreating)}
                    missingRequired={missingRequired}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
            disabled={!isCreating && !isEditing}
            onChange={questions => removeQuestion(index, questions)}
          />
        </Card>
      ))}
      <InputRow className={styles.overallFooter}>
        <Button
          color="blue"
          text="Add Section"
          disabled={!isCreating && !isEditing}
          onClick={addSection}
        />
      </InputRow>
      <Modal
        title={`Unsaved ${auditTypeName} Template`}
        titleClassName="redHeader"
        isOpen={showUnsavedTemplateModal}
        submitButtonColor="red"
        submitButtonText="Leave Page"
        onRequestClose={() => setShowUnSavedTemplateModal(false)}
        submitActions={() => history.goBack()}
      >
        {` Leaving now will stop creation of this ${auditTypeName} Template. Are you
        sure you want to leave?`}
      </Modal>
      <Modal
        title={`Save ${auditTypeName} Template`}
        titleClassName="greenHeader"
        isOpen={showSavingTemplateModal}
        submitButtonColor="green"
        submitButtonText="Save"
        onRequestClose={() => setShowSavingTemplateModal(false)}
        submitActions={submit}
        testID={auditTypeName}
      >
        {`Saving this ${auditTypeName} Template will also update any unstarted ${auditTypeName}s that used this template.`}
      </Modal>
      <DeleteTemplateModal
        isOpen={showDeleteTemplateModal}
        deletingWhat={`${auditTypeName}`}
        onRequestClose={() => setShowDeleteTemplateModal(false)}
        submitActions={() => {
          deleteSafetyAuditTemplate(template).then(() => history.goBack());
        }}
      />
      <SaveChangesModal
        isOpen={showSaveChangesModal}
        onRequestClose={() => setShowSaveChangesModal(false)}
        submitActions={() => {
          history.goBack();
        }}
        savingWhat={`${auditTypeName}`}
      />
      <Modal
        title="Delete Template Section"
        titleClassName="redHeader"
        isOpen={showDeleteSectionModal}
        submitButtonColor="red"
        submitButtonText="Delete"
        onRequestClose={() => {
          setDeleteSectionsModal(false);
          setDeleteIndex(null);
        }}
        submitActions={() => {
          deleteSection(deletingIndex);
          setDeleteSectionsModal(false);
          setDeleteIndex(null);
        }}
      >
        Are you sure you want to delete this template section?
      </Modal>
      <Modal
        title="Change Options"
        titleClassName="blueHeader"
        isOpen={optionsModal.modalOpen}
        submitButtonColor="blue"
        submitButtonText="Save Changes"
        onRequestClose={() =>
          setOptionsModal({
            ...optionsModal,
            modalOpen: false,
            activeOptions: []
          })
        }
        disableSubmit={
          !optionsModal.activeOptions.length ||
          optionsModal.activeOptions?.some(o => !o.trim().length)
        }
        submitActions={() => {
          handleQuestionChange(
            optionsModal.sectionIndex,
            optionsModal.rowIndex,
            {
              options: optionsModal.activeOptions
            }
          );
          setOptionsModal({
            modalOpen: false,
            sectionIndex: null,
            rowIndex: null,
            activeOptions: [],
            selectedFieldType: null
          });
        }}
      >
        <div className={styles.modal}>
          {optionsModal.activeOptions?.map((option, index) => (
            <div className={styles.options} key={index}>
              <Textbox
                testID={`addOption${index}`}
                currentValue={option}
                onChange={v => {
                  const currentOptions = optionsModal.activeOptions;
                  currentOptions[index] = v;
                  setOptionsModal({
                    ...optionsModal,
                    activeOptions: currentOptions
                  });
                }}
                placeholder="Type your option here."
                className={styles.option}
              />
              <HoverImage
                src={require('../../../assets/images/removePermissions.png')}
                srcHover={require('../../../assets/images/removePermissonsHover.png')}
                className={styles.removeImage}
                alt="remove row"
                onClick={() =>
                  setOptionsModal({
                    ...optionsModal,
                    activeOptions: [
                      ...optionsModal.activeOptions.slice(0, index),
                      ...optionsModal.activeOptions.slice(index + 1)
                    ]
                  })
                }
              />
            </div>
          ))}
          <Button
            text="Add Option"
            color="blue"
            onClick={() =>
              setOptionsModal({
                ...optionsModal,
                activeOptions: [...optionsModal.activeOptions, '']
              })
            }
          />
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject={`${auditTypeName} Template`}
      />
    </HeaderAndFooter>
  );
}

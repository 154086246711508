import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import {
  setActiveIncidentRequest,
  setActiveIncidentResponse
} from '../../actions/incidents';
import { loadTask, setActiveTaskRequest } from '../../actions/tasks';
import {
  fetchTasks,
  fetchRepeatingTasks,
  reassignTasks,
  sendTaskReminder,
  fetchUserListFilter,
  updateUserListFilter,
  archiveMany
} from '../../api/v4';
import { getLoggedInUser } from '../../selectors/users';
import { usePrevious } from '../../utils/hooks';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import AnalyticsPeriodPicker from '../../components/AnalyticsPeriodPicker';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import ReassignUserModal from '../../components/Modal/reassignUserModal';
import RepeatableList from '../../components/RepeatableList';
import TypeSelection from '../../components/TypeSelection';

import styles from './taskStages.modules.scss';

export default function TaskStages() {
  const pathname = useLocation().pathname;
  const taskType = pathname.split('/').pop();
  const taskTypeName = taskType === 'task' ? 'Task' : 'Corrective Action';
  const dispatch = useDispatch();
  const setActiveTask = payload => dispatch(setActiveTaskRequest(payload));
  const fetchTask = id => dispatch(loadTask(id));
  const loadIncident = id => dispatch(setActiveIncidentRequest(id));
  const user = useSelector(getLoggedInUser);
  const { location, project } = useActiveHeirarchy();
  const [schedule, setSchedule] = useState('Active');
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [reassignedUser, setReassignedUser] = useState(null);
  const [ids, setIds] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [groups, setGroups] = useState(false);
  const [active, setActive] = useState([]);
  const [repeating, setRepeating] = useState([]);
  const [empPeriod, setEmpPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const prevPeriod = usePrevious(currentPeriod);
  const prevType = usePrevious(schedule);

  const setUp = useCallback(() => {
    fetchUserListFilter(user._id, 'task').then(r => {
      setCurrentPeriod(r);
      setEmpPeriod(r);
    });

    if (taskType === 'task') fetchRepeatingTasks().then(setRepeating);
    dispatch(setActiveIncidentResponse({}));
  }, [dispatch, taskType, user._id]);

  useEffect(() => {
    setUp();
  }, [setUp]);

  useEffect(() => {
    if (
      currentPeriod &&
      (!isEqual(prevPeriod, currentPeriod) ||
        (!isEqual(prevType, schedule) && schedule !== 'Repeating'))
    )
      fetchTasks(taskType, { ...currentPeriod, selectedType: schedule }).then(
        setActive
      );
  }, [currentPeriod, prevPeriod, taskType, schedule, prevType]);

  const reassign = () => {
    reassignTasks({ ids, reassignedUser }).then(() => {
      setOpenReassignModal(false);
      setReassignedUser(null);
      setUp();
    });
  };

  const doesContainIncidentTask = ids => {
    const selectedTasks = active.filter(t => ids.includes(t._id));

    return selectedTasks.some(t => t.ownerType === 'incident');
  };

  const getGroupIds = ids => {
    const selectedTasks = active.filter(t => ids.includes(t._id));

    return selectedTasks.map(t => t.groupId);
  };

  const handleRowClick = ids => {
    setIds(ids);
    // need to check for incident task to limit to sups or higher
    if (doesContainIncidentTask(ids)) {
      setPermissions([400, 500, 900]);
    } else {
      setPermissions([100, 400, 500, 900]);
    }
    setGroups(getGroupIds(ids));
    setOpenReassignModal(true);
  };

  const handleArchive = ids => {
    archiveMany({
      type: 'task',
      ids: ids,
      isArchived: schedule === 'Active'
    })
      .then(() => {
        fetchTasks(taskType, { ...currentPeriod, selectedType: schedule }).then(
          setActive
        );
      })
      .catch(() =>
        // need to do this in case user tries to unarchive tasks that have archived owners
        fetchTasks(taskType, { ...currentPeriod, selectedType: schedule }).then(
          setActive
        )
      );
  };

  const repeatingSettings = [
    { key: 'label', label: 'Title', accessor: r => r?.task?.label },
    { key: 'description' },
    {
      key: 'assignedTo',
      datatype: 'users',
      label: 'Assignee(s)'
    },
    {
      key: 'repeatingOn',
      datatype: 'dow',
      label: 'Repeat On',
      frequency: 'frequency'
    }
  ];
  let activeSettings = [
    {
      key: 'isCompleted',
      label: 'Status',
      accessor: r => (r?.isCompleted ? 'Completed' : 'Open'),
      enum: [
        { value: 'Open', label: 'Open' },
        { value: 'Completed', label: 'Completed' }
      ]
    },
    { key: 'label', label: 'Title' },
    { key: 'description' },
    {
      key: 'assignedTo',
      datatype: 'users',
      label: 'Assignee(s)'
    },
    {
      key: 'dueDate',
      datatype: 'futureDate',
      label: 'Due Date'
    },
    {
      key: 'completionDate',
      datatype: 'futureDate',
      label: 'Completion Date'
    },
    {
      key: 'incidentNumber',
      label: 'Incident Number'
    }
  ];

  const header = (
    <Header
      title={`${taskTypeName}s`}
      rightButtons={{
        text: 'Create Task',
        color: 'blue',
        onClick: () => setActiveTask({ type: 'task' }),
        visible:
          !location?.isHidden &&
          !project?.isHidden &&
          user.accessLevel > 100 &&
          taskType === 'task'
      }}
    />
  );

  if (!location?._id) {
    repeatingSettings.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
    activeSettings.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  if (schedule === 'Archived')
    activeSettings = activeSettings.filter(
      setting => setting.label !== 'Status'
    );

  return (
    <HeaderAndFooter Header={header}>
      {taskType === 'task' ||
      (taskType === 'correctiveAction' && user.accessLevel > 400) ? (
        <TypeSelection
          selected={schedule}
          selectedArray={[
            'Active',
            taskType !== 'correctiveAction' ? 'Repeating' : null,
            user.accessLevel > 400 ? 'Archived' : null
          ]}
          onClick={v => {
            setActive([]);
            setSchedule(v);
          }}
        />
      ) : null}
      {schedule !== 'Repeating' ? (
        <AnalyticsPeriodPicker
          period={currentPeriod}
          onChange={value => {
            setActive([]);
            setCurrentPeriod(value);
          }}
          onSave={v =>
            updateUserListFilter({
              empId: user._id,
              type: 'task',
              period: v
            }).then(() => setEmpPeriod(v))
          }
          allowAll
          savedPeriod={empPeriod}
          listPicker
        />
      ) : null}

      {schedule === 'Repeating' ? (
        <RepeatableList
          repeatables={repeating}
          repeatingColumns={repeatingSettings}
          handleRowClick={row =>
            fetchTask({ id: row._id, fromRepeating: true })
          }
          updateData={() => fetchRepeatingTasks().then(setRepeating)}
          saveKey="repeatingTaskList"
          type="Tasks"
        />
      ) : (
        <List
          saveKey={`${
            schedule === 'Archived' ? 'archived' : ''
          }${taskTypeName}List`}
          rowClick={row =>
            row.type === 'ReportComponent'
              ? loadIncident(row.ownerId)
              : fetchTask(row._id)
          }
          actions={
            user.accessLevel >= 500
              ? [
                  ...(schedule === 'Active'
                    ? [
                        {
                          color: 'blue',
                          label: `Reassign ${taskTypeName}s`,
                          onClick: handleRowClick
                        },
                        {
                          color: 'blue',
                          label: 'Send Reminder',
                          onClick: sendTaskReminder
                        }
                      ]
                    : []),
                  {
                    color: 'blue',
                    label: `${
                      schedule === 'Archived' ? 'Un-' : ''
                    }Archive ${taskTypeName}s`,
                    onClick: handleArchive
                  }
                ]
              : null
          }
          dataIsHash
          data={active}
          settings={activeSettings}
          getRowId={r => r._id}
        />
      )}
      <ReassignUserModal
        title={`Reassign ${taskTypeName}s`}
        reassignUser={reassignedUser}
        disableSubmit={!reassignedUser}
        message={
          <div className={styles.reassignText}>
            All of the selected {taskTypeName}s will be reassigned to the user
            below.
            <span style={{ color: '#c74846', fontWeight: 'bold' }}>
              If there is a report related {taskTypeName} in the selected group
              of {taskTypeName}s to be reassigned, make sure the user below has
              permissions to all of the incidents associated.
            </span>
          </div>
        }
        onRequestClose={() => {
          setOpenReassignModal(false);
          setReassignedUser(null);
        }}
        isOpen={openReassignModal}
        employeeFieldLabel="New Assignee"
        onChange={setReassignedUser}
        submitActions={reassign}
        permissions={permissions}
        groups={groups}
      />
    </HeaderAndFooter>
  );
}

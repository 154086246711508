import React, { useState } from 'react';
import moment from 'moment';
import {
  fetchTrainingExceptionTitles,
  fetchTrainingExceptionReport,
  sendTrainingReminder,
  updateExceptionReports,
  downloadExceptionList
} from '../../api/v4';
import Card from '../../components/Card';
import { Button, Dropdown } from '../../components/inputs';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import HierarchySelector from '../../components/HierarchySelector';
import List from '../../components/List';
import { TwoColumn } from '../../components/inputs';
import { SingleDatePicker } from 'react-dates';
import {
  EXCEPTION_REPORTING_FREQUENCY_OPTIONS,
  TRAINING_STATUSES
} from '../../constants/constants';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import styles from './styles.module.scss';
import ReportModal from '../../components/Modal/reportModal';
import RemoveExceptionItemModel from '../../components/Modal/removeExcptionItemModal';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function TrainingReport() {
  const { company, location, project } = useActiveHeirarchy();

  const [dateRange, setDateRange] = useState(null);
  const [trainingTitles, setTrainingTitles] = useState([]);
  const [selectedTraining, setSelectedTraining] = useState(null);
  const [groupIds, setGroupIds] = useState([]);
  const [projectIds, setProjectIds] = useState([]);
  const [hireDateRange, setHireDateRange] = useState(null);
  const [employeeStatus, setEmployeeStatus] = useState('all');
  const [exceptionData, setExceptionData] = useState([]);
  const [exceptionColumns, setExceptionColumns] = useState([]);
  const [exceptionReports, setExceptionReports] = useState([]);
  const [hasRanReport, setHasRanReport] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [rangeStartDate, setRangeStartDate] = useState(null);
  const [rangeStartDateFocused, setRangeStartDateFocused] = useState(false);
  const [rangeEndDate, setRangeEndDate] = useState(null);
  const [rangeEndDateFocused, setRangeEndDateFocused] = useState(false);
  const [hireStartDate, setHireStartDate] = useState(null);
  const [hireStartDateFocused, setHireStartDateFocused] = useState(false);
  const [hireEndDate, setHireEndDate] = useState(null);
  const [hireEndDateFocused, setHireEndDateFocused] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [tempData, setTempData] = useState(null);
  const [exceptionTitles, setExceptionTitles] = useState([]);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);

  const [dateRangeErrorMessage, setDateRangeErrorMessage] = useState(null);
  const [hireDateErrorMessage, setHireDateErrorMessage] = useState(null);

  const handleSendReminder = empIds => {
    let trainingIds = [];
    empIds.forEach(emp => {
      trainingIds = trainingIds.concat(
        exceptionData.find(e => e._id === emp).masterId
      );
    });
    sendTrainingReminder({ trainingIds, empIds });
  };

  const handleSaveReports = reports => {
    updateExceptionReports(
      reports.map(report => ({
        ...report,
        groupIds,
        projectIds,
        hireDateRange,
        dateRange,
        employeeStatus,
        selectedTraining
      }))
    ).then(r => {
      setExceptionReports(r);
      setOpenReportModal(false);
    });
  };

  const handleRunReport = () => {
    fetchTrainingExceptionReport({
      dateRange,
      startDate: rangeStartDate,
      endDate: rangeEndDate,
      trainingTitles: selectedTraining,
      hireDateRange: hireDateRange === 'clear' ? null : hireDateRange,
      hireStartDate,
      hireEndDate,
      groupIds,
      projectIds,
      employeeStatus
    }).then(response => {
      setExceptionData(response.exceptions);
      setExceptionTitles(response.titleList);
      setExceptionReports(response.exceptionReports);

      getStatusColumns(response.titleList);
      setHasRanReport(true);
      setHasChanges(false);
    });
  };

  const getStatusColumns = titles => {
    let columns = [
      {
        key: 'assignee',
        label: 'Employee Name',
        datatype: 'users'
      },
      {
        key: 'status',
        label: 'Training Status',
        enum: [...TRAINING_STATUSES, 'Unassigned']
      },
      {
        key: 'completionDate',
        label: `Completion Date`,
        datatype: 'date'
      },
      {
        key: 'expiration',
        label: `Expired?`,
        enum: ['No', 'Yes', 'In 30 days', 'In 60 days', 'In 90 days']
      }
    ];

    setExceptionColumns(columns);
    return columns;
  };

  const handleCustomDateChange = async (start, end, type = 'end') => {
    let titles;

    if (type === 'start') {
      setRangeStartDate(start);
    } else {
      setRangeEndDate(end);
    }
    if (start && end) {
      if (moment(start).isBefore(end)) {
        titles = await fetchTrainingExceptionTitles({
          dateRange,
          startDate: start,
          endDate: end
        });
        if (selectedTraining) {
          checkForRemovedTitles(titles, 'customRange');
          setDateRangeErrorMessage('');
        } else {
          setTrainingTitles(titles);
          setDateRange('customRange');
          setDateRangeErrorMessage('');
        }
      } else {
        setDateRangeErrorMessage('Invalid Date Range');
      }
    }
  };

  const handleDateChange = async value => {
    setDateRange(value);
    if (value !== 'customRange') {
      await fetchTrainingExceptionTitles({ dateRange: value }).then(r =>
        handleTitlesForDateChange(value, r)
      );
    } else {
      setRangeStartDate(null);
      setRangeEndDate(null);
    }
  };

  const handleTitlesForDateChange = (date, titles) => {
    if (dateRange !== 'custom') {
      setTrainingTitles(titles);
    } else {
      checkForRemovedTitles(titles, date);
    }
  };

  const checkForRemovedTitles = (titles, date) => {
    if (!titles.map(t => t.value).includes(selectedTraining)) {
      setRemoveModal(true);
      setTempData({ date, titles });
    } else {
      setDateRange(date);
      setTrainingTitles(titles);
      setHasChanges(true);
    }
  };

  const handleExportTable = async () => {
    const src = await downloadExceptionList({
      exceptionData,
      titleList: exceptionTitles
    });
    window.location = src;
  };

  const handleGroupChange = values => {
    const validProjectIds = company.groups
      .map(g => {
        if (values.includes(g._id)) {
          return g.projects.map(p => p._id);
        } else {
          return [];
        }
      })
      .reduce((acc, x) => acc.concat(x), []);
    setGroupIds(values);

    const newProjectIds = projectIds.filter(p => validProjectIds.includes(p));
    setProjectIds(newProjectIds);
    if (hasRanReport) setHasChanges(true);
  };

  const header = (
    <Header
      title="Training Report"
      // rightButtons={{
      //   color: 'blue',
      //   text: 'Send a Report',
      //   disabled: !hasRanReport,
      //   onClick: () => setOpenReportModal(true)
      // }}
      rightButtons={{
        color: 'blue',
        text: 'Export Table',
        disabled: !hasRanReport,
        onClick: () => handleExportTable(true)
      }}
    />
  );

  const checkForValidHireRange = (start, end) => {
    let message = moment(start).isBefore(end) ? null : 'Invalid Date Range';
    setHireDateErrorMessage(message);
  };

  const canRun =
    dateRange &&
    selectedTraining &&
    ((hireDateRange === 'customRange' &&
      hireStartDate &&
      hireEndDate &&
      !hireDateErrorMessage) ||
      hireDateRange !== 'customRange');

  const hireDateOptions = [
    {
      value: 'clear',
      label: ' '
    },
    ...EXCEPTION_REPORTING_FREQUENCY_OPTIONS
  ];
  const employeeStatusOptions = [
    {
      value: 'all',
      label: 'All Employees'
    },
    {
      value: 'unassigned',
      label: "Employees who haven't completed training"
    },
    {
      value: 'complete',
      label: 'Employees who have completed training'
    }
  ];

  return (
    <HeaderAndFooter Header={header}>
      <Card>
        <TwoColumn>
          {dateRange === 'customRange' ? (
            <TwoColumn>
              <Dropdown
                options={EXCEPTION_REPORTING_FREQUENCY_OPTIONS}
                fieldLabel="Date"
                currentValue={dateRange}
                onChange={value => handleDateChange(value)}
                isRequired
                touched={missingRequired && !dateRange}
              />
              <div
                className={{ display: 'flex', flexDirection: 'column-reverse' }}
              >
                <div className={styles.datePicker}>
                  <SingleDatePicker
                    date={rangeStartDate}
                    onDateChange={value =>
                      handleCustomDateChange(value, rangeEndDate, 'start')
                    }
                    focused={rangeStartDateFocused}
                    onFocusChange={f => setRangeStartDateFocused(f.focused)}
                    placeholder="Start"
                    isOutsideRange={d => false}
                  />
                  <SingleDatePicker
                    date={rangeEndDate}
                    onDateChange={value =>
                      handleCustomDateChange(rangeStartDate, value)
                    }
                    focused={rangeEndDateFocused}
                    onFocusChange={f => setRangeEndDateFocused(f.focused)}
                    placeholder="End"
                    isOutsideRange={d => false}
                  />
                </div>
                <span className={styles.dateWarning}>
                  {dateRangeErrorMessage}
                </span>
              </div>
            </TwoColumn>
          ) : (
            <Dropdown
              options={EXCEPTION_REPORTING_FREQUENCY_OPTIONS}
              fieldLabel="Date"
              currentValue={dateRange}
              onChange={value => handleDateChange(value)}
              isRequired
              touched={missingRequired && !dateRange?.length}
            />
          )}
          <Dropdown
            options={trainingTitles}
            fieldLabel="Training Title"
            currentValue={selectedTraining}
            onChange={value => {
              setSelectedTraining(value);
              if (hasRanReport) setHasChanges(true);
            }}
            isRequired
            disabled={!dateRange?.length}
            selectButtons
            searchable
            touched={missingRequired && !selectedTraining}
          />
        </TwoColumn>
        <HierarchySelector
          onGroupChange={values => handleGroupChange(values)}
          groupId={location ? [location._id] : groupIds}
          groupDisabled={dateRange?.length < 1 || !selectedTraining}
          onProjectChange={values => {
            setProjectIds(values);
            if (hasRanReport) setHasChanges(true);
          }}
          projectId={project ? [project._id] : projectIds}
          projectDisabled={groupIds?.length < 1 && !location}
          groupRequired={false}
          multi
          alwaysShowGroupDropdown
          alwaysShowProjectDropdown
        />
        <TwoColumn>
          {hireDateRange === 'customRange' ? (
            <TwoColumn>
              <Dropdown
                searchable
                options={hireDateOptions}
                fieldLabel="Hire Date"
                currentValue={hireDateRange}
                onChange={value => {
                  setHireDateRange(value);
                  if (value === 'clear') {
                    setHireEndDate(null);
                    setHireStartDate(null);
                  }
                  if (hasRanReport) setHasChanges(true);
                }}
                disabled={dateRange?.length < 1 || !selectedTraining}
                placeholder="Choose one or more options"
              />
              <div
                className={{ display: 'flex', flexDirection: 'column-reverse' }}
              >
                <div className={styles.datePicker}>
                  <SingleDatePicker
                    date={hireStartDate}
                    onDateChange={value => {
                      setHireStartDate(value);
                      checkForValidHireRange(value, hireEndDate);
                    }}
                    focused={hireStartDateFocused}
                    onFocusChange={f => setHireStartDateFocused(f.focused)}
                    placeholder="Start"
                    isOutsideRange={d => false}
                  />
                  <SingleDatePicker
                    date={hireEndDate}
                    onDateChange={value => {
                      setHireEndDate(value);
                      checkForValidHireRange(hireStartDate, value);
                    }}
                    focused={hireEndDateFocused}
                    onFocusChange={f => setHireEndDateFocused(f.focused)}
                    placeholder="End"
                    isOutsideRange={d => false}
                  />
                </div>
                <span className={styles.dateWarning}>
                  {hireDateErrorMessage}
                </span>
              </div>
            </TwoColumn>
          ) : (
            <Dropdown
              searchable
              options={hireDateOptions}
              fieldLabel="Hire Date"
              currentValue={hireDateRange}
              onChange={value => {
                setHireDateRange(value);
                if (hasRanReport) setHasChanges(true);
              }}
              disabled={dateRange?.length < 1 || !selectedTraining}
              placeholder="Choose one or more options"
            />
          )}
          <Dropdown
            searchable
            options={employeeStatusOptions}
            fieldLabel="Employee Status"
            currentValue={employeeStatus}
            onChange={value => {
              setEmployeeStatus(value);
              if (hasRanReport) setHasChanges(true);
            }}
            disabled={dateRange?.length < 1 || !selectedTraining}
            placeholder="Choose one or more options"
          />
        </TwoColumn>
        <Button
          color="blue"
          text={hasRanReport ? 'Update Report' : 'Run Report'}
          onClick={() =>
            !canRun ? setMissingModalOpen(true) : handleRunReport()
          }
          disabled={hasRanReport && !hasChanges}
          className={styles.button}
          onMouseEnter={() => setMissingRequired(!canRun)}
        />
      </Card>
      <Card>
        {canRun && hasRanReport ? (
          <List
            data={exceptionData}
            dataIsHash
            settings={exceptionColumns}
            getRowId={r => r._id}
            actions={[
              {
                label: 'Send Reminder',
                color: 'blue',
                onClick: handleSendReminder
              }
            ]}
            hideCheckboxFor={[
              'isCompleted',
              'completionDate',
              'hideUnassigned'
            ]}
          />
        ) : (
          <div className={styles.messageText}>
            Fill fields above to generate report
          </div>
        )}
      </Card>
      <ReportModal
        isOpen={openReportModal}
        onRequestClose={() => setOpenReportModal(false)}
        submitActions={values => handleSaveReports(values)}
        exceptionType={'training'}
        currentReports={exceptionReports}
      />
      <RemoveExceptionItemModel
        isOpen={removeModal}
        onRequestClose={() => setRemoveModal(false)}
        submitActions={() => {
          setRemoveModal(false);
          setSelectedTraining(null);
          setHasRanReport(false);
          setTrainingTitles(tempData.titles);
          setDateRange(tempData.date);
          setTempData(null);
        }}
        itemsToRemove={[selectedTraining]}
        removeWhat="Trainings"
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Training Report"
        isRunning
      />
    </HeaderAndFooter>
  );
}
